import React from 'react'
import { avatar, quotationMark } from '../assets'
import Chairman from '../assets/chairman.png'

const FeedbackCard = () => {
  return (
    <>
    <div className='bg-white p-8 rounded-3xl shadow-xl my-8 mx-2'>
      <div className='flex justify-between'>
        <div className='flex gap-4'>
                <img src={Chairman} className='rounded-full' />
                <div>
                    <h2 className='whitespace-nowrap font-bold'>John Davidson</h2>
                    <p>Supply Chain Manager</p>
                    <h3>Supply Chain Manager</h3>
                </div>
            
            </div>
            <img className='h-8' src={quotationMark} />
      </div>

      <div className='py-8 mt-4'>
        <h3 className='text-lg'>Using this asset tracking system has revolutionized our supply chain management. We now have real-time insights, ensuring accurate inventory, reducing losses, and improving overall operational efficiency.</h3>
        <h1 className='text-lg'>Using this asset tracking system has revolutionized our supply chain management. We now have real-time insights, ensuring accurate inventory, reducing losses, and improving overall operational efficiency.</h1>
      </div>
    </div>

    <div className='bg-white p-8 rounded-3xl shadow-xl my-8 mx-2'>
      <div className='flex justify-between'>
        <div className='flex gap-4'>
                <img src={avatar} />
                <div>
                    <h2 className='whitespace-nowrap font-bold'>Sarah Thompson</h2>
                    <p>Fleet Manager</p>
                    <h3>Fleet Manager</h3>
                </div>
            
            </div>
            <img className='h-8' src={quotationMark} />
      </div>

      <div className='py-8 mt-4'>
        <h3 className='text-lg'>Our fleet management has significantly improved since implementing this asset tracking solution. We can monitor vehicle locations, optimize routes, and enhance maintenance schedules, resulting in cost savings and better customer service.</h3>
        <h1 className='text-lg'>Our fleet management has significantly improved since implementing this asset tracking solution. We can monitor vehicle locations, optimize routes, and enhance maintenance schedules, resulting in cost savings and better customer service.</h1>
      </div>
    </div>

    
    </>
  )
}

export default FeedbackCard
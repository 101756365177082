import React, { useState } from 'react';
import Helmet from '../components/Helmet/Helmet';
import { Container, Row, Col, Form, FormGroup } from 'reactstrap';
import { Link } from "react-router-dom";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import {auth} from '../firebase.config.js';
import { storage } from '../firebase.config.js';
import {toast} from 'react-toastify';
import { setDoc, doc } from 'firebase/firestore';
import { db } from '../firebase.config.js';

import '../styles/adminsignup.css';
import { useNavigate } from "react-router-dom";

const AdminSignup = ({ textToCopy = 'https://eugogatelogistics.com/admin/signup-admin' }) => {
    const [loading, setLoading] = useState(false);
    const [copied, setCopied] = useState(false);

    const copyToClipboard = () => {

        navigator.clipboard.writeText(textToCopy).then(
    
          () => {
    
            setCopied(true);
    
            // changing back to default state after 2 seconds.
    
            setTimeout(() => {
    
              setCopied(false);
    
            }, 2000);
    
          },
    
          (err) => {
    
            console.log("failed to copy", err.mesage);
    
          }
    
        );
    
      };
    
    
      const btnStyle = copied ? "bg-green-500 text-white" : "";

  return (
    <Helmet title="SignUp">
      <section>
      <Container style={{justifyContent: 'center', alignItems: 'center', width: '100%', }}>
        <Row style={{
              backgroundColor: '#f8f9fa',
              border: '0',
              justifyContent: 'center',
              padding: '0.5rem',
              width: '100%'
            }}>
            {loading ? (
            <Col lg='12' className="text-center">
              <h2 className="font-bold text-center text-5xl">Loading <div class="spinner-grow text-primary"></div></h2></Col>
              ) : (
              <Col lg='6' className="m-auto text-center">
              <h2 className='font-bold mb-4 text-5xl'>Create New Admin</h2>
              <br/>
              <h2>To create a new admin, copy the link below and open it in a new browser window.</h2>
              <small className='text-red-500'>!! Do not open it in a new tab, open it in a new browser !! </small>

              <button onClick={copyToClipboard} className={ btnStyle + " text-xl text-white font-bold bg-blue-500 border w-full border-gray-500 rounded p-5 transition" } >
              {copied ? "Copied" : "Copy The Link"}
             </button>
              
            </Col>
              )
            }
          </Row>
        </Container>
      </section>
    </Helmet>
  )
}


export default AdminSignup;
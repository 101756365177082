import React from 'react';
import {Container,Row, Col} from "reactstrap";
import '../styles/dashboard.css';
import useGetData from '../custom-hooks/useGetData';
import Helmet from '../components/Helmet/Helmet';

const Dashboard = () => {

  const {data: products} = useGetData('products')
  const {data: assets} = useGetData('assets')
  const {data: users} = useGetData('users')

  return (
    <Helmet title="Admin Dashboard">
    <section>
      <Container>
        <Row>
          <Col className="lg-3">
            <div className="revenue__box">
              <h2>Total Sales</h2>
              <span>$7690</span>
            </div>
          </Col>
          <Col className="lg-3">
          <div className="order__box">
              <h2>Total Orders</h2>
              <span>769</span>
            </div>
          </Col>
          <Col className="lg-3">
          <div className="products__box">
              <h2>Total Orders</h2>
              <span>{products.length}</span>
            </div>
          </Col>
          <Col className="lg-3">
          <div className="products__box">
              <h2>Total Assets</h2>
              <span>{assets.length}</span>
            </div>
          </Col>
          <Col className="lg-3">
          <div className="users__box">
              <h2>Total Users</h2>
              <span>{users.length}</span>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
    </Helmet>
  )
}

export default Dashboard
import React from 'react';
import { Container, Row , Col } from 'reactstrap';
import useGetData from "../custom-hooks/useGetData";
import { deleteDoc, doc } from 'firebase/firestore';
import { db, auth } from '../firebase.config';
import {toast} from 'react-toastify';
import useAuth from '../custom-hooks/useAuth';

const Users = () => {
    const { currentUser } = useAuth();
    const {data: usersData, loading} = useGetData('users');

    const deleteUser = async (id) => {
        try {
            // First, delete the user from Firestore
            await deleteDoc(doc(db, 'users', id));

            // Then, get the user object from Firebase Authentication
            const user = await auth.getUser(id);

            // Check if the user UID is the one that shouldn't be deleted
            if (user.uid === process.env.REACT_APP_USER_UID) {
                toast.error('Cannot delete this user!');
                return;
            }

            // Check if the current user has permission to delete a user
            if (currentUser && currentUser.uid !== user.uid) {
                toast.error('You do not have permission to delete this user!');
                return;
            }

            // Finally, delete the user from Firebase Authentication
            await auth.deleteUser(id);
            toast.success('User deleted!');
        } catch (error) {
            console.log(error);
            toast.error('Error deleting user!');
        }
    }

    return (
        <section>
            <Container style={{justifyContent: 'center', alignItems: 'center', width: '100%', }}>
            <Row style={{
              backgroundColor: '#f8f9fa',
              border: '0',
              justifyContent: 'center',
              padding: '0.5rem',
              width: '100%'
            }}>
                    <h2 className='font-bold text-5xl'>All Admins and Super Admin</h2>
                    <Col lg='12' className='pt-5'>
                    <table className="table-auto text-[1.2rem] w-full block overflow-x-scroll ml-0">
                            <thead>
                                <tr>
                                <th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Image</th>
                                <th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Username</th>
                                <th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Email</th>
                                <th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    loading ? (<h5 className='pt-5 fw-bold'>Loading...</h5>) :
                                    (usersData?.map(user=>(
                                        <tr key={user.uid}>
                                            <td class="px-6 py-3 border-b border-black"><img src={user.photoURL} alt="" className='w-20 h-20 rounded-full ' /></td>
                                            <td class="px-6 py-3 border-b border-black">{user.displayName}</td>
                                            <td class="px-6 py-3 border-b border-black">{user.email}</td>

                                            {user && user.uid == process.env.REACT_APP_USER_UID ?
                                    <td class="px-6 py-3 text-black font-bold border-b border-black">
                                       Super Admin
                                    </td> :
                                    <td class="px-6 py-3 border-b border-black">
                                    <button onClick={() => deleteUser(user.id)} className="bg-red-600 rounded-full  text-white font-semibold">Delete Admin</button>
                                </td>
                                      }
                                        </tr>
                                    )))
                                }
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Users;

// import images

import services_logo1 from "./assets/images/Services/logo1.png";
import services_logo2 from "./assets/images/Services/logo2.png";
import services_logo3 from "./assets/images/Services/logo3.png";
export const content = {
  
  services: {
    title: "Services",
    subtitle: "WHAT I OFFER",
    service_content: [
      {
        title: "Location Tracking",
        para: "Stay informed with up-to-the-minute asset location updates, enhancing operational efficiency, security, and the ability to respond swiftly to changes in transportation or storage.",
        logo: services_logo1,
      },
      {
        title: "Inventory Tool",
        para: "Seamlessly manage your asset inventory with advanced tracking capabilities, ensuring accurate stock control, streamlined workflows, and improved resource allocation for optimal productivity.",
        logo: services_logo2,
      },
      {
        title: "Condition Monitoring",
        para: "Proactively monitor asset health and performance, leveraging sensor data and analytics to identify issues early, reduce downtime, and optimize maintenance schedules for peak operational efficiency.",
        logo: services_logo3,
      },
    ],
  },
}
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import useGetData from '../custom-hooks/useGetData';
import { db } from '../firebase.config';
import { doc, deleteDoc, updateDoc, collection, addDoc, onSnapshot } from 'firebase/firestore';
import { toast } from 'react-toastify';
import emailjs from 'emailjs-com';
// import "../styles/allorders.css";
import Helmet from "../components/Helmet/Helmet";
import useAuth from '../custom-hooks/useAuth';

const AllOrders = () => {

    const { currentUser } = useAuth();
    const { data: productsData, loading } = useGetData('products');
    const [editingProduct, setEditingProduct] = useState(null);
    const [orderHistory, setOrderHistory] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletingProductId, setDeletingProductId] = useState(null);

  const toggleDeleteModal = () => {
    setShowDeleteModal((prevState) => !prevState);
  };

  // ----------------------------------------------------------------------------------
const sendEmail = async (recipientEmail, id, shipperName, recipient, shipperEmail, item, myType, myWebsite) => {
  try {
    const templateParams = {
      user_email: recipientEmail,
      product_id: id,
      sender: shipperName,
      yourClient: recipient,
      senderEmail: shipperEmail,
      item,
      product_type: myType,
      yourWeb: myWebsite,
      // Add other variables related to the asset details that you want to include in the email template
    };

    await emailjs.send(
      process.env.REACT_APP_EMAIL_SERVICE_ID,
      process.env.REACT_APP_EMAIL_TEMPLATE_ID,
      templateParams,
      process.env.REACT_APP_EMAIL_USER_ID
    );

    // Show toast success message when email is sent successfully
    toast.success('Email sent successfully!');
  } catch (error) {
    console.error('Failed to send email:', error);
  }
};
// --------------------------------------------------------------


  
    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, 'orderHistory'), (snapshot) => {
          const data = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
          setOrderHistory(data);
        });
        return () => unsubscribe();
      }, []);
  
      const deleteProduct = async (id) => {
        setDeletingProductId(id);
        toggleDeleteModal();
      };
    
      const confirmDeleteProduct = async (id) => {
        // Perform the actual deletion logic here, for example:
        try {
          // Delete the product from the database using the provided id
          await deleteDoc(doc(db, 'products', id));
    
          // Show a success message
          toast.success('Product deleted successfully!');
        } catch (error) {
          console.error('Error deleting product:', error);
          toast.error('An error occurred while deleting the product.');
        } finally {
          // Reset the state and close the modal
          setDeletingProductId(null);
          toggleDeleteModal();
        }
      };


    const deleteOrder = async (id) => {
        await deleteDoc(doc(db, 'orderHistory', id));
        toast.success('Order History deleted!');
        };
  
    const updateOrderHistory = async (type, product) => {
      await addDoc(collection(db, 'orderHistory'), {
        type,
        product,
        timestamp: Date.now(),
      });
    };
  
    const saveEditedProduct = async (id, newData) => {
      const product = productsData.find((p) => p.id === id);
      await updateOrderHistory('edited', product);
      await updateDoc(doc(db, 'products', id), newData);
      toast.success('Order updated!');
      setEditingProduct(null);
    };

    const notifyClient = async (product) => {
      const { recipientEmail, id, shipperName, recipient, shipperEmail, item, myType, myWebsite } = product;
      if (recipientEmail) {
        await sendEmail(recipientEmail, id, shipperName, recipient, shipperEmail, item, myType, myWebsite);
      } else {
        toast.error('No client email found!');
      }
    };

const renderEditForm = (product) => (
<tr key={product.id}>
<td>
<img src={product.imgUrl} alt="" />
</td>
<td>
<input
type="text"
defaultValue={product.displayName}
disabled
onChange={(e) =>
setEditingProduct({ ...product, displayName: e.target.value })
}
/>
</td>
<td>
<input
type="text"
defaultValue={product.addedBy}
disabled
onChange={(e) =>
setEditingProduct({ ...product, addedBy: e.target.value })
}
/>
</td>
<td>
<input
type="text"
defaultValue={product.id}
disabled
onChange={(e) =>
setEditingProduct({ ...product, id: e.target.value })
}
/>
</td>
<td>
<input
type="text"
defaultValue={product.shipperName}
onChange={(e) =>
setEditingProduct({ ...product, shipperName: e.target.value })
}
/>
</td>
<td>
<input
type="text"
defaultValue={product.shipperEmail}
onChange={(e) =>
setEditingProduct({ ...product, shipperEmail: e.target.value })
}
/>
</td>
<td>
<input
type="text"
defaultValue={product.shipperAddress}
onChange={(e) =>
setEditingProduct({ ...product, shipperAddress: e.target.value })
}
/>
</td>
<td>
<input
type="text"
defaultValue={product.shipperNumber}
onChange={(e) =>
setEditingProduct({ ...product, shipperNumber: e.target.value })
}
/>
</td>
<td>
<input
type="text"
defaultValue={product.recipient}
onChange={(e) =>
setEditingProduct({ ...product, recipient: e.target.value })
}
/>
</td>
<td>
<input
type="text"
defaultValue={product.recipientAddress}
onChange={(e) =>
setEditingProduct({ ...product, recipientAddress: e.target.value })
}
/>
</td>
<td>
<input
type="text"
defaultValue={product.recipientEmail}
onChange={(e) =>
setEditingProduct({ ...product, recipientEmail: e.target.value })
}
/>
</td>
<td>
<input
type="text"
defaultValue={product.recipientNum}
onChange={(e) =>
setEditingProduct({ ...product, recipientNum: e.target.value })
}
/>
</td>
<td>
<input
type="text"
defaultValue={product.origin}
onChange={(e) =>
setEditingProduct({ ...product, origin: e.target.value })
}
/>
</td>
<td>
<input
type="text"
defaultValue={product.destination}
onChange={(e) =>
setEditingProduct({ ...product, destination: e.target.value })
}
/>
</td>
<td>
<input
type="text"
defaultValue={product.weight}
onChange={(e) =>
setEditingProduct({ ...product, weight: e.target.value })
}
/>
</td>
<td>
<input
type="text"
defaultValue={product.product}
onChange={(e) =>
setEditingProduct({ ...product, product: e.target.value })
}
/>
</td>
<td>
<input
type="number"
defaultValue={product.freight}
onChange={(e) =>
setEditingProduct({ ...product, freight: e.target.value })
}
/>
</td>
<td>
<input
type="date"
defaultValue={product.pickupDate}
onChange={(e) =>
setEditingProduct({ ...product, pickupDate: e.target.value })
}
/>
</td>
<td>
<input
type="text"
defaultValue={product.comments}
onChange={(e) =>
setEditingProduct({ ...product, comments: e.target.value })
}
/>
</td>
<td>
<input
type="text"
defaultValue={product.package}
onChange={(e) =>
setEditingProduct({ ...product, package: e.target.value })
}
/>
</td>
<td>
<input
type="text"
defaultValue={product.carrier}
onChange={(e) =>
setEditingProduct({ ...product, carrier: e.target.value })
}
/>
</td>
<td>
<input
type="text"
defaultValue={product.shipmentMode}
onChange={(e) =>
setEditingProduct({ ...product, shipmentMode: e.target.value })
}
/>
</td>
<td>
<input
type="text"
defaultValue={product.quantity}
onChange={(e) =>
setEditingProduct({ ...product, quantity: e.target.value })
}
/>
</td>
<td>
<input
type="text"
defaultValue={product.deliveryDate}
onChange={(e) =>
setEditingProduct({ ...product, deliveryDate: e.target.value })
}
/>
</td>
<td>
<input
type="text"
defaultValue={product.category}
onChange={(e) =>
setEditingProduct({ ...product, category: e.target.value })
}
/>
</td>
<td>
<input
type="text"
defaultValue={product.shipmentType}
onChange={(e) =>
setEditingProduct({ ...product, shipmentType: e.target.value })
}
/>
</td>
<td>
<input
type="number"
defaultValue={product.referenceNumber}
disabled
onChange={(e) =>
setEditingProduct({ ...product, referenceNumber: e.target.value })
}
/>
</td>
<td>
<input
type="text"
defaultValue={product.paymentMode}
onChange={(e) =>
setEditingProduct({ ...product, paymentMode: e.target.value })
}
/>
</td>
<td>
    <input
    type="text"
    defaultValue={product.currentLoc}
    onChange={(e) =>
    setEditingProduct({ ...product, currentLoc: e.target.value })
    }
    />
</td>
<td>
<button
className="bg-green-600 rounded-full  text-white font-semibold"
onClick={() => saveEditedProduct(product.id, editingProduct)}
>
Save
</button> 
<button
className="bg-black rounded-full  text-white font-semibold"
onClick={() => setEditingProduct(null)}
>
Cancel
</button>
    
</td>
</tr>
);

const renderOrderHistoryRow = (order) => (
  <tr key={order.id}>
    <td class="px-6 py-3 text-black font-bold border-b border-black">{new Date(order.timestamp).toLocaleString()}</td>
    <td class="px-6 py-3 text-black font-bold border-b border-black">{order.product.deliveryDate}</td>
    <td class="px-6 py-3 text-black font-bold border-b border-black">{order.type}</td>
    <td class="px-6 py-3 text-black font-bold border-b border-black">{order.product.currentLoc}</td>
    <td class="px-6 py-3 text-black font-bold border-b border-black">{order.product.category}</td>
    <td class="px-6 py-3 text-black font-bold border-b border-black">{order.product.carrier}</td>
    <td class="px-6 py-3 text-black font-bold border-b border-black">{order.product.comments}</td>
    <td class="px-6 py-3 border-b border-black">
    <button
       className="bg-red-600 rounded-full  text-white font-semibold"
       onClick={() => deleteOrder(order.id)}
    >
       Delete
  </button>
  </td>
  </tr>
);

const groupOrderHistoryByProductId = (orderHistory) => {
  const groupedOrderHistory = {};

  orderHistory.forEach((order) => {
    const productId = order.product.id;

    if (!groupedOrderHistory[productId]) {
      groupedOrderHistory[productId] = [];
    }

    groupedOrderHistory[productId].push(order);
  });

  return groupedOrderHistory;
};

const groupedOrderHistory = groupOrderHistoryByProductId(orderHistory);

const renderOrderHistoryTable = (productId, orderHistory) => (
  <table className="table-auto text-[1.2rem] relative w-full h-70 block overflow-x-scroll overflow-y-scroll ml-0 mt-10px">
    <thead>
      <tr>
        <th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Updated Date</th>
        <th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Delivery Date</th>
        <th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Order Type</th>
        <th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Package Current Location</th>
        <th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Status</th>
        <th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Updated By</th>
        <th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Comments</th>
        <th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Action</th>
      </tr>
    </thead>
    <tbody>
    {orderHistory.sort((a, b) => b.timestamp - a.timestamp).map((order) => renderOrderHistoryRow(order))}
    </tbody>
  </table>
);

const renderProductRow = (product) => (
<tr key={product.id}>
<td class="px-6 py-3 border-b border-black">
<img src={product.imgUrl} alt="" className='w-20 h-20 rounded-full' />
</td>

{product && product.displayName ?
        <td class="px-6 py-3 text-black font-bold border-b border-black">
        {product.displayName}
        </td> :
        <td class="px-6 py-3 text-black font-bold border-b border-black">
        Super Admin
    </td>
        }
        {product && product.addedBy ?
        <td class="px-6 py-3 text-black font-bold border-b border-black">
        {product.addedBy}
        </td> :
        <td class="px-6 py-3 text-black font-bold border-b border-black">
        {currentUser.uid}
    </td>
        }
<td class="px-6 py-3 text-black font-bold border-b border-black">{product.id}</td>
<td class="px-6 py-3 text-black font-bold border-b border-black">{product.shipperName}</td>
<td class="px-6 py-3 text-black font-bold border-b border-black">{product.shipperEmail}</td>
<td class="px-6 py-3 text-black font-bold border-b border-black">{product.shipperAddress}</td>
<td class="px-6 py-3 text-black font-bold border-b border-black">{product.shipperNumber}</td>
<td class="px-6 py-3 text-black font-bold border-b border-black">{product.recipient}</td>
<td class="px-6 py-3 text-black font-bold border-b border-black">{product.recipientAddress}</td>
<td class="px-6 py-3 text-black font-bold border-b border-black">{product.recipientEmail}</td>
<td class="px-6 py-3 text-black font-bold border-b border-black">{product.recipientNum}</td>
<td class="px-6 py-3 text-black font-bold border-b border-black">{product.origin}</td>
<td class="px-6 py-3 text-black font-bold border-b border-black">{product.destination}</td>
<td class="px-6 py-3 text-black font-bold border-b border-black">{product.weight}</td>
<td class="px-6 py-3 text-black font-bold border-b border-black">{product.product}</td>
<td class="px-6 py-3 text-black font-bold border-b border-black">{product.freight}</td>
<td class="px-6 py-3 text-black font-bold border-b border-black">{product.pickupDate}</td>
<td class="px-6 py-3 text-black font-bold border-b border-black">{product.comments}</td>
<td class="px-6 py-3 text-black font-bold border-b border-black">{product.package}</td>
<td class="px-6 py-3 text-black font-bold border-b border-black">{product.carrier}</td>
<td class="px-6 py-3 text-black font-bold border-b border-black">{product.shipmentMode}</td>
<td class="px-6 py-3 text-black font-bold border-b border-black">{product.quantity}</td>
<td class="px-6 py-3 text-black font-bold border-b border-black">{product.deliveryDate}</td>
<td class="px-6 py-3 text-black font-bold border-b border-black">{product.category}</td>
<td class="px-6 py-3 text-black font-bold border-b border-black">{product.shipmentType}</td>
<td class="px-6 py-3 text-black font-bold border-b border-black">{product.referenceNumber}</td>
<td class="px-6 py-3 text-black font-bold border-b border-black">{product.paymentMode}</td>
<td class="px-6 py-3 text-black font-bold border-b border-black">{product.currentLoc}</td>
<td class="px-6 py-3 border-b border-black">
<button className="bg-red-600 rounded-full text-white font-semibold" onClick={() => deleteProduct(product.id)}>
            Delete
</button>
<button
className="bg-blue-600 rounded-full  text-white font-semibold"
onClick={() => setEditingProduct(product)}
>
Edit
</button>
<button
      className="bg-indigo-600 rounded-full whitespace-nowrap text-white font-semibold"
      onClick={() => notifyClient(product)}
    >
      Notify Client
    </button>
</td>
</tr>
);


 // Function to filter products based on search query ------------------------------
 const [searchQuery, setSearchQuery] = useState('');
  const filterProducts = (products, query) => {
    if (!query) return products;

    const searchFields = [
      'displayName',
      'addedBy',
      'shipperName',
      'shipperEmail',
      'shipperAddress',
      'shipperNumber',
      'recipient',
      'recipientAddress',
      'recipientEmail',
      'recipientNum',
      'origin',
      'id',
      'destination',
      'weight',
      'product',
      'freight',
      'pickupDate',
      'comments',
      'package',
      'carrier',
      'shipmentMode',
      'quantity',
      'deliveryDate',
      'shipmentType',
      'referenceNumber',
      'paymentMode',
      'currentLoc',
      'category',
    ];

    query = query.toLowerCase();

    return products.filter((product) => {
      for (const field of searchFields) {
        if (product[field]?.toLowerCase().includes(query)) {
          return true;
        }
      }
      return false;
    });
  };


return (
<Helmet title="All Orders">
<section>
<Container style={{justifyContent: 'center', alignItems: 'center', width: '100%', }}>
<h2 className='font-bold text-5xl'>All Orders</h2>

<div class="relative mt-2 mb-5">
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
            </svg>
        </div>
        <input type="text"  
        class="block w-full p-4 pl-10 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
        placeholder="Search Order's country, name, tracking. destination..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
         />
        
    </div>


<Row style={{
              backgroundColor: '#f8f9fa',
              border: '0',
              justifyContent: 'center',
              padding: '0.5rem',
              width: '100%'
            }}>
<Col>
<table className="table-auto text-[1.2rem] w-full h-70 block overflow-x-scroll overflow-y-scroll ml-0">
<thead>
<tr>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Image</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>User Name</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>User UID</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Tracking No.</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Shipper Name</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Shipper Email</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Shipper Address</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Shipper Phone Number</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Recipient</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Recipient Address</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Recipient Email</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Recipient Phone Number</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Origin</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Destination</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Weight</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Product</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Total Freight</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Pick-up Date</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Comments</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Package</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Carrier</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Shipment Mode</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Quantity</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Delivery Date</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Status</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Shipment Type</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Carrier Reference No.</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Payment Mode</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Item Current Location</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Action</th>
</tr>
</thead>
<tbody>
{loading ? (
<h4 className="py-5 text-center fw-bold">Loading...</h4>
) : (
 <>
                      {editingProduct && renderEditForm(editingProduct)}
                      {filterProducts(productsData, searchQuery).map((product) =>
                        editingProduct && editingProduct.id === product.id ? (
                          renderEditForm(editingProduct)
                        ) : (
                          renderProductRow(product)
                        )
                      )}
                    </>
)}
</tbody>


</table>
</Col>
</Row>
<br/><br/>
<h2 className='font-bold text-5xl'>All Order History</h2>
<Row style={{
              backgroundColor: '#f8f9fa',
              border: '0',
              justifyContent: 'center',
              padding: '0.5rem',
              width: '100%'
            }}>
<Col>
              {Object.entries(groupedOrderHistory).map(([productId, orderHistory]) => (
                <div key={productId} className="bg-gray-200 rounded-3xl mb-5">
                  <h2 className="font-bold text-2xl md:text-3xl py-4">Order History For Tracking Pin: {productId}</h2>
                  {renderOrderHistoryTable(productId, orderHistory)}
                </div>
              ))}
            </Col>
</Row>
</Container>
{/* Modal for confirming product deletion */}
{showDeleteModal && (
          <div id="popup-modal" tabIndex="-1" className="fixed top-0 left-0 right-0 z-50 flex items-center justify-center p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-full bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-sm">
            <div className="relative w-full max-w-md max-h-full">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <button type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={toggleDeleteModal}>
                  <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
                <div className="p-6 text-center">
                  <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                  </svg>
                  <h2 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete this Order?</h2>
                  <button type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2" onClick={() => confirmDeleteProduct(deletingProductId)}>
                    Yes, I'm sure
                  </button>
                  <button type="button" className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600" onClick={toggleDeleteModal}>
                    No, cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
</section>
</Helmet>
);
};

export default AllOrders;





import { content } from "./Content";

const Services = () => {
  const { services } = content;
  return (
    <section id="services">
      <div className=" px-5 py-14 justify-center items-center">
      <h1 class="mb-4 text-3xl font-extrabold text-gray-900 dark:text-white md:text-5xl lg:text-6xl text-center"><span class="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">{services.title}</span>
          
        </h1>
        <h3 class="mb-4 text-5xl font-extrabold text-gray-900 dark:text-white md:text-5xl lg:text-6xl text-center"><span class="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">{services.title}</span>
          
        </h3>
        <h2 className="text-center" data-aos="fade-down">
        <small class="ml-2 font-semibold text-gray-500 dark:text-gray-400">
          {services.subtitle}
          </small>
        </h2>
        <br />
        <div className="flex gap-5 justify-between flex-wrap group md:mx-5">
          {services.service_content.map((content, i) => (
            <div
              key={i}
              data-aos="fade-up"
              data-aos-delay={i * 600}
              className="min-w-[14rem] duration-300 cursor-pointer border-2 border-slate-200 rounded-xl text-center bg-bg_light_primary p-6 flex-1 group-hover:blur-sm 
              hover:!blur-none"
            >
              <img src={content.logo} alt="..." className="mx-auto" />
              <h6 className="my-3 font-semibold text-gray-500 dark:text-gray-400 capitalize ">{content.title}</h6>
              <p className="leading-7 tracking-widest leading-loose">{content.para}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
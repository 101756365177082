import React, { useEffect } from 'react';

const LanguageModal = ({ isOpen, onRequestClose }) => {
  useEffect(() => {
    // Add event listener to close the modal when clicked outside
    const handleClickOutside = (event) => {
      if (isOpen && event.target.id === 'language-modal') {
        onRequestClose();
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen, onRequestClose]);

  return (
    <>
      {isOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-75 z-50">
          <div className="bg-white p-4 rounded-md max-w-md">
            <div className="text-center">
              <img src="https://cdn11.bigcommerce.com/s-2lbnjvmw4d/images/stencil/1280x1280/products/3595/4340/blob__91211.1573736195.1280.1280__18155.1573737376.jpg?c=2" alt="Language Selection" className="mx-auto mb-4" />
              <h2 className="text-3xl font-bold text-gray-500">Select Language</h2>
              <h2 className="mt-2">
              Change language preference at bottom left to view the website in your preferred language.
              </h2>
            </div>
            <div className="mt-4 flex justify-center">
              <button
                className="px-4 py-2 bg-gradient-to-r from-[#6a00ff] to-[#00d8ff] text-white rounded w-full cursor-pointer"
                onClick={onRequestClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LanguageModal;

import React, {useState} from "react"
import { Link, NavLink } from "react-router-dom"
import Typed from "react-typed"
import Heading from "../../common/heading/Heading"
import "./Hero.css"

const Hero = () => {
  const [isErasing, setIsErasing] = useState(false);

  const handleBeginTyping = () => {
    setIsErasing(false);
  };

  const handleCompleteTyping = () => {
    setIsErasing(true);
  };
 
  return (
    <>
      <section className='hero'>
        <div className='ml-15 p-5 mt-10'>
          <div className='row'>
          <>
  <Typed
          className=' mb-4 bg-[rgba(0,0,0,0.5)] text-4xl md:text-[3rem] font-bold text-center tracking-wider p-[1rem] rounded-[10px]'
            strings={['Eugogate', 'Asset Vault', 'Eugogate Asset Vault']}
            typeSpeed={120}
            backSpeed={140}
            loop
            onComplete={handleCompleteTyping}
            onBegin={handleBeginTyping}
          />
          {isErasing ? (
        <span role="img" aria-label="Eraser" className="mb-2 text-white text-6xl">
          ✍️
        </span>
      ) : (
        <span role="img" aria-label="Pen" className="mb-2 text-white text-6xl">
          ✏️
        </span>
      )}
          </>
            <h2 class="mb-4 mt-5 py-5 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white">We invest in the <span class="underline underline-offset-3 decoration-8 decoration-blue-400 dark:decoration-blue-600">world’s potential</span></h2>
            <p>Welcome to our asset tracking platform, where you can easily track and manage your valuable assets with precision and ease.</p>
            <h3>Welcome to our asset tracking platform, where you can easily track and manage your valuable assets with precision and ease.</h3>
          </div>
        </div>
      </section>
      <div className='margin'></div>
    </>
  )
}

export default Hero

import React, { useState } from 'react';
import heroImage from './sub.jpg';

const bgImageStyle = {
  backgroundImage: `url(${heroImage})`,
};

const Newsletter = () => {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send the email and default message to the desired email address
      await sendEmailToRecipient(email, 'I just want to be notified every day', 'queencynthia32@gmail.com');
      setIsSubmitted(true);
    } catch (err) {
      setError('An error occurred. Please try again later.');
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <div className=''>
      <div className='w-full py-16 text-white px-4' style={bgImageStyle}>
        <div className='max-w-[1240px] mx-auto lg:grid lg:grid-cols-3'>
          {isSubmitted ? (
            <div className='lg:col-span-3 my-4'>
              <h2 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>
                Thank you for subscribing to our newsletter!
              </h2>
            </div>
          ) : (
            <>
              <div className='lg:col-span-2 my-4'>
                <h2 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>
                  Want tips & info to optimize your flow?
                </h2>
                <h2>Sign up to our newsletter and stay up to date.</h2>
              </div>
              <div className='my-4'>
                <form onSubmit={handleSubmit}>
                  <div className='flex flex-col sm:flex-row items-center justify-between w-full'>
                    <input
                      className='p-3 flex w-full rounded-md text-black'
                      type='email'
                      placeholder='Enter Email'
                      value={email}
                      onChange={handleEmailChange}
                      required
                    />
                    <button type='submit' className='bg-[#00df9a] text-white rounded-md font-medium w-[200px] ml-4 my-6 px-6 py-3'>
                      Notify Me
                    </button>
                  </div>
                  <h2>
                    We care about the protection of your data. Read our{' '}
                    <span className='text-[#00df9a]'>Privacy Policy.</span>
                  </h2>
                  {error && <p className='text-red-500'>{error}</p>}
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Newsletter;

// Placeholder function to send email to recipient
const sendEmailToRecipient = async (senderEmail, message, recipientEmail) => {
  // Implement your logic to send the email using a server-side solution or API.
  // This is just a placeholder function.
  console.log(`Sending email to ${recipientEmail} from ${senderEmail} with message: ${message}`);
};

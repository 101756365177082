import React, {useState} from 'react';
import { Container, Row, Col, Form, FormGroup } from 'reactstrap';
import {toast} from 'react-toastify';
import "../styles/addorder.css";
import Helmet from '../components/Helmet/Helmet';

import {db,storage} from '../firebase.config';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { collection, addDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import useAuth from '../custom-hooks/useAuth';

const AddAsset = () => {

  const {currentUser} = useAuth()

  const [enterDepositor, setEnterDepositor] = useState('');
  const [enterDepositorEmail, setEnterDepositorEmail] = useState('');
  const [enterMonthlyCharges, setEnterMonthlyCharges] = useState('');
  const [enterWeight, setEnterWeight] = useState('');
  const [enterCategory, setEnterCategory] = useState('');
  const [enterDepositPurpose, setEnterDepositPurpose] = useState('');
  const [enterProductImg, setEnterProductImg] = useState(null);
  const [enterBeneficiary, setEnterBeneficiary] = useState('');
  const [enterBeneficiaryEmail, setEnterBeneficiaryEmail] = useState('');
  const [enterClientEmail, setEnterClientEmail] = useState('');
  const [enterClient, setEnterClient] = useState('');
  const [enterVaultType, setEnterVaultType] = useState('');
  const [enterItem, setEnterItem] = useState('');
  const [enterPackageValue, setEnterPackageValue] = useState('');
  const [enterDepositDate, setEnterDepositDate] = useState('');
  const [enterDemurrageStatus, setEnterDemurrageStatus] = useState('');
  const [enterComments, setEnterComments] = useState('');
  const [enterCurrency, setEnterCurrency] = useState('');
  const [enterLoc, setEnterLoc] = useState('');
  
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate()

  const addProduct = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    // ======= add asset to firebase database =======
    try {
      const docRef = await collection(db, "assets");
    
      const storageRef = ref(storage, `productImages/${Date.now() + enterProductImg.name}`);
      const uploadTask = uploadBytesResumable(storageRef, enterProductImg);

      const invoiceNumber = Math.floor(Math.random() * 9000000) + 1000000;
      const RealInvoiceNumber = invoiceNumber.toString();

      const serialNumber = Math.floor(Math.random() * 9000000000) + 1000000000;
      const RealSerialNumber = serialNumber.toString();

      const transactionCode = Math.floor(Math.random() * 9000000000) + 1000000000;
      const RealTransactionCode = transactionCode.toString();

      const vaultAccessCode = Math.floor(Math.random() * 90000000) + 10000000;
      const RealVaultAccessCode = vaultAccessCode.toString();

      const assetType = "Asset Vault";
      const assetWebsite = "https://eugogatelogistics.com/asset-value-portal"
    
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // handle upload progress
        },
        (error) => {
          setLoading(false);
          toast.error("Image not uploaded! Error: " + error.message);
        },
        async () => {
          const downloadURL = await getDownloadURL(
            uploadTask.snapshot.ref
          );
          
          
          await addDoc(docRef, {
            depositor: enterDepositor,
            depositorEmail: enterDepositorEmail,
            monthlyCharges: enterMonthlyCharges,
            depositPurpose: enterDepositPurpose,
            weight: enterWeight,
            category: enterCategory,
            imgUrl: downloadURL,
            beneficiary: enterBeneficiary,
            beneficiaryEmail: enterBeneficiaryEmail,
            yourClient: enterClient,
            yourClientEmail: enterClientEmail,
            assetWebsite: assetWebsite,
            vaultType: enterVaultType,
            invoiceNumber: RealInvoiceNumber,
            serialNumber: RealSerialNumber,
            transactionCode: RealTransactionCode,
            vaultAccessCode: RealVaultAccessCode,
            myassetType: assetType,
            item: enterItem,
            packageValue: enterPackageValue,
            depositDate: enterDepositDate,
            currency: enterCurrency,
            demurrageStatus: enterDemurrageStatus,
            comments: enterComments,
            location: enterLoc,
            addedBy: currentUser.uid, // add the uid of the current user who added the product
            displayName: currentUser.displayName
          });
          setLoading(false);
          toast.success("Asset added successfully!");
          navigate("/dashboard/all-assets");
        }
      );
    } catch (err) {
      setLoading(false);
      toast.error("Asset not added! Error: " + err.message);
    }
  };
  
  
  
  return (
    <Helmet title="Add Asset">
    <section>
    <Container style={{justifyContent: 'center', alignItems: 'center', width: '100%', }}>
      <Row style={{
              backgroundColor: '#f8f9fa',
              border: '0',
              justifyContent: 'center',
              padding: '0.5rem',
              width: '100%'
            }}>
          <Col lg="12">
            {
              loading ? <h2 className='py-5 font-bold text-4xl text-center'>Creating Asset, Please wait... <div role="status">
              <svg aria-hidden="true" class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-purple-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
              </svg>
              <span class="sr-only">Loading...</span>
          </div></h2> : <>
               <h2 className='font-bold text-5xl text-center'>Add Asset</h2>
            <Form onSubmit={addProduct}>
              <FormGroup className="form__group">
                <span>Depositor Name:</span>
                <input type="text" placeholder='John Doe' value={enterDepositor} onChange={e=> setEnterDepositor(e.target.value)} required />
              </FormGroup>

              <FormGroup className="form__group">
                <span>Depositor Email:</span>
                <input type="email" placeholder='john@gmail.com' value={enterDepositorEmail} onChange={e=> setEnterDepositorEmail(e.target.value)} required />
              </FormGroup>
              
              <FormGroup className="form__group">
                <span>Charges Per Month</span>
                <input type="number" placeholder='300'  value={enterMonthlyCharges} onChange={e=> setEnterMonthlyCharges(e.target.value)} required />
              </FormGroup>

              <div className='d-flex align-items-center justify-content-between gap-5'>
              <FormGroup className="form__group w-50">
                <span>Deposit Purpose</span>
                <input type="text" placeholder='Safe Keeping'  value={enterDepositPurpose} onChange={e=> setEnterDepositPurpose(e.target.value)} required />
              </FormGroup>

              <FormGroup className="form__group w-50">
                <span>Location Of Asset</span>
                <input type="text" placeholder='Canada'  value={enterLoc} onChange={e=> setEnterLoc(e.target.value)} required />
              </FormGroup>

              <FormGroup className="form__group w-50">
                <span>Shipment Status</span>
                <select 
                  className='w-100 p-2'  
                  value={enterCategory} 
                  onChange={e=> setEnterCategory(e.target.value)}
                >
                <option>Shipment Status</option>
                  <option value="pending">pending</option>
                  <option value="awaiting payment">Awaiting Payment</option>
                  <option value="awaiting fulfillment">Awaiting Fulfillment</option>
                  <option value="awaiting shipment">Awaiting Shipment</option>
                  <option value="partially shipped">Partially Shipped</option>
                  <option value="awaiting pickup">Awaiting Pickup</option>
                  <option value="completed">Completed</option>
                  <option value="shipped">Shipped</option>
                  <option value="cancelled">Cancelled</option>
                  <option value="declined">Declined</option>
                  <option value="on demand">On Demand</option>
                  <option value="on hold">On Hold</option>
                  <option value="refunded">Refunded</option>
                  <option value="disputed">Disputed</option>
                  <option value="manual verification required">Manual Verification Required</option>
                  <option value="partially refunded">Partially Refunded</option>
                </select>
              </FormGroup>
              </div>

              
              <div>
              <FormGroup className="form__group">
              <span>Preferred Currency</span>
                <select 
                  className='w-100 p-2' 
                  value={enterCurrency} 
                  onChange={e=> setEnterCurrency(e.target.value)}
                >
                <option>Account Currency</option>
                  <option value="$">$</option>
                  <option value="€">€</option>
                  <option value="₹">₹</option>
                  <option value="¥">¥</option>
                  <option value="₵">₵</option>
                  <option value="£">£</option>
                  <option value="₦">₦</option>
                </select>
              </FormGroup>

              <FormGroup className="form__group">
                <span>Depositor Image</span>
                <input type="file" onChange={e=> setEnterProductImg(e.target.files[0])} required />
              </FormGroup>

              <div className='d-flex align-items-center justify-content-between gap-5'>
              <FormGroup className="form__group w-50">
                <span>Vault Type</span>
                <input type="text" placeholder='Safety Deposit Box'  value={enterVaultType} onChange={e=> setEnterVaultType(e.target.value)} required />
              </FormGroup>

              <FormGroup className="form__group w-50">
              <span>Weight</span>
                <input type="text" placeholder='300Kg'  value={enterWeight} onChange={e=> setEnterWeight(e.target.value)} required />
              
              </FormGroup>
              </div>

              <FormGroup className="form__group">
                <span>Beneficiary Name</span>
                <input type="text" placeholder='Dani Daniels'  value={enterBeneficiary} onChange={e=> setEnterBeneficiary(e.target.value)} required />
              </FormGroup>

              <FormGroup className="form__group">
                <span>Beneficiary Email</span>
                <input type="email" placeholder='dani@gmail'  value={enterBeneficiaryEmail} onChange={e=> setEnterBeneficiaryEmail(e.target.value)} required />
              </FormGroup>

              <FormGroup className="form__group">
                <span>Your Client's Name</span>
                <input type="text" placeholder='Paul Doe'  value={enterClient} onChange={e=> setEnterClient(e.target.value)} required />
              </FormGroup>

              <FormGroup className="form__group">
                <span>Your Client's Email</span>
                <input type="email" placeholder='paul@gmail'  value={enterClientEmail} onChange={e=> setEnterClientEmail(e.target.value)} required />
              </FormGroup>

              <FormGroup className="form__group">
                <span>Item(s)</span>
                <input type="text" placeholder='Gold'  value={enterItem} onChange={e=> setEnterItem(e.target.value)} required />
              </FormGroup>

              <FormGroup className="form__group">
                <span>Package Value</span>
                <input type="number" placeholder='5000000'  value={enterPackageValue} onChange={e=> setEnterPackageValue(e.target.value)} required />
              </FormGroup>

              <FormGroup className="form__group">
                <span>Date Of Deposit</span>
                <input type="date"  value={enterDepositDate} onChange={e=> setEnterDepositDate(e.target.value)} required />
              </FormGroup>

              <FormGroup className="form__group">
                <span>Demurrage Status</span>
                <input type="text" placeholder='To Be Paid'  value={enterDemurrageStatus} onChange={e=> setEnterDemurrageStatus(e.target.value)} required />
              </FormGroup>

              <FormGroup className="form__group">
                <span>Comments</span>
                <input type="text" placeholder='Positive'  value={enterComments} onChange={e=> setEnterComments(e.target.value)} required />
              </FormGroup>
              
              </div>

              <button className="bg-blue-500 text-white font-bold rounded-xl w-full" type="submit">Add Asset</button>
            </Form>
              </>
            }
          </Col>
        </Row>
      </Container>
    </section>
    </Helmet>
  )
}

export default AddAsset;
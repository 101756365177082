import React, {useState} from 'react';
import Main from "../Main/Main";
import RequestQuote from "./RequestQuote";
import "./main.css";
import Testimonial from './Testimonial';
import AboutMe from '../../Compo/AboutMe';
import Skills from '../../Compo/Skills'
import Portfolio from '../../Compo/Portfolio';
import Companies from '../AssetValuePortal/components/Companies';
import Footer from '../AssetValuePortal/components/Footer';
import Air from '../../assets/assets/air.jpg'
import Staff from '../../assets/assets/staff.png'
import Truck3 from '../../assets/assets/car.jpg'
import Logistics from '../../assets/Logistics.mp4'

function OrderPortal() {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send the email and default message to the desired email address
      await sendEmailToRecipient(email, 'I just want to be notified every day', 'queencynthia32@gmail.com');
      setIsSubmitted(true);
    } catch (err) {
      setError('An error occurred. Please try again later.');
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };


  return (
  <>
  <Main/>
  <Portfolio/>
  <AboutMe/>
  <div class="mobileQuote" style={{  width: '100%', lgWidth: '50%', padding: '2rem', backgroundImage: 'linear-gradient(to bottom right, blue, red, yellow, blue)'  }}>
  {isSubmitted ? (
            <div className='lg:col-span-3 my-4'>
              <h3 className='md:text-4xl sm:text-3xl text-center text-white text-2xl font-bold py-2'>
                Thank you for requesting for a Quote!
              </h3>
            </div>
          ) : (
            <>
          <h2 style={{ fontSize: '3rem', fontWeight: 'bold', marginBottom: '1rem', color: 'white' }}>Request a Quote</h2>
          <form style={{ display: 'grid', gridTemplateColumns: '1fr', gap: '1rem' }} onSubmit={handleSubmit}>
            <div>
              <label style={{ display: 'block', color: 'white', fontWeight: 'bold', marginBottom: '0.5rem' }} htmlFor="name">
                Full Name
              </label>
              <input
                style={{ width: '100%', borderRadius: '0.5rem', padding: '0.5rem', border: '1px solid gray', outline: 'none', boxShadow: '0 0 0 2px transparent', transition: 'box-shadow 0.3s ease-in-out' }}
                id="name"
                type="text"
                placeholder="John Doe"
              />
            </div>
            <div>
              <label style={{ display: 'block', color: 'white', fontWeight: 'bold', marginBottom: '0.5rem' }} htmlFor="email">
                Email
              </label>
              <input
                style={{ width: '100%', borderRadius: '0.5rem', padding: '0.5rem', border: '1px solid gray', outline: 'none', boxShadow: '0 0 0 2px transparent', transition: 'box-shadow 0.3s ease-in-out' }}
                id="email"
                value={email}
                onChange={handleEmailChange}
                type="email"
                placeholder="john.doe@example.com"
              />
            </div>
            <div>
              <label style={{ display: 'block', color: 'white', fontWeight: 'bold', marginBottom: '0.5rem' }} htmlFor="phone">
                Phone Number
              </label>
              <input
                style={{ width: '100%', borderRadius: '0.5rem', padding: '0.5rem', border: '1px solid gray', outline: 'none', boxShadow: '0 0 0 2px transparent', transition: 'box-shadow 0.3s ease-in-out' }}
                id="phone"
                type="tel"
                placeholder="555-555-5555"
              />
            </div>
            <div>
            <label style={{ display: 'block', color: 'white', fontWeight: 'bold', marginBottom: '0.5rem' }} htmlFor="message">
Message
</label>
<textarea
style={{ width: '100%', borderRadius: '0.5rem', padding: '0.5rem', border: '1px solid gray', outline: 'none', boxShadow: '0 0 0 2px transparent', transition: 'box-shadow 0.3s ease-in-out' }}
id="message"
placeholder="Please provide details about your shipping needs."
/>
</div>
<div>
<button
style={{ backgroundColor: 'black', width: '100%', color: 'white', borderRadius: '0.5rem', padding: '0.5rem', border: 'none', fontWeight: 'bold', cursor: 'pointer', transition: 'background-color 0.3s ease-in-out' }}
type="submit"
>
Submit
</button>
</div>
{error && <p className='text-red-500'>{error}</p>}
</form>
</>
          )}
</div>
      <Skills/>
      <div class="block md:hidden items-center justify-center p-5 max-w-full bg-white border border-gray-200 rounded-lg shadow dark:border-gray-700">
    <a href="#">
        <img class="rounded-t-lg" src={Air} alt="" />
    </a>
    <div class="p-5">
        <a href="#">
            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-black">Fast, Reliable Air Cargo</h5>
        </a>
        <h6 class="mb-3 font-normal text-gray-700 dark:text-gray-600">Rapid and dependable air transportation services for swift delivery of goods worldwide.</h6>
       
    </div>
</div>

<div class="block md:hidden items-center justify-center p-5 max-w-full bg-white border border-gray-200 rounded-lg shadow dark:border-gray-700">
    <a href="#">
        <img class="rounded-t-lg" src={Truck3} alt="" />
    </a>
    <div class="p-5">
        <a href="#">
            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-black">Efficient Land Transportation</h5>
        </a>
        <h6 class="mb-3 font-normal text-gray-700 dark:text-gray-600">Efficient and secure land transportation solutions for seamless logistics and timely deliveries.</h6>
        
    </div>
</div>

<div class="block md:hidden items-center justify-center p-5 max-w-full bg-white border border-gray-200 rounded-lg shadow dark:border-gray-700">
    <a href="#">
        <img class="rounded-t-lg" src={Staff} alt="" />
    </a>
    <div class="p-5">
        <a href="#">
            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-black">Smooth Ocean Shipping Solutions</h5>
        </a>
        <h6 class="mb-3 font-normal text-gray-700 dark:text-gray-600">Reliable and cost-effective ocean shipping services for efficient global cargo transportation and supply chain management.</h6>
       
    </div>
</div>

<section class="bg-white dark:bg-white block md:hidden lg:hidden">
    <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16">
        <div class="bg-gray-50 dark:bg-gray-100 border border-gray-200 dark:border-gray-200 rounded-lg p-8 md:p-12 mb-8">
            <a href="#" class="bg-blue-100 text-blue-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded-md dark:bg-gray-700 dark:text-blue-400 mb-2">
                <svg class="w-3 h-3 mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path>
                </svg>
                About Us
            </a>
            <h3 class="text-gray-900 dark:text-black text-3xl md:text-5xl font-extrabold mb-2">Efficient Logistics and Seamless Shipping Solutions</h3>
            <h3 class="text-lg font-normal text-gray-500 dark:text-gray-500 mb-6">Delivering seamless logistics and shipping solutions, we ensure efficient transportation, reliable supply chain management, and streamlined global distribution for your business.</h3>
            
        </div>
        </div>
</section>



<video class="w-100 mx-auto h-auto max-w-100 border border-gray-200 rounded-lg bg-gray-300 dark:border-gray-700" autoplay muted controls>
  <source src={Logistics} type="video/mp4"/>
</video>

      <RequestQuote/>
  <Testimonial/>
  <Companies/>
  <Footer/>
  </>
  );
}

export default OrderPortal;

// Placeholder function to send email to recipient
const sendEmailToRecipient = async (senderEmail, message, recipientEmail) => {
  // Implement your logic to send the email using a server-side solution or API.
  // This is just a placeholder function.
  console.log(`Sending email to ${recipientEmail} from ${senderEmail} with message: ${message}`);
};
import React, { useState } from "react";
import '../styles/admin-nav.css';
import Logo from "../components/Logo/Logo"
import { NavLink } from "react-router-dom";

function Navbar() {
  const [active, setActive] = useState("nav__menu");
  const [icon, setIcon] = useState("nav__toggler");
  const navToggle = () => {
    if (active === "nav__menu") {
      setActive("nav__menu nav__active");
    } else setActive("nav__menu");

    // Icon Toggler
    if (icon === "nav__toggler") {
      setIcon("nav__toggler toggle");
    } else setIcon("nav__toggler");
  };
  return (
    <nav className="nav">
      <NavLink to='home' className="nav__brand">
        <Logo/>
      </NavLink>
      <ul className={active}>
        <li className="nav__item">
          <NavLink to='mini/dashboard' className="nav__link">
          Dashboard
          </NavLink>
        </li>
       
        <li className="nav__item">
          <NavLink to='mini/dashboard/add-order' className="nav__link">
          Add Orders
          </NavLink>
        </li>
        <li className="nav__item">
          <NavLink to='mini/dashboard/all-orders' className="nav__link">
          All Orders
          </NavLink>
        </li>
        <li className="nav__item">
          <NavLink to='mini/dashboard/add-asset' className="nav__link">
          Add Asset
          </NavLink>
        </li>
        <li className="nav__item">
          <NavLink to='mini/dashboard/all-assets' className="nav__link">
          All Assets
          </NavLink>
        </li>
        
      </ul>
      <div onClick={navToggle} className={icon}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
    </nav>
  );
}

export default Navbar;

export const courses = [
    {
      id: 1,
      title: 'Asset Evaluation',
      category:'Services',
      rating:5,
      linkImg:
      'https://upload.wikimedia.org/wikipedia/commons/4/41/A_woman_starts_to_fill_in_an_employee_evaluation_form_in_pen.jpg'
    },
    {
      id: 2,
      title: 'Performance Monitoring',
      category:'Services',
      rating:4,
      linkImg:
        'https://elearningindustry.com/wp-content/uploads/2016/01/4-learning-evaluation-models-can-use-e1452862791439.jpg',
    },
    {
      id: 3,
      title: 'Risk Assessment',
      category:'Services',
      rating:4,
      linkImg:
        'https://pxl-imperialacuk.terminalfour.net/fit-in/687x378/prod01/channel_2/media/migration/administration-and-support-services/shutterstock_97474436--tojpeg_1442831414615_x4.jpg',
    },
    {
      id: 4,
      title: 'Financial Analysis',
      category:'Services',
      rating:4,
      linkImg:
        'https://humanitarianglobal.com/wp-content/uploads/2021/11/A-team-discussing-results-in-a-meeting.jpg',
    },
    {
      id: 5,
      title: 'Portfolio Optimization',
      category:'Services',
      rating:4,
      linkImg:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcxhpOTxFzHbrqMXgf5fNqt5xIq4E8j2ursTZr_FdPih6T36XB1j3dCxJ4vt12UxpWIDk&usqp=CAU',
    }
  ]
import React from 'react';
import Routers from '../../routers/Routers'
import { useLocation } from 'react-router-dom';

import MiniAdminNav from '../../miniAdmin/MiniAdminNav';
import AdminNav from '../../admin/AdminNav';
import Navbar from '../../components/components/Navbar';


import AssetPortalNavbar from '../AssetValuePortal/AssetPortalNavbar';
import CheckAssetNavbar from '../AssetValuePortal/CheckAsset/CheckAssetNavbar';
import OrderPortalNavbar from '../TrackOrderPortal/OrderPortalNavbar';
import CheckOrderNavbar from '../TrackOrderPortal/Parcel/CheckOrderNavbar';



const Layout = () => {

  const location = useLocation()
{/*
  const navbarComponent = location.pathname.startsWith('/mini/dashboard')
  ? ( <MiniAdminNav /> )
  : location.pathname.startsWith('/dashboard')
  ? ( <AdminNav /> )
  : <Navbar />;
*/}
  
  
  const navbarComponent = location.pathname.startsWith('/asset-value-portal') ? (
    <AssetPortalNavbar />
      ) : location.pathname.startsWith('/check-asset')
      ? ( <CheckAssetNavbar /> )
      : location.pathname.startsWith('/parcel')
      ? ( <CheckOrderNavbar /> )
      : location.pathname.startsWith('/order-portal')
      ? ( <OrderPortalNavbar /> )
      : location.pathname.startsWith('/mini/dashboard')
      ? ( <MiniAdminNav /> )
      : location.pathname.startsWith('/dashboard')
      ? ( <AdminNav /> )
      : <Navbar />;
  

  return (
  <>
  {navbarComponent} 
    <div>
     <Routers/>
    </div>
    
  </>
  );
};

export default Layout
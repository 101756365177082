import React from 'react';
import { companyLogo1, companyLogo2, companyLogo3, companyLogo4  } from '../assets';

const Companies = () => {
  return (
    <div className='w-full bg-white py-[20px]'>
        <div className='md:max-w-[1480px] m-auto max-w-[600px]  px-4 md:px-0'>
        <h1 class="mb-4 text-3xl font-extrabold text-gray-900 dark:text-white md:text-5xl lg:text-6xl text-center"><span class="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">Trusted by over 25,000 teams around the world.</span></h1>
        <h3 class="mb-4 text-5xl font-extrabold text-gray-900 dark:text-white text-center"><span class="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">Trusted by over 25,000 teams around the world.</span></h3>
            <p className='text-center  text-[#536E96] text-2xl'>Unlocking Asset Value Potential: Supporting Companies with Comprehensive Asset Value Tracking Solutions.</p>
            <h3 className='text-center  text-[#536E96] text-xl'>Unlocking Asset Value Potential: Supporting Companies with Comprehensive Asset Value Tracking Solutions.</h3>
            <div className='flex justify-center py-8 md:gap-8 '>
                <div className='grid md:grid-cols-4 grid-cols-2 gap-2'>
                  <img src={companyLogo1} />
                  <img src={companyLogo2} />
                  <img src={companyLogo3} />
                  <img src={companyLogo4} />
                </div>
            </div>
        
        </div>

    </div>
  )
}

export default Companies
import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Testimonial.css";
import { Avatar } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";

const testimonials = [
  {
    name: "Paula Wilson",
    work: "Supply Chain Manager",
    feedback:
      "Shipping with your company was a breeze! From the easy online booking process to the timely delivery of my package, everything was smooth and hassle-free. I will definitely be using your services again",
    img: "https://www.tutorialrepublic.com/examples/images/clients/1.jpg",
  },
  {
    name: "Gideon Smith",
    work: "Warehouse Operations Coordinator",
    feedback:
      "I was extremely impressed with the shipping services provided by your company. The process was seamless and my package arrived in perfect condition and on time. I will definitely be using your services again in the future!",
    img: "https://media.istockphoto.com/id/1135381173/photo/portrait-of-a-young-man-outdoors-smiling.jpg?s=612x612&w=0&k=20&c=J8DKGHI8o-oj8cY1CCNpFY2V9OmVVbJuKSO2DdbMvRg=",
  },
  {
    name: "Sarah Johnson",
    work: "Freight Forwarding Specialist",
    feedback:
      "I was really impressed by the quick and reliable shipping services provided by this company. They delivered my package on time and in perfect condition. I highly recommend them for all your shipping needs.",
    img: "https://53.fs1.hubspotusercontent-na1.net/hub/53/hubfs/Customer-testimonial-page.jpg?width=595&height=400&name=Customer-testimonial-page.jpg",
  },
  {
    name: "Michael Thompson",
    work: "International Trade Compliance Analyst",
    feedback:
      "I have been using your shipping services for several years now and I am always impressed by your professionalism and efficiency. My packages always arrive on time and in great condition. Your customer service is also top-notch!",
    img: "https://www.tutorialrepublic.com/examples/images/clients/3.jpg",
  },
  {
    name: "Philip Muller",
    work: "Import/Export Coordinator",
    feedback:
      "Thanks to your shipping company, my business has been able to expand and reach new markets. Your logistics solutions are efficient, reliable, and affordable. I would recommend your services to anyone in need of shipping.",
    img: "https://www.findtherightclick.com/wp-content/uploads/2017/07/Matt-T-Testimonial-pic.jpg",
  },
];

const PreviousBtn = (props) => {
  console.log(props);
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowBackIos style={{ color: "gray", fontSize: "45px" }} />
    </div>
  );
};

const NextBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowForwardIos style={{ color: "gray", fontSize: "45px" }} />
    </div>
  );
};

const Testimonial = () => {
  return (
    <div
      className="testimonial"
      style={{ display: "flex", justifyContent: "center", marginTop: 50 }}
    >
      <div style={{ width: "50%", textAlign: "center" }}>
        <h2 style={{ marginBottom: 20 }} className='font-bold text-4xl'>TESTIMONIALS</h2>
        <Slider prevArrow={<PreviousBtn />} nextArrow={<NextBtn />} dots>
          {testimonials.map((testimonial) => (
            <Card
              img={testimonial.img}
              name={testimonial.name}
              work={testimonial.work}
              feedback={testimonial.feedback}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
};

const Card = (props) => {
const { img, name, work, feedback } = props;
return (
<div className="card mb-4 mt-4" style={{ width: "70%", margin: "auto" }}>
<div className="card-header">
<Avatar src={img} style={{ margin: "0 auto", width: "80px", height: "80px" }} />
<div style={{ marginLeft: 10 }}>
<h2>{name}</h2>
<h2 style={{ fontSize: "18px", fontWeight: "bold", color: "#333", margin: "10px 0" }}>{work}</h2>
</div>
</div>
<div className="card-body">
<p>{feedback}</p>
<h3>{feedback}</h3>
</div>
</div>
);
};

export default Testimonial;

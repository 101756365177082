import React from 'react'
import './../assetss'
import './../Compo'
import { book, laptop, wave } from './../assetss'
import { aboutSection, AppText } from '../Constantss'
const AboutMe = () => {
  return (
    <div id="aboutme" className='mt-[100px]'>
          <img src={wave}  className="w-screen absolute " />
        <div className='w-full bg-purple-600 h-[300px]  justify-center items-center'>
        <div className='flex flex-row justify-between absolute'>
            <img src={laptop}  className="w-[150px] md:w-[300px] mt-[-100px] " />
        </div>
            <h1 className='text-white text-[50px] pt-[20px] font-bold text-center'>Our <span className='text-black'>Services</span></h1>
            <h3 className='text-white text-[30px] pt-[20px] font-bold text-center'>Our <span className='text-black'>Services</span></h3>
            <h1 className='w-full px-10 md:px-48 lg:px-80 mt-[50px] text-white'>{AppText.aboutMeDescripion} </h1>
            <h3 className='w-full px-10 md:px-48 lg:px-80 mt-[50px] text-white text-center'>{AppText.aboutMeDescripion} </h3>
        </div>
        <div className='flex flex-col md:flex-row mt-[-75px] md:px-32 '>
           {
            aboutSection.map((item,index)=>(
               
                <div key={index} className="flex  relative w-full items-center justify-center flex-col m-2
                group md:hover:bg-purple-600 rounded-lg py-2">
                <img src={item.image} className="w-[230px] rounded-lg h-[170px] object-cover hidden md:block lg:block"/>
                <h1 className='font-bold md:group-hover:text-white'>{item.title}</h1>
                <h1 className='text-[12px] md:group-hover:text-gray-200 text-gray-500 px-7'>{item.desc}</h1>
                    </div>
             

            ))
            }
            
        </div>
    </div>
   
  )
}

export default AboutMe
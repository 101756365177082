import React, {useState} from 'react';

const RequestQuote = () => {
    const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send the email and default message to the desired email address
      await sendEmailToRecipient(email, 'I just want to be notified every day', 'queencynthia32@gmail.com');
      setIsSubmitted(true);
    } catch (err) {
      setError('An error occurred. Please try again later.');
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };


  return (
    <div class="mobileQuote" style={{  width: '100%', lgWidth: '50%', padding: '2rem', backgroundImage: 'linear-gradient(to bottom right, blue, red, yellow, blue)'  }}>
  {isSubmitted ? (
            <div className='lg:col-span-3 my-4'>
              <h3 className='md:text-4xl sm:text-3xl text-center text-white text-2xl font-bold py-2'>
                Thank you for requesting for a Quote!
              </h3>
            </div>
          ) : (
            <>
          <h2 style={{ fontSize: '3rem', fontWeight: 'bold', marginBottom: '1rem', color: 'white' }}>Request a Quote</h2>
          <form style={{ display: 'grid', gridTemplateColumns: '1fr', gap: '1rem' }} onSubmit={handleSubmit}>
            <div>
              <label style={{ display: 'block', color: 'white', fontWeight: 'bold', marginBottom: '0.5rem' }} htmlFor="name">
                Full Name
              </label>
              <input
                style={{ width: '100%', borderRadius: '0.5rem', padding: '0.5rem', border: '1px solid gray', outline: 'none', boxShadow: '0 0 0 2px transparent', transition: 'box-shadow 0.3s ease-in-out' }}
                id="name"
                type="text"
                placeholder="John Doe"
              />
            </div>
            <div>
              <label style={{ display: 'block', color: 'white', fontWeight: 'bold', marginBottom: '0.5rem' }} htmlFor="email">
                Email
              </label>
              <input
                style={{ width: '100%', borderRadius: '0.5rem', padding: '0.5rem', border: '1px solid gray', outline: 'none', boxShadow: '0 0 0 2px transparent', transition: 'box-shadow 0.3s ease-in-out' }}
                id="email"
                value={email}
                onChange={handleEmailChange}
                type="email"
                placeholder="john.doe@example.com"
              />
            </div>
            <div>
              <label style={{ display: 'block', color: 'white', fontWeight: 'bold', marginBottom: '0.5rem' }} htmlFor="phone">
                Phone Number
              </label>
              <input
                style={{ width: '100%', borderRadius: '0.5rem', padding: '0.5rem', border: '1px solid gray', outline: 'none', boxShadow: '0 0 0 2px transparent', transition: 'box-shadow 0.3s ease-in-out' }}
                id="phone"
                type="tel"
                placeholder="555-555-5555"
              />
            </div>
            <div>
            <label style={{ display: 'block', color: 'white', fontWeight: 'bold', marginBottom: '0.5rem' }} htmlFor="message">
Message
</label>
<textarea
style={{ width: '100%', borderRadius: '0.5rem', padding: '0.5rem', border: '1px solid gray', outline: 'none', boxShadow: '0 0 0 2px transparent', transition: 'box-shadow 0.3s ease-in-out' }}
id="message"
placeholder="Please provide details about your shipping needs."
/>
</div>
<div>
<button
style={{ backgroundColor: 'black', width: '100%', color: 'white', borderRadius: '0.5rem', padding: '0.5rem', border: 'none', fontWeight: 'bold', cursor: 'pointer', transition: 'background-color 0.3s ease-in-out' }}
type="submit"
>
Submit
</button>
</div>
{error && <p className='text-red-500'>{error}</p>}
</form>
</>
          )}
</div>
  )
}

export default RequestQuote;

// Placeholder function to send email to recipient
const sendEmailToRecipient = async (senderEmail, message, recipientEmail) => {
    // Implement your logic to send the email using a server-side solution or API.
    // This is just a placeholder function.
    console.log(`Sending email to ${recipientEmail} from ${senderEmail} with message: ${message}`);
  };
import React, { useState } from "react";

/* import react-icons */
import { BiChevronDown } from "react-icons/bi";

/* import framer-motion */
import { motion, AnimatePresence } from "framer-motion";

const Accordion = ({ question, answer }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      {/* question section */}
      <div
        onClick={() => setShow(!show)}
        className="flex justify-between items-center cursor-pointer"
      >
        <h2 className="text-2xl md:text-3xl font-semibold text-green-500">{question}</h2>
       {/* <BiChevronDown
          className={`text-3xl transition-all duration-500 ${
            show ? "rotate-180" : ""
          }`}
        ></BiChevronDown>
        */}
        <svg data-accordion-icon className={`text-3xl text-green-500 transition-all w-10 h-10 shrink-0 duration-500 ${
            show ? "rotate-180" : ""
          }`}  fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"></path></svg>
      </div>

      {/* answer section */}
      <AnimatePresence>
        {show && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            transition={{ duration: 0.3 }}
            className="overflow-clip"
          >
            <p className="pt-3 text-2xl md:text-xl">{answer}</p>
            <h3 className="pt-3 text-xl ">{answer}</h3>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Accordion;
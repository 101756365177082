import React from 'react';
import useAuth from '../custom-hooks/useAuth';
import { Navigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

const ProtectedRoute = () => {

    const {currentUser} = useAuth();

    // just added this //////////////////////////
    if (!currentUser) {
      return <Navigate to='/login-admin' state={{ error: 'You must be logged in to access this page' }} />;
    }
    ///////////////////

  return ( <Outlet />
  )
};

export default ProtectedRoute
import React, { useState, useEffect } from 'react';
import Analytics from '../components/components/Analytics';
import Footer from '../components/AssetValuePortal/components/Footer';
import Hero from '../components/components/Hero';
import Newsletter from '../components/components/Newsletter';
import Service from '../components/components/Service';
import Faq from '../components/components/Faq';
import Testimonials from '../components/components/Testimonials';
import Portfolio  from '../Compo/Portfolio';
import Companies from '../components/AssetValuePortal/components/Companies'
import RequestQuote from '../Compo/RequestQuote';
import LanguageModal from '../components/LanguageModal';

function App() {
  const [showModal, setShowModal] = useState(true);

  const handleCloseModal = () => {
    // The modal is closed by the user, save the selected language
    localStorage.setItem('preferredLanguage', 'userSelectedLanguage');
    setShowModal(false);
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem('preferredLanguage');
    if (storedLanguage) {
      // If the preferred language is already selected, don't show the modal
      setShowModal(false);
    }
  }, []);


  return (
    <div>
      <Hero />
      <Service/>
      <RequestQuote/>
      <Testimonials/>
      <Portfolio/>
      <Analytics />
      <Faq />
      <Companies/>
      <Newsletter />
      <Footer />
         {/* Language Modal */}
         {showModal && <LanguageModal isOpen={showModal} onRequestClose={handleCloseModal} />}
    </div>
  );
}

export default App;



/*
import React from 'react';
import Clients from '../components/Clients/Clients';
import Content from '../components/Content/Content';
import Features from '../components/Features/Features';
import Hero from '../components/Hero/Hero';
import { heroOne, heroTwo, heroThree, heroFour } from '../data/HeroData';
import { Heading } from '../globalStyles';

const Home = () => {
	return (
		<>
			<Hero />
			<Heading id="projects">Our Projects</Heading>
			<Content {...heroOne} />
			<Content {...heroTwo} />
			<Content {...heroThree} />
			<Content {...heroFour} />
            <Clients />
            <Features />
		</>
	);
};

export default Home;

*/
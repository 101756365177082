import React from 'react';
import Truck1 from '../../assets/assets/truck1.webp';
import Truck2 from '../../assets/assets/truck2.gif';
import Truck3 from '../../assets/assets/car.jpg';
import Air from '../../assets/assets/air.jpg';
import Staff from '../../assets/assets/staff.png';

const Analytics = () => {
  return (
    /*
    <div className='w-full bg-white py-16 px-4'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        <img className='w-[500px] mx-auto my-4' src={Laptop} alt='/' />
        <div className='flex flex-col justify-center'>
          <p className='text-[#00df9a] font-bold '>DATA ANALYTICS DASHBOARD</p>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>Manage Data Analytics Centrally</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum
            molestiae delectus culpa hic assumenda, voluptate reprehenderit
            dolore autem cum ullam sed odit perspiciatis. Doloribus quos velit,
            eveniet ex deserunt fuga?
          </p>
          <button className='bg-black text-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3'>Get Started</button>
        </div>
      </div>
    </div>
   */
<div>
<div className='hidden md:block'>
<div className='w-full py-16 bg-white px-4'>
      <div className='max-w-[1240px] mx-auto grid lg:grid-cols-3'>
        <div className='lg:col-span-2 my-4'>
        <img className='w-[200px]  my-4' src={Truck1} alt='/' />
        </div>
        <div className='my-4'>
          <div className='flex flex-col sm:flex-row items-center justify-between w-full'>
          {/* <p className='text-[#00df9a] font-bold '>DATA ANALYTICS DASHBOARD</p> */} 
          <h1 className='md:text-5xl sm:text-3xl text-2xl font-bold py-2 font-bold leading-relaxed text-gray-800 whitespace-nowrap'>Efficient logistics solutions for seamless operations.</h1> 
          
          {/*  
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum
            molestiae delectus culpa hic assumenda, voluptate reprehenderit
            dolore autem cum ullam sed odit perspiciatis. Doloribus quos velit,
            eveniet ex deserunt fuga?
          </p>
         <button className='bg-black text-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3'>Get Started</button> */}
          </div>
        </div>
        <div className='flex flex-col sm:flex-row my-4 w-[250px] rounded-3xl'>
        <img className='w-[200px] mx-auto my-4 rounded-3xl' src={Truck2} alt='/' />
        </div>
      </div>
    </div>
    </div>

    
<div class="block md:hidden items-center justify-center p-5 max-w-full bg-white border border-gray-200 rounded-lg shadow dark:border-gray-700">
    <a href="#">
        <img class="rounded-t-lg" src={Air} alt="" />
    </a>
    <div class="p-5">
        <a href="#">
            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-black">Fast, Reliable Air Cargo</h5>
        </a>
        <h6 class="mb-3 font-normal text-gray-700 dark:text-gray-600">Rapid and dependable air transportation services for swift delivery of goods worldwide.</h6>
       
    </div>
</div>

<div class="block md:hidden items-center justify-center p-5 max-w-full bg-white border border-gray-200 rounded-lg shadow dark:border-gray-700">
    <a href="#">
        <img class="rounded-t-lg" src={Truck3} alt="" />
    </a>
    <div class="p-5">
        <a href="#">
            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-black">Efficient Land Transportation</h5>
        </a>
        <h6 class="mb-3 font-normal text-gray-700 dark:text-gray-600">Efficient and secure land transportation solutions for seamless logistics and timely deliveries.</h6>
        
    </div>
</div>

<div class="block md:hidden items-center justify-center p-5 max-w-full bg-white border border-gray-200 rounded-lg shadow dark:border-gray-700">
    <a href="#">
        <img class="rounded-t-lg" src={Staff} alt="" />
    </a>
    <div class="p-5">
        <a href="#">
            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-black">Smooth Ocean Shipping Solutions</h5>
        </a>
        <h6 class="mb-3 font-normal text-gray-700 dark:text-gray-600">Reliable and cost-effective ocean shipping services for efficient global cargo transportation and supply chain management.</h6>
       
    </div>
</div>

<section class="bg-white dark:bg-white block md:hidden lg:hidden">
    <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16">
        <div class="bg-gray-50 dark:bg-gray-100 border border-gray-200 dark:border-gray-200 rounded-lg p-8 md:p-12 mb-8">
            <a href="#" class="bg-blue-100 text-blue-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded-md dark:bg-gray-700 dark:text-blue-400 mb-2">
                <svg class="w-3 h-3 mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path>
                </svg>
                About Us
            </a>
            <h3 class="text-gray-900 dark:text-black text-3xl md:text-5xl font-extrabold mb-2">Efficient Logistics and Seamless Shipping Solutions</h3>
            <h3 class="text-lg font-normal text-gray-500 dark:text-gray-500 mb-6">Delivering seamless logistics and shipping solutions, we ensure efficient transportation, reliable supply chain management, and streamlined global distribution for your business.</h3>
            
        </div>
        </div>
</section>
    </div>
  );
};

export default Analytics;

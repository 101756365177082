import React, { useState } from "react";
import "./App.css";
import Layout from "./components/Layout/Layout";


function App() {
  
  return <Layout />;
}

export default App;

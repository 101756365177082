import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import useGetData from '../custom-hooks/useGetData';
import useAuth from '../custom-hooks/useAuth';
import '../styles/dashboard.css';

const Dashboard = () => {
  const { currentUser } = useAuth();
  const { data: products } = useGetData('products');
  const userProducts = products.filter((product) => product.addedBy === currentUser.uid);

  const {data: assets} = useGetData('assets');
  const userAssets = assets.filter((product) => product.addedBy === currentUser.uid);

  return (
    <>
      <section>
        <Container>
          <Row>
            <Col className="lg-3">
              <div className="products__box">
                <h4>Total Orders</h4>
                <span>{userProducts.length}</span>
              </div>
            </Col>

            <Col className="lg-3">
              <div className="products__box">
                <h4>Total Assets</h4>
                <span>{userAssets.length}</span>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Dashboard;

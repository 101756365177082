import React from 'react';
import Man from '../../assets/assets/man.png';
import AboutMe from '../../Compo/AboutMe';

const Service = () => {
  return (
    <div>
<div className='hidden md:block'>
<div className="flex flex-col items-center bg-white shadow md:flex-row md:max-w-full h-[550px] hover:bg-white dark:bg-white dark:hover:bg-white">
    <img className="object-cover w-full rounded-t-lg h-96 md:h-[550px] md:w-[350px] md:rounded-none md:rounded-l-lg md:mx-[60px] " src={Man} alt="" />
    
    <div class="p-4 bg-white rounded-lg md:p-8 dark:bg-white" id="about" role="tabpanel" aria-labelledby="about-tab">
            <h2 class="mb-3 md:text-4xl text-2xl font-extrabold tracking-tight text-gray-900 dark:text-black">Secure Logistics: Your Assets, Our Priority.</h2>
            <p class="mb-3 text-gray-500 dark:text-gray-400">At our logistics and asset vault, we prioritize your peace of mind. With a steadfast commitment to security, efficiency, and trust, we safeguard and deliver your valued assets with meticulous care. Our streamlined processes and advanced technologies ensure seamless operations, while our dedicated team upholds the highest standards of professionalism. Experience the confidence that comes from partnering with a trusted logistics provider, dedicated to protecting and managing your assets every step of the way.</p>
            <a href="#" class="inline-flex items-center font-medium text-blue-600 hover:text-blue-800 dark:text-blue-500 dark:hover:text-blue-700">
                Learn more
                <svg class="w-6 h-6 ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
            </a>
        </div>
</div>
</div>

    
<AboutMe/>
</div>
  )
}

export default Service
import React, { useState } from 'react';
import { Badge, Row, Col, Card, CardBody, CardFooter, Button } from 'reactstrap';
import './Trackstyles.css';
import useGetData from '../../../custom-hooks/useGetData';
import { db } from '../../../firebase.config';
import { doc, updateDoc, getDoc  } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { Link, useNavigate } from "react-router-dom";
import Footer from '../../AssetValuePortal/components/Footer';
import Typed from 'react-typed';


function Parcel() {
  const [trackingPin, setTrackingPin] = useState('');
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate= useNavigate()

  const [isErasing, setIsErasing] = useState(false);

  const handleBeginTyping = () => {
    setIsErasing(false);
  };

  const handleCompleteTyping = () => {
    setIsErasing(true);
  };
 

  const getProductData = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      const productDoc = doc(db, 'products', trackingPin);
      const productSnapshot = await getDoc(productDoc);
      if (productSnapshot.exists()) {
        setProduct(productSnapshot.data());
        toast.success('The Order Exists!');
        navigate('/parcel/parcel-details', { state: { trackingPin } });
      } else {
        setProduct(null);
        setError('Product not found!');
        toast.error('Order not found!');
      }
    } catch (error) {
      toast.error('Error fetching order data:', error);
      console.error('Error fetching product data:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
<>
  <div>

  <div style={{
  backgroundImage: "url('https://www.content.shi.com/SHIcom/ContentAttachmentImages/SharedResources/Solutions/ITAM/itam-main-020922-saas-cost-optimization-services-banner-thumb.jpg')",
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  height: '400px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  textShadow: '2px 2px #000000',
}}>
   <>
    <Typed
          className='mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl'
            strings={['Eugogate', 'Logistics', 'Eugogate Logistics']}
            typeSpeed={120}
            backSpeed={140}
            loop
            onComplete={handleCompleteTyping}
            onBegin={handleBeginTyping}
          />
          {isErasing ? (
        <span role="img" aria-label="Eraser" className="mb-2 text-white text-6xl">
          ✍️
        </span>
      ) : (
        <span role="img" aria-label="Pen" className="mb-2 text-white text-6xl">
          ✏️
        </span>
      )}
          </>
</div>


{
   loading ? (<Col lg='12' className='text-center'><h2 className='font-bold text-5xl mt-3'>Loading Order, Please Wait... <div role="status">
   <svg aria-hidden="true" class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-purple-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
       <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
       <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
   </svg>
   <span class="sr-only">Loading...</span>
</div> </h2></Col>) : (

<div className="container">
      <h1>Track Order Value</h1>
      <h3 className='font-bold'>Track Order Value</h3>
      <form onSubmit={getProductData}>
        <div className="form-group">
          <label htmlFor="order-pin"></label>
          <input
            type="text"
            className="rounded-lg block w-full p-4 dark:placeholder-gray-400 dark:text-black text-md font-semibold text-center border border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:border-gray-600"
            id="asset-name"
            placeholder="Enter Order tracking number (Example: 1g2agd62ghd82hwstg4)"
            value={trackingPin}
            onChange={(e) => setTrackingPin(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="bg-blue-700  hover:bg-green-800 text-white font-bold rounded-xl w-full">
          Track Order
        </button>
      </form>
    </div>
              )}

<br/><br/>
<Footer/>
    </div>
</>
  );
}

export default Parcel;

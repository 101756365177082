import React, {useState} from 'react';
import './styles.css';

const RequestQuote = () => {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send the email and default message to the desired email address
      await sendEmailToRecipient(email, 'I just want to be notified every day', 'queencynthia32@gmail.com');
      setIsSubmitted(true);
    } catch (err) {
      setError('An error occurred. Please try again later.');
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <div>

    <div class="mainSection hidden md:block lg:block" style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem', padding: '5rem' }}>
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%', maxWidth: 'screen-lg', backgroundColor: 'white', borderRadius: '0.5rem' }}>
        <div class="first-column" style={{ width: '100%', lgWidth: '50%', padding: '2rem' }}>
          <h2 class="header" style={{ background: 'linear-gradient(to right, blue, red, yellow)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', fontSize: '6rem', fontWeight: 'bold', marginBottom: '1rem' }}>Serving Our Clients <br/> For Over 100 Years</h2>
          <h2 class="header1" style={{ background: 'linear-gradient(to right, blue, red, yellow)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', fontSize: '2rem', fontWeight: 'bold', marginBottom: '1rem' }}>Serving Our Clients <br/> For Over 100 Years</h2>
          <ul style={{ marginLeft: '2rem', listStyle: 'disc' }}>
  <li style={{ marginBottom: '2rem' }}>
    <h4 style={{ background: 'linear-gradient(to right, blue, red, yellow)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', marginBottom: '0.5rem', fontSize: '3rem', fontWeight: 'bold', }}>Standard Shipping</h4>
    <p style={{ background: 'linear-gradient(to right, blue, red, yellow)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', fontSize: '2rem' }}>Get your package delivered in a timely and cost-effective manner with our Standard Shipping option.</p>
  </li>
  <li style={{  marginBottom: '2rem' }}>
    <h4 style={{ background: 'linear-gradient(to right, blue, red, yellow)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', marginBottom: '0.5rem' , fontSize: '3rem', fontWeight: 'bold', }}>Express Shipping</h4>
    <p style={{ background: 'linear-gradient(to right, blue, red, yellow)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', fontSize: '2rem' }}>Need your package delivered ASAP? Choose our Express Shipping option for lightning-fast delivery.</p>
  </li>
  <li style={{ marginBottom: '2rem' }}>
    <h4 style={{ background: 'linear-gradient(to right, blue, red, yellow)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', marginBottom: '0.5rem' , fontSize: '3rem', fontWeight: 'bold', }}>Next Day Shipping</h4>
    <p style={{ background: 'linear-gradient(to right, blue, red, yellow)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', fontSize: '2rem' }}>Have an urgent package that needs to be delivered tomorrow? Our Next Day Shipping option has got you covered.</p>
  </li>
</ul>


        </div>
<div class="second-column hidden md:block lg:block" style={{ width: '60%', lgWidth: '50%', height: '580px', padding: '5rem', backgroundImage: 'linear-gradient(to bottom right, blue, red, yellow, blue)', borderRadius: '2rem'  }}>
{isSubmitted ? (
            <div className='lg:col-span-3 my-4'>
              <h1 className='md:text-4xl sm:text-3xl text-white text-2xl font-bold py-2'>
                Thank you for requesting for a Quote!
              </h1>
            </div>
          ) : (
            <>
          <h2 style={{ fontSize: '3rem', fontWeight: 'bold', marginBottom: '1rem', color: 'white' }}>Request a Quote</h2>
          <form style={{ display: 'grid', gridTemplateColumns: '1fr', gap: '1rem' }} onSubmit={handleSubmit}>
            <div>
              <label style={{ display: 'block', color: 'white', fontWeight: 'bold', marginBottom: '0.5rem', fontSize: '2rem' }} htmlFor="name">
                Full Name
              </label>
              <input
                style={{ width: '100%', height: '45px', borderRadius: '1.5rem', padding: '0.5rem', border: '1px solid gray', outline: 'none', boxShadow: '0 0 0 2px transparent', transition: 'box-shadow 0.3s ease-in-out' }}
                id="name"
                type="text"
                placeholder="John Doe"
              />
            </div>
            <div>
              <label style={{ display: 'block', color: 'white', fontWeight: 'bold', marginBottom: '0.5rem', fontSize: '2rem'  }} htmlFor="email">
                Email
              </label>
              <input
                style={{ width: '100%', height: '45px', borderRadius: '1.5rem', padding: '0.5rem', border: '1px solid gray', outline: 'none', boxShadow: '0 0 0 2px transparent', transition: 'box-shadow 0.3s ease-in-out' }}
                id="email"
                value={email}
                onChange={handleEmailChange}
                type="email"
                placeholder="john.doe@example.com"
                required
              />
            </div>
            <div>
              <label style={{ display: 'block', color: 'white', fontWeight: 'bold', marginBottom: '0.5rem', fontSize: '2rem'  }} htmlFor="phone">
                Phone Number
              </label>
              <input
                style={{ width: '100%', height: '45px', borderRadius: '1.5rem', padding: '0.5rem', border: '1px solid gray', outline: 'none', boxShadow: '0 0 0 2px transparent', transition: 'box-shadow 0.3s ease-in-out' }}
                id="phone"
                type="tel"
                placeholder="555-555-5555"
              />
            </div>
            <div>
            <label style={{ display: 'block', color: 'white', fontWeight: 'bold', marginBottom: '0.5rem', fontSize: '2rem'  }} htmlFor="message">
Message
</label>
<textarea
style={{ width: '100%', height: '75px', borderRadius: '1.5rem',  padding: '0.5rem', border: '1px solid gray', outline: 'none', boxShadow: '0 0 0 2px transparent', transition: 'box-shadow 0.3s ease-in-out' }}
id="message"
placeholder="Please provide details about your shipping needs."
/>
</div>
<div>
<button
style={{ backgroundColor: 'black', width: '100%', height: '40px', borderRadius: '1.5rem', color: 'white', padding: '0.5rem', border: 'none', fontWeight: 'bold', cursor: 'pointer', transition: 'background-color 0.3s ease-in-out' }}
type="submit"
>
Submit
</button>
</div>
{error && <p className='text-red-500'>{error}</p>}
</form>
</>
          )}    
</div>
</div>
</div>


</div>
);
};

export default RequestQuote;

// Placeholder function to send email to recipient
const sendEmailToRecipient = async (senderEmail, message, recipientEmail) => {
  // Implement your logic to send the email using a server-side solution or API.
  // This is just a placeholder function.
  console.log(`Sending email to ${recipientEmail} from ${senderEmail} with message: ${message}`);
};
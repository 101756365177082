/* FAQ data */
export const accordions = [
  {
    id: 1,
    question: "How can I track my shipment with Eugogate Logistics?",
    answer:
      "You can easily track your shipment by entering the tracking number provided to you on our website's tracking page. Our system will provide real-time updates on your package's location and delivery status.",
  },
  {
    id: 2,
    question: "What shipping options does Eugogate Logistics offer?",
    answer:
      "Eugogate Logistics offers a range of shipping options, including express, standard, and freight services. We tailor our solutions to meet your specific needs, ensuring reliable and timely delivery of your goods.",
  },
  {
    id: 3,
    question: "How long does it take for my package to be delivered?",
    answer:
      "Delivery times vary depending on the shipping service chosen and the destination. Generally, domestic shipments within the same country are delivered within 2-5 business days, while international shipments may take 5-10 business days or longer.",
  },
  {
    id: 4,
    question: "Can I change the delivery address for my package?",
    answer:
      "Yes, you can request a change of delivery address for your package. Contact our customer support team with your tracking number and new address details, and we will do our best to accommodate your request.",
  },
  {
    id: 5,
    question: "What happens if my package gets lost or damaged during transit?",
    answer:
      "In the rare event of loss or damage, Eugogate Logistics provides insurance coverage for your shipments. Please contact our customer support immediately to initiate a claim process and resolve the issue promptly.",
  },
  {
    id: 6,
    question: "Are there any restrictions on the items I can ship?",
    answer:
      "Certain items are restricted or prohibited from shipping due to safety or legal reasons. Please refer to our shipping guidelines or contact our customer support for a comprehensive list of restricted items to ensure compliance with regulations.",
  },
  {
    id: 7,
    question: "Can I schedule a specific delivery time for my package?",
    answer:
      "While we strive to deliver packages within the estimated timeframe, specific delivery time requests are subject to availability and may require additional charges. Please contact our customer support to inquire about scheduling options.",
  },
  {
    id: 8,
    question: "Doe you provide customs clearance services for international shipments?",
    answer:
      "Yes, we offer customs clearance services for international shipments. Our experienced team will handle all necessary documentation and procedures to ensure smooth customs clearance and compliance with import/export regulations.",
  },
  {
    id: 9,
    question: "How can I contact Eugogate Logistics for customer support?",
    answer:
      "You can reach our customer support team via email at contact@eugogatelogistics.com . Our dedicated representatives are available to assist you with any inquiries or concerns you may have.",
  },
  {
    id: 10,
    question: "What payment methods does Eugogate Logistics accept?",
    answer:
      "Eugogate Logistics accepts various payment methods, including major credit cards, bank transfers, and online payment platforms. Please refer to our website or contact our billing department for specific payment options and instructions.",
  },
];
import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import useGetData from '../custom-hooks/useGetData';
import { db } from '../firebase.config';
import { doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import emailjs from 'emailjs-com';
// import "../styles/allorders.css";
import Helmet from "../components/Helmet/Helmet";
import useAuth from '../custom-hooks/useAuth';

const AllAssets = () => {

const { data: productsData, loading } = useGetData('assets');
const [editingProduct, setEditingProduct] = useState(null);
const { currentUser } = useAuth();
const [showDeleteModal, setShowDeleteModal] = useState(false);
const [deletingProductId, setDeletingProductId] = useState(null);

  const toggleDeleteModal = () => {
    setShowDeleteModal((prevState) => !prevState);
  };

// ----------------------------------------------------------------------------------
const sendEmail = async (beneficiaryEmail, id, beneficiary, monthlyCharges, yourClient, yourClientEmail, item, myassetType, assetWebsite) => {
    try {
      const templateParams = {
        user_email: yourClientEmail,
        product_id: id,
        sender: beneficiary,
        monthly_charges: monthlyCharges,
        yourClient,
        senderEmail: beneficiaryEmail,
        item,
        product_type: myassetType,
        yourWeb: assetWebsite,
        // Add other variables related to the asset details that you want to include in the email template
      };

      await emailjs.send(
        process.env.REACT_APP_EMAIL_SERVICE_ID,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAIL_USER_ID
      );

      // Show toast success message when email is sent successfully
      toast.success('Email sent successfully!');
    } catch (error) {
      console.error('Failed to send email:', error);
    }
  };
  // --------------------------------------------------------------

  const deleteProduct = async (id) => {
    setDeletingProductId(id);
    toggleDeleteModal();
  };

  const confirmDeleteProduct = async (id) => {
    // Perform the actual deletion logic here, for example:
    try {
      // Delete the product from the database using the provided id
      await deleteDoc(doc(db, 'assets', id));

      // Show a success message
      toast.success('Asset deleted successfully!');
    } catch (error) {
      console.error('Error deleting asset:', error);
      toast.error('An error occurred while deleting the asset.');
    } finally {
      // Reset the state and close the modal
      setDeletingProductId(null);
      toggleDeleteModal();
    }
  };

const saveEditedProduct = async (id, newData) => {
await updateDoc(doc(db, 'assets', id), newData);
toast.success('Product updated!');
setEditingProduct(null);
};

const notifyClient = async (product) => {
    const { beneficiaryEmail, id, beneficiary, monthlyCharges, yourClient, yourClientEmail, item, myassetType, assetWebsite } = product;
    if (yourClientEmail) {
      await sendEmail(beneficiaryEmail, id, beneficiary, monthlyCharges, yourClient, yourClientEmail, item, myassetType, assetWebsite);
    } else {
      toast.error('No client email found!');
    }
  };


const renderEditForm = (product) => (
    <tr key={product.id}>
    <td>
    <img src={product.imgUrl} alt="" className='w-20 h-20 rounded-full' />
    </td>
    <td>
    <input
    type="text"
    defaultValue={product.displayName}
    disabled
    onChange={(e) =>
    setEditingProduct({ ...product, displayName: e.target.value })
    }
    />
    </td>
    <td>
    <input
    type="text"
    defaultValue={product.addedBy}
    disabled
    onChange={(e) =>
    setEditingProduct({ ...product, addedBy: e.target.value })
    }
    />
    </td>
    <td>
    <input
    type="text"
    defaultValue={product.id}
    disabled
    onChange={(e) =>
    setEditingProduct({ ...product, id: e.target.value })
    }
    />
    </td>
    <td>
    <input
    type="text"
    defaultValue={product.item}
    onChange={(e) =>
    setEditingProduct({ ...product, item: e.target.value })
    }
    />
    </td>
    <td>
    <input
    type="text"
    defaultValue={product.depositor}
    onChange={(e) =>
    setEditingProduct({ ...product, depositor: e.target.value })
    }
    />
    </td>
    <td>
    <input
    type="text"
    defaultValue={product.depositorEmail}
    onChange={(e) =>
    setEditingProduct({ ...product, depositorEmail: e.target.value })
    }
    />
    </td>
    <td>
    <input
    type="text"
    defaultValue={product.monthlyCharges}
    onChange={(e) =>
    setEditingProduct({ ...product, monthlyCharges: e.target.value })
    }
    />
    </td>
    <td>
    <input
    type="text"
    defaultValue={product.category}
    onChange={(e) =>
    setEditingProduct({ ...product, category: e.target.value })
    }
    />
    </td>
    <td>
    <input
    type="text"
    defaultValue={product.location}
    onChange={(e) =>
    setEditingProduct({ ...product, location: e.target.value })
    }
    />
    </td>
    <td>
    <input
    type="text"
    defaultValue={product.depositPurpose}
    onChange={(e) =>
    setEditingProduct({ ...product, depositPurpose: e.target.value })
    }
    />
    </td>
    <td>
    <input
    type="text"
    defaultValue={product.weight}
    onChange={(e) =>
    setEditingProduct({ ...product, weight: e.target.value })
    }
    />
    </td>
    <td>
    <input
    type="text"
    defaultValue={product.beneficiary}
    onChange={(e) =>
    setEditingProduct({ ...product, beneficiary: e.target.value })
    }
    />
    </td>
    <td>
    <input
    type="text"
    defaultValue={product.beneficiaryEmail}
    onChange={(e) =>
    setEditingProduct({ ...product, beneficiaryEmail: e.target.value })
    }
    />
    </td>
    <td>
    <input
    type="text"
    defaultValue={product.yourClient}
    onChange={(e) =>
    setEditingProduct({ ...product, yourClient: e.target.value })
    }
    />
    </td>
    <td>
    <input
    type="text"
    defaultValue={product.yourClientEmail}
    onChange={(e) =>
    setEditingProduct({ ...product, yourClientEmail: e.target.value })
    }
    />
    </td>
    <td>
    <input
    type="text"
    defaultValue={product.vaultType}
    onChange={(e) =>
    setEditingProduct({ ...product, vaultType: e.target.value })
    }
    />
    </td>
    <td>
    <input
    type="text"
    defaultValue={product.packageValue}
    onChange={(e) =>
    setEditingProduct({ ...product, packageValue: e.target.value })
    }
    />
    </td>
    <td>
    <input
    type="date"
    defaultValue={product.depositDate}
    onChange={(e) =>
    setEditingProduct({ ...product, depositDate: e.target.value })
    }
    />
    </td>
    <td>
    <input
    type="text"
    defaultValue={product.demurrageStatus}
    onChange={(e) =>
    setEditingProduct({ ...product, demurrageStatus: e.target.value })
    }
    />
    </td>
    <td>
    <input
    type="text"
    defaultValue={product.comments}
    onChange={(e) =>
    setEditingProduct({ ...product, comments: e.target.value })
    }
    />
    </td>
    <td>
    <input
    type="number"
    defaultValue={product.invoiceNumber}
    disabled
    onChange={(e) =>
    setEditingProduct({ ...product, invoiceNumber: e.target.value })
    }
    />
    </td>
    <td>
    <input
    type="number"
    defaultValue={product.serialNumber}
    disabled
    onChange={(e) =>
    setEditingProduct({ ...product, serialNumber: e.target.value })
    }
    />
    </td>
    <td>
    <input
    type="number"
    defaultValue={product.transactionCode}
    disabled
    onChange={(e) =>
    setEditingProduct({ ...product, transactionCode: e.target.value })
    }
    />
    </td>
    <td>
    <input
    type="number"
    defaultValue={product.vaultAccessCode}
    disabled
    onChange={(e) =>
    setEditingProduct({ ...product, vaultAccessCode: e.target.value })
    }
    />
    </td>
    
    <td>
    <button
    className="bg-green-600 rounded-full  text-white font-semibold"
    onClick={() => saveEditedProduct(product.id, editingProduct)}
    >
    Save
    </button>
    <button
    className="bg-black rounded-full  text-white font-semibold"
    onClick={() => setEditingProduct(null)}
    >
    Cancel
    </button>
    </td>
    </tr>
    );
    const renderProductRow = (product) => (
        <tr key={product.id}>
        <td class="px-6 py-3 border-b border-black">
        <img src={product.imgUrl} alt="" className='w-20 h-20 rounded-full' />
        </td>

                {product && product.displayName ?
        <td class="px-6 py-3 text-black font-bold border-b border-black">
        {product.displayName}
        </td> :
        <td class="px-6 py-3 text-black font-bold border-b border-black">
        Super Admin
    </td>
        }
        {product && product.addedBy ?
        <td class="px-6 py-3 text-black font-bold border-b border-black">
        {product.addedBy}
        </td> :
        <td class="px-6 py-3 text-black font-bold border-b border-black">
        {currentUser.uid}
    </td>
        }
        <td class="px-6 py-3 text-black font-bold border-b border-black">{product.id}</td>
        <td class="px-6 py-3 text-black font-bold border-b border-black">{product.item}</td>
        <td class="px-6 py-3 text-black font-bold border-b border-black">{product.depositor}</td>
        <td class="px-6 py-3 text-black font-bold border-b border-black">{product.depositorEmail}</td>
        <td class="px-6 py-3 text-black font-bold border-b border-black">$ {product.monthlyCharges}</td>
        <td class="px-6 py-3 text-black font-bold border-b border-black">{product.category}</td>
        <td class="px-6 py-3 text-black font-bold border-b border-black">{product.location}</td>
        <td class="px-6 py-3 text-black font-bold border-b border-black">{product.depositPurpose}</td>
        <td class="px-6 py-3 text-black font-bold border-b border-black">{product.weight}</td>
        <td class="px-6 py-3 text-black font-bold border-b border-black">{product.beneficiary}</td>
        <td class="px-6 py-3 text-black font-bold border-b border-black">{product.beneficiaryEmail}</td>
        <td class="px-6 py-3 text-black font-bold border-b border-black">{product.yourClient}</td>
        <td class="px-6 py-3 text-black font-bold border-b border-black">{product.yourClientEmail}</td>
        <td class="px-6 py-3 text-black font-bold border-b border-black">{product.vaultType}</td>
        <td class="px-6 py-3 text-black font-bold border-b border-black">{product.packageValue}</td>
        <td class="px-6 py-3 text-black font-bold border-b border-black">{product.depositDate}</td>
        <td class="px-6 py-3 text-black font-bold border-b border-black">{product.demurrageStatus}</td>
        <td class="px-6 py-3 text-black font-bold border-b border-black">{product.comments}</td>
        <td class="px-6 py-3 text-black font-bold border-b border-black">{product.invoiceNumber}</td>
        <td class="px-6 py-3 text-black font-bold border-b border-black">{product.serialNumber}</td>
        <td class="px-6 py-3 text-black font-bold border-b border-black">{product.transactionCode}</td>
        <td class="px-6 py-3 text-black font-bold border-b border-black">{product.vaultAccessCode}</td>
        <td class="px-6 py-3 border-b border-black">
        <button className="bg-red-600 rounded-full text-white font-semibold" onClick={() => deleteProduct(product.id)}>
            Delete
</button>
        <button
        className="bg-blue-600 rounded-full  text-white font-semibold"
        onClick={() => setEditingProduct(product)}
        >
        Edit
        </button>
        <button
      className="bg-indigo-600 rounded-full whitespace-nowrap text-white font-semibold"
      onClick={() => notifyClient(product)}
    >
      Notify Client
    </button>
        </td>
        </tr>
        );

         // Function to filter products based on search query ------------------------------
 const [searchQuery, setSearchQuery] = useState('');
 const filterProducts = (assets, query) => {
   if (!query) return assets;

   const searchFields = [
     'displayName',
     'addedBy',
     'id',
     'item',
     'depositorEmail',
     'monthlyCharges',
     'category',
     'depositPurpose',
     'weight',
     'beneficiary',
     'vaultType',
     'packageValue',
     'depositDate',
     'transactionCode',
     'demurrageStatus',
     'pickupDate',
     'comments',
     'invoiceNumber',
     'serialNumber',
     'vaultAccessCode',
     'depositor',
     'beneficiaryEmail',
     'yourClient',
     'yourClientEmail',
   ];

   query = query.toLowerCase();

   return assets.filter((product) => {
     for (const field of searchFields) {
       if (product[field]?.toLowerCase().includes(query)) {
         return true;
       }
     }
     return false;
   });
 };

return (
<Helmet title="All Assets">
<section className=''>
<Container>
<h2 className='font-bold text-5xl text-center'>All Assets</h2>
<div class="relative mt-2 mb-5">
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
            </svg>
        </div>
        <input type="text"  
        class="block w-full p-4 pl-10 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
        placeholder="Search Order's country, name, tracking. destination..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
         />
        
    </div>
<Row style={{
              backgroundColor: '#f8f9fa',
              border: '0',
              justifyContent: 'center',
              padding: '0.5rem',
              width: '100%'
            }}>
<Col>
<table className="table-auto text-[1.2rem] w-full block overflow-x-scroll overflow-y-scroll ml-0">
<thead className='bg-[#f8f9fa] font-bold'>
<tr>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Depositor Image</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>User Name</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>User UID</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Asset Tracking No.</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Items</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Depositor</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Depositor Email</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Monthly Charges</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Shipment Status</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Asset Location</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Deposit Purpose</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Weight</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Beneficiary Name</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Beneficiary Email</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Client's Name</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Client's Email</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Vault Type</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Package Value</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Date Of Deposit</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Demurrage Status</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Comments</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>invoice Number</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>serial Number</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>transaction Code</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>vault Access Code</th>
<th className='p-[3rem] text-2xl bg-blue-200 text-center whitespace-nowrap text-gray'>Action</th>
</tr>
</thead>
<tbody>
{loading ? (
<h4 className="py-5 text-center fw-bold">Loading...</h4>
) : (
<>
                      {editingProduct && renderEditForm(editingProduct)}
                      {filterProducts(productsData, searchQuery).map((product) =>
                        editingProduct && editingProduct.id === product.id ? (
                          renderEditForm(editingProduct)
                        ) : (
                          renderProductRow(product)
                        )
                      )}
                    </>
)}
</tbody>
</table> 
</Col>
</Row>
</Container>
{/* Modal for confirming product deletion */}
{showDeleteModal && (
          <div id="popup-modal" tabIndex="-1" className="fixed top-0 left-0 right-0 z-50 flex items-center justify-center p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-full bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-sm">
            <div className="relative w-full max-w-md max-h-full">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <button type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={toggleDeleteModal}>
                  <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
                <div className="p-6 text-center">
                  <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                  </svg>
                  <h2 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete this Asset?</h2>
                  <button type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2" onClick={() => confirmDeleteProduct(deletingProductId)}>
                    Yes, I'm sure
                  </button>
                  <button type="button" className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600" onClick={toggleDeleteModal}>
                    No, cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
</section>
</Helmet>
);
};

export default AllAssets;





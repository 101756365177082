import React, { useState, useEffect } from 'react';
import { Badge, Row, Col, Card, CardBody, CardFooter, Button,  ListGroup, ListGroupItem  } from 'reactstrap';
import './styles.css';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase.config';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Footer from '../components/Footer'

function AssetDetails() {
  const location = useLocation();
  const navigate = useNavigate();

  const [asset, setAsset] = useState(null);

  useEffect(() => {
    if (!location.state || !location.state.trackingPin) {
      handleAccessDenied();
      return;
    }

    const fetchAssetData = async () => {
      try {
        const assetDoc = doc(db, 'assets', location.state.trackingPin);
        const assetSnapshot = await getDoc(assetDoc);
        if (assetSnapshot.exists()) {
          setAsset(assetSnapshot.data());
          toast.success('Asset Found Successfully !');
        }
      } catch (error) {
        toast.error('Error fetching asset data:', error);
        console.error('Error fetching asset data:', error);
      }
    };
    fetchAssetData();
  }, [location.state, navigate]);

  const handleAccessDenied = () => {
    toast.error('Access Denied. You are not authorized to access this page.');
    navigate('/check-asset');
  };
  
  const formattedCurrency = asset && parseFloat(asset.packageValue).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const monthlyCurrency = asset && parseFloat(asset.monthlyCharges).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  if (!asset) {
    return <div>Loading asset data...</div>;
  }

  return (
  <>
  <div>
  <div style={{
  backgroundImage: "url('https://evolvous.com/wp-content/uploads/2022/01/Digital-transformation-in-the-construction-industry.jpg')",
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  height: '400px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  textShadow: '2px 2px #000000',
}}>
  <h1 style={{
    fontSize: '3rem',
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase',
    letterSpacing: '2px',
    padding: '1rem',
    backgroundColor: 'rgba(0,0,0,0.5)',
    borderRadius: '10px',
  }} class="header">
    View your asset's performance
  </h1>
  
  <h3 style={{
    fontSize: '1rem',
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase',
    letterSpacing: '2px',
    padding: '1rem',
    backgroundColor: 'rgba(0,0,0,0.5)',
    borderRadius: '10px',
  }} >
    View your asset's performance
  </h3>
</div>
</div>
  
  <h1 style={{
  textAlign: 'center',
  fontFamily: 'Arial',
  fontWeight: 'bold',
  fontSize: '2rem',
  color: 'black',
  borderRadius: '10px',
  padding: '1rem',
}}>Vault Access Details</h1>

  <h3 style={{
  textAlign: 'center',
  fontFamily: 'Arial',
  fontWeight: 'bold',
  fontSize: '2rem',
  color: 'black',
  borderRadius: '10px',
  padding: '1rem',
}}>Vault Access Details</h3>

  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' , width: '100%', }} className='detail'>
    <Row style={{
              backgroundColor: '#f8f9fa',
              border: '0',
              padding: '0.5rem',
              display: 'flex',
              justifyContent: 'space-around',
              width: '65%'
            }}>
      <Col xl={6} className='mb-4' style={{ padding: '1rem', backgroundColor: '#f8f9fa', }}>
        <Card style={{ padding: '1rem', backgroundColor: 'white', borderRadius: '1rem', width: '450px', }}>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
               {asset && <img
                  src={asset.imgUrl}
                  alt=''
                  style={{ width: '45px', height: '45px', borderRadius: '50%' }}
                /> }
                <div style={{ width: '250px', marginLeft: '10px' }}>
                  <p style={{ fontWeight: 'bold', marginBottom: '5px', color: 'black' }}>Depositor</p>
                  {asset &&  <p style={{ color: 'gray', marginBottom: '0' }}>{asset.depositor}</p> }
                </div>
              </div>
              <Badge pill color='success' style={{
  backgroundColor: 'lightgreen',
  borderRadius: '5px',
  padding: '0.5rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
}}>
  Verified
</Badge>

            </div>
          </CardBody>
          <CardFooter
            style={{
              backgroundColor: 'white',
              border: '0',
              padding: '0.5rem',
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <Button color='link' style={{ color: 'black',  textDecoration: 'none' }}>
              Invoice No.:
            </Button>
            <Button color='link' style={{ color: '#007bff', textDecoration: 'none' }}>
            {asset && <p>{asset.invoiceNumber}</p>}
            </Button>
          </CardFooter>
          
          <CardFooter
            style={{
              backgroundColor: 'white',
              border: '0',
              padding: '0.5rem',
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <Button color='link' style={{ color: 'black', textDecoration: 'none' }}>
              Serial No.:
            </Button>
            <Button color='link' style={{ color: '#007bff', textDecoration: 'none' }}>
            {asset && <p>{asset.serialNumber}</p>}
            </Button>
          </CardFooter>
        </Card>
      </Col>
      
      <Col xl={6} className='mb-4' style={{ padding: '1rem', backgroundColor: '#f8f9fa' }}>
        <Card style={{ padding: '1rem', backgroundColor: 'white', borderRadius: '1rem', width: '450px', }}>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src='https://thumbs.dreamstime.com/b/money-transaction-vector-logo-icon-design-buying-cash-symbol-illustration-illustrations-152825421.jpg'
                  alt=''
                  style={{ width: '45px', height: '45px', borderRadius: '50%' }}
                />
                <div style={{ width: '250px', marginLeft: '10px' }}>
                  <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '5px' }}>Transaction Code</p>
                  {asset && <p style={{ color: 'gray', marginBottom: '0' }}>{asset.transactionCode}</p> }
                </div>
              </div>
              <Badge pill color='success' style={{
  backgroundColor: 'lightgreen',
  borderRadius: '5px',
  padding: '0.5rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
}}>
  Active
</Badge>
            </div>
          </CardBody>
          <CardFooter
            style={{
              backgroundColor: 'white',
              border: '0',
              padding: '0.5rem',
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <Button color='link' style={{ color: 'black', textDecoration: 'none' }}>
              Charges Per Month:
            </Button>
            <Button color='link' style={{ color: '#007bff', textDecoration: 'none' }}>
            {asset && <p>{asset.currency}{" "} {monthlyCurrency}</p> }
            </Button>
          </CardFooter>
          
          <CardFooter
            style={{
              backgroundColor: 'white',
              border: '0',
              padding: '0.5rem',
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <Button color='link' style={{ color: 'black', textDecoration: 'none' }}>
              Deposit Purpose:
            </Button>
            <Button color='link' style={{ color: '#007bff', textDecoration: 'none' }}>
            {asset && <p>{asset.depositPurpose}</p> }
            </Button>
          </CardFooter>
        </Card>
      </Col>
    </Row>
    </div>
    
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%',  }} className='detail'>
    <Row style={{
              backgroundColor: '#f8f9fa',
              border: '0',
              padding: '0.5rem',
              display: 'flex',
              justifyContent: 'space-around',
              width: '65%', 
            }} >
      <Col xl={6} className='mb-4' style={{ padding: '1rem', backgroundColor: '#f8f9fa', }}>
        <Card style={{ padding: '1rem', backgroundColor: 'white', borderRadius: '1rem', width: '450px', }}>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src='https://gopostr.s3.amazonaws.com/favicon_url/vRZw4SjV4Tkm3p2UdRxJ5EZpwWLVQ2YnLjuxAn3u.png'
                  alt=''
                  style={{ width: '45px', height: '45px', borderRadius: '50%' }}
                />
                <div style={{ width: '250px', marginLeft: '10px' }}>
                  <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '5px' }}>Vault Access Code</p>
                  {asset && <p style={{ color: 'gray', marginBottom: '0' }}>{asset.vaultAccessCode}</p> }
                </div>
              </div>
              <Badge pill color='success' style={{
  backgroundColor: 'lightgreen',
  borderRadius: '5px',
  padding: '0.5rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
}}>
  Verified
</Badge>

            </div>
          </CardBody>
        </Card>
      </Col>
      
      <Col xl={6} className='mb-4' style={{ padding: '1rem', backgroundColor: '#f8f9fa' }}>
        <Card style={{ padding: '1rem', backgroundColor: 'white', borderRadius: '1rem', width: '450px',  }}>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src='https://99designs-blog.imgix.net/blog/wp-content/uploads/2016/09/att-logo-600x600.jpg?auto=format&q=60&fit=max&w=930'
                  alt=''
                  style={{ width: '45px', height: '45px', borderRadius: '50%' }}
                />
                <div style={{ width: '250px', marginLeft: '10px' }}>
                  <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '5px' }}>Vault Type</p>
                  {asset &&  <p style={{ color: 'gray', marginBottom: '0' }}>{asset.vaultType}</p> }
                </div>
              </div>
              <Badge pill color='success' style={{
  backgroundColor: 'lightgreen',
  borderRadius: '5px',
  padding: '0.5rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
}}>
  Active
</Badge>
            </div>
          </CardBody>
          
        </Card>
      </Col>
    </Row>
    </div>
    
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', }} className='detail'>
    <Row style={{
              backgroundColor: '#f8f9fa',
              border: '0',
              padding: '0.5rem',
              display: 'flex',
              justifyContent: 'space-around',
              width: '65%',
            }} >
      <Col xl={6} className='mb-4' style={{ padding: '1rem', backgroundColor: '#f8f9fa', }}>
        <Card style={{ padding: '1rem', backgroundColor: 'white', borderRadius: '1rem',width: '450px', }}>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src='https://thumbs.dreamstime.com/b/weight-measure-scale-flat-design-icon-kitchen-tools-equipment-sign-symbol-167664120.jpg'
                  alt=''
                  style={{ width: '45px', height: '45px', borderRadius: '50%' }}
                />
                <div style={{ width: '250px', marginLeft: '10px' }}>
                  <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '5px' }}>Weight</p>
                  {asset && <p style={{ color: 'gray', marginBottom: '0' }}>{asset.weight}</p> }
                </div>
              </div>
              <Badge pill color='success' style={{
  backgroundColor: 'lightgreen',
  borderRadius: '5px',
  padding: '0.5rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
}}>
  Verified
</Badge>

            </div>
          </CardBody>
        </Card>
      </Col>
      
      <Col xl={6} className='mb-4' style={{ padding: '1rem', backgroundColor: '#f8f9fa' }}>
        <Card style={{ padding: '1rem', backgroundColor: 'white', borderRadius: '1rem', width: '450px',}}>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src='https://static.vecteezy.com/system/resources/previews/008/302/513/non_2x/eps10-blue-user-icon-or-logo-in-simple-flat-trendy-modern-style-isolated-on-white-background-free-vector.jpg'
                  alt=''
                  style={{ width: '45px', height: '45px', borderRadius: '50%' }}
                />
                <div style={{ width: '250px', marginLeft: '10px' }}>
                  <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '5px' }}>Beneficiary</p>
                  {asset &&  <p style={{ color: 'gray', marginBottom: '0' }}>{asset.beneficiary}</p> }
                </div>
              </div>
              <Badge pill color='success' style={{
  backgroundColor: 'lightgreen',
  borderRadius: '5px',
  padding: '0.5rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
}}>
  Active
</Badge>
            </div>
          </CardBody>
          
        </Card>
      </Col>
    </Row>
    </div>
    
    
    
    
    
    
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', }} className='detail'>
    <Row style={{
              backgroundColor: '#f8f9fa',
              border: '0',
              padding: '0.5rem',
              display: 'flex',
              justifyContent: 'space-around',
              width: '65%',
            }} >
      <Col xl={6} className='mb-4' style={{ padding: '1rem', backgroundColor: '#f8f9fa', }}>
        <Card style={{ padding: '1rem', backgroundColor: 'white', borderRadius: '1rem', width: '450px', }}>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src='https://previews.123rf.com/images/stmool/stmool1908/stmool190800079/128323132-full-blue-basket-of-goods-grocery-store-fast-delivery-service-special-offer-supermarket-fresh.jpg'
                  alt=''
                  style={{ width: '45px', height: '45px', borderRadius: '50%' }}
                />
                <div style={{ width: '250px', marginLeft: '10px' }}>
                  <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '5px' }}>Item(s)</p>
                  {asset && <p style={{ color: 'gray', marginBottom: '0' }}>{asset.item}</p> }
                </div>
              </div>
              <Badge pill color='success' style={{
  backgroundColor: 'lightgreen',
  borderRadius: '5px',
  padding: '0.5rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
}}>
  Verified
</Badge>

            </div>
          </CardBody>
        </Card>
      </Col>
      
      <Col xl={6} className='mb-4' style={{ padding: '1rem', backgroundColor: '#f8f9fa' }}>
        <Card style={{ padding: '1rem', backgroundColor: 'white', borderRadius: '1rem', width: '450px', }}>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src='https://www.kindpng.com/picc/m/538-5387323_flat-icon-money-blue-hd-png-download.png'
                  alt=''
                  style={{ width: '45px', height: '45px', borderRadius: '50%' }}
                />
                <div style={{ width: '250px', marginLeft: '10px' }}>
                  <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '5px' }}>Package Value</p>
                  {asset && <p style={{ color: 'gray', marginBottom: '0' }}>{asset.currency}{" "} {formattedCurrency}</p> }
                </div>
              </div>
              <Badge pill color='success' style={{
  backgroundColor: 'lightgreen',
  borderRadius: '5px',
  padding: '0.5rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
}}>
  Active
</Badge>
            </div>
          </CardBody>
          
        </Card>
      </Col>
    </Row>
    </div>
    
    
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', }} className='detail'>
    <Row style={{
              backgroundColor: '#f8f9fa',
              border: '0',
              padding: '0.5rem',
              display: 'flex',
              justifyContent: 'space-around',
              width: '65%',
            }} >
      <Col xl={6} className='mb-4' style={{ padding: '1rem', backgroundColor: '#f8f9fa', }}>
        <Card style={{ padding: '1rem', backgroundColor: 'white', borderRadius: '1rem', width: '450px', }}>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src='https://t4.ftcdn.net/jpg/02/28/13/53/360_F_228135369_EWQJsS9TEskImi51e5AxpEsYsonOIBMM.jpg'
                  alt=''
                  style={{ width: '45px', height: '45px', borderRadius: '50%' }}
                />
                <div style={{ width: '250px', marginLeft: '10px' }}>
                  <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '5px' }}>Shipment Status</p>
                  {asset &&  <p style={{ color: 'gray', marginBottom: '0' }}>{asset.category}</p> }
                </div>
              </div>
              <Badge pill color='success' style={{
  backgroundColor: 'lightgreen',
  borderRadius: '5px',
  padding: '0.5rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
}}>
  Verified
</Badge>

            </div>
          </CardBody>
        </Card>
      </Col>
      
      <Col xl={6} className='mb-4' style={{ padding: '1rem', backgroundColor: '#f8f9fa' }}>
        <Card style={{ padding: '1rem', backgroundColor: 'white', borderRadius: '1rem', width: '450px', }}>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src='https://w7.pngwing.com/pngs/33/81/png-transparent-computer-icons-calendar-others-miscellaneous-blue-text-thumbnail.png'
                  alt=''
                  style={{ width: '45px', height: '45px', borderRadius: '50%' }}
                />
                <div style={{ width: '250px', marginLeft: '10px' }}>
                  <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '5px' }}>Date Of Deposit</p>
                  {asset &&  <p style={{ color: 'gray', marginBottom: '0' }}>{asset.depositDate}</p> }
                </div>
              </div>
              <Badge pill color='success' style={{
  backgroundColor: 'lightgreen',
  borderRadius: '5px',
  padding: '0.5rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
}}>
  Active
</Badge>
            </div>
          </CardBody>
          
        </Card>
      </Col>
    </Row>
    </div>
    
    
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', }} className='detail'>
    <Row style={{
              backgroundColor: '#f8f9fa',
              border: '0',
              padding: '0.5rem',
              display: 'flex',
              justifyContent: 'space-around',
              width: '65%',
            }} >
      <Col xl={6} className='mb-4' style={{ padding: '1rem', backgroundColor: '#f8f9fa', }}>
        <Card style={{ padding: '1rem', backgroundColor: 'white', borderRadius: '1rem', width: '450px', }}>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src='https://arviem.com/wordpress/wp-content/uploads/Arviem_predict.png'
                  alt=''
                  style={{ width: '45px', height: '45px', borderRadius: '50%' }}
                />
                <div style={{ width: '250px', marginLeft: '10px' }}>
                  <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '5px' }}>Demurrage Status</p>
                  {asset && <p style={{ color: 'gray', marginBottom: '0' }}>{asset.demurrageStatus}</p> }
                </div>
              </div>
              <Badge pill color='success' style={{
  backgroundColor: 'lightgreen',
  borderRadius: '5px',
  padding: '0.5rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
}}>
  Verified
</Badge>

            </div>
          </CardBody>
        </Card>
      </Col>
      
      <Col xl={6} className='mb-4' style={{ padding: '1rem', backgroundColor: '#f8f9fa' }}>
        <Card style={{ padding: '1rem', backgroundColor: 'white', borderRadius: '1rem', width: '450px', }}>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src='https://cdn-icons-png.flaticon.com/512/2899/2899298.png'
                  alt=''
                  style={{ width: '45px', height: '45px', borderRadius: '50%' }}
                />
                <div style={{ width: '250px', marginLeft: '10px' }}>
                  <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '5px' }}>Comments</p>
                  {asset && <p style={{ color: 'gray', marginBottom: '0' }}>{asset.comments}</p> }
                </div>
              </div>
              <Badge pill color='success' style={{
  backgroundColor: 'lightgreen',
  borderRadius: '5px',
  padding: '0.5rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
}}>
  Active
</Badge>
            </div>
          </CardBody>
          
        </Card>
      </Col>
    </Row>
    </div>







    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', }} className='detail'>
    <Row style={{
              backgroundColor: '#f8f9fa',
              border: '0',
              padding: '0.5rem',
              display: 'flex',
              justifyContent: 'space-around',
              width: '65%',
            }} >
      <Col xl={6} className='mb-4' style={{ padding: '1rem', backgroundColor: '#f8f9fa', }}>
        <Card style={{ padding: '1rem', backgroundColor: 'white', borderRadius: '1rem', width: '450px', }}>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src='https://img.freepik.com/premium-vector/blue-globe-icon-with-long-shadow-vector-illustration-symbol-earth-planet-icon-world-symbol-flat-style_118339-1385.jpg?w=360'
                  alt=''
                  style={{ width: '45px', height: '45px', borderRadius: '50%' }}
                />
                <div style={{ width: '250px', marginLeft: '10px' }}>
                  <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '5px' }}>Location Of Asset</p>
                  {asset && <p style={{ color: 'gray', marginBottom: '0' }}>{asset.location}</p> }
                </div>
              </div>
              <Badge pill color='success' style={{
  backgroundColor: 'lightgreen',
  borderRadius: '5px',
  padding: '0.5rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
}}>
  Verified
</Badge>

            </div>
          </CardBody>
        </Card>
      </Col>
      {/*
      <Col xl={6} className='mb-4' style={{ padding: '1rem', backgroundColor: '#f8f9fa' }}>
        <Card style={{ padding: '1rem', backgroundColor: 'white', borderRadius: '1rem', width: '450px', }}>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src='https://www.citypng.com/public/uploads/preview/hd-profile-user-round-blue-icon-symbol-transparent-png-11639594354dzabzsbpuv.png'
                  alt=''
                  style={{ width: '45px', height: '45px', borderRadius: '50%' }}
                />
                <div style={{ width: '250px', marginLeft: '10px' }}>
                  <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '5px' }}>Alternative Beneficiary</p>
                  {asset && asset.yourClient ? (
                    <>
                  {asset && <p style={{ color: 'gray', marginBottom: '0' }}>{asset.yourClient}</p> }
                  {asset && <p style={{ color: 'gray', marginBottom: '0' }}>{asset.yourClientEmail}</p> }
                  </>
                  ) 
                  :
                  <h1 className='font-normal text-2xl text-center text-gray-500'>Not Specified </h1> }
                </div>
              </div>
              <Badge pill color='success' style={{
  backgroundColor: 'lightgreen',
  borderRadius: '5px',
  padding: '0.5rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
}}>
  Active
</Badge>
            </div>
          </CardBody>
          
        </Card>
      </Col>
*/}
    </Row>
    </div>
    
    <div style={{ width: '100%', borderLeft: "2px solid black", padding: '5px'}} className="mini">
      <h3 style={{ backgroundColor: '#f8f9fa', padding: '0.5rem 1rem', borderTop: '1px solid #dee2e6', borderBottom: '1px solid #dee2e6', borderTop: '1px solid black', fontSize: '1.7rem' ,fontWeight: 'bold'}}>Depositor Details</h3>
      <ListGroup style={{ marginBottom: '1rem' }}>
        <ListGroupItem className='py-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #dee2e6' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
          {asset &&  <img
              src={asset.imgUrl}
              alt=''
              style={{ width: '29px', height: '29px', borderRadius: '50%', marginRight: '1rem', marginLeft: '2px' }}
            /> }
            <div>
              <h3 style={{ fontSize: '1.7rem', marginBottom: '1px' }}>Depositor Name</h3>
              {asset &&   <h3 style={{ fontSize: '1.4rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}>{asset.depositor}</h3> }
            </div>
          </div>
        </ListGroupItem>
        
        <ListGroupItem className='py-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #dee2e6' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
           
            <div>
              <h3 style={{ fontSize: '1.7rem', paddingLeft: '10px', marginBottom: '1px' }}>Invoice No.</h3>
              {asset &&  <h3 style={{ fontSize: '1.4rem', paddingLeft: '10px', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue' }}>{asset.invoiceNumber}</h3> }
            </div>
          </div>
        </ListGroupItem>
        
        <ListGroupItem className='py-5' style={{padding: '5px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid black' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
              <h3 style={{ fontSize: '1.7rem', paddingLeft: '10px', marginBottom: '1px' }}>Serial Number</h3>
              {asset &&  <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue' }}>{asset.serialNumber}</h3> }
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>
    
    
    
    <div style={{ width: '100%', borderLeft: "2px solid black", padding: '5px'}} className="mini">
      <h3 style={{ backgroundColor: '#f8f9fa', padding: '0.5rem 1rem', borderTop: '1px solid #dee2e6', borderBottom: '1px solid #dee2e6', borderTop: '1px solid black', fontSize: '1.7rem' ,fontWeight: 'bold'}}>Transaction Details</h3>
      <ListGroup style={{ marginBottom: '1rem' }}>
        <ListGroupItem className='py-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #dee2e6' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
              <h3 style={{ fontSize: '1.7rem', marginLeft: '1rem', marginBottom: '1px' }}>Transaction Code</h3>
              {asset && <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}>{asset.transactionCode}</h3> }
            </div>
          </div>
        </ListGroupItem>
        
        <ListGroupItem className='py-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #dee2e6' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
            <h3 style={{ fontSize: '1.7rem', marginLeft: '1rem', marginBottom: '1px' }}>Charges Per Month</h3>
              {asset &&  <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}>{asset.currency}{" "} {monthlyCurrency}</h3> }
            </div>
          </div>
        </ListGroupItem>
        
        <ListGroupItem className='py-5' style={{padding: '5px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid black' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
            <h3 style={{ fontSize: '1.7rem', marginLeft: '1rem', marginBottom: '1px' }}>Deposit Purpose</h3>
              {asset && <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}>{asset.depositPurpose}</h3> }
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>
    
    
    
    <div style={{ width: '100%',  borderLeft: "2px solid black", padding: '5px'}} className="mini">
      <h3 style={{ backgroundColor: '#f8f9fa', padding: '0.5rem 1rem', borderTop: '1px solid #dee2e6', borderBottom: '1px solid #dee2e6', borderTop: '1px solid black', fontSize: '1.7rem' ,fontWeight: 'bold'}}>Vault Access Code</h3>
      <ListGroup style={{ marginBottom: '1rem' }}>
        <ListGroupItem className='py-5' style={{padding: '5px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid black' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
            {asset &&  <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}>{asset.vaultAccessCode}</h3> }
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>
    
    
    
    <div style={{ width: '100%', borderLeft: "2px solid black", padding: '5px'}} className="mini">
    <h3 style={{ backgroundColor: '#f8f9fa', padding: '0.5rem 1rem', borderTop: '1px solid #dee2e6', borderBottom: '1px solid #dee2e6', borderTop: '1px solid black', fontSize: '1.7rem' ,fontWeight: 'bold'}}>Vault Type</h3>
      <ListGroup style={{ marginBottom: '1rem' }}>
        <ListGroupItem className='py-5' style={{padding: '5px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid black' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
            {asset && <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}>{asset.vaultType}</h3> }
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>
    
    
    
    <div style={{ width: '100%', borderLeft: "2px solid black", padding: '5px'}} className="mini">
    <h3 style={{ backgroundColor: '#f8f9fa', padding: '0.5rem 1rem', borderTop: '1px solid #dee2e6', borderBottom: '1px solid #dee2e6', borderTop: '1px solid black', fontSize: '1.7rem' ,fontWeight: 'bold'}}>Weight</h3>
      <ListGroup style={{ marginBottom: '1rem' }}>
        <ListGroupItem className='py-5' style={{padding: '5px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid black' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
            {asset && <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue'}}>{asset.weight}</h3> }
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>
    
    
    
    <div style={{ width: '100%', borderLeft: "2px solid black", padding: '5px'}} className="mini">
    <h3 style={{ backgroundColor: '#f8f9fa', padding: '0.5rem 1rem', borderTop: '1px solid #dee2e6', borderTop: '1px solid black', fontSize: '1.7rem' ,fontWeight: 'bold'}}> Beneficiary</h3>
      <ListGroup style={{ marginBottom: '1rem' }}>
        <ListGroupItem className='py-5' style={{padding: '5px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid black'}}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
            {asset && <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}>{asset.beneficiary}</h3> }
            {asset && <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}>{asset.beneficiaryEmail}</h3> }
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>
    {/*
    <div style={{ width: '100%', borderLeft: "2px solid black", padding: '5px'}} className="mini">
    <h3 style={{ backgroundColor: '#f8f9fa', padding: '0.5rem 1rem', borderTop: '1px solid #dee2e6', borderTop: '1px solid black', fontSize: '1.7rem' ,fontWeight: 'bold'}}>Alternative Beneficiary</h3>
      <ListGroup style={{ marginBottom: '1rem' }}>
        <ListGroupItem className='py-5' style={{padding: '5px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid black'}}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
              {asset && asset.yourClient ? (
                <>
                {asset && <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}>{asset.yourClient}</h3> }
            {asset && <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}>{asset.yourClientEmail}</h3> }
              </> ) :
              <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}>Not Specified</h3>
            }
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>
          */}
    <div style={{ width: '100%', borderLeft: "2px solid black", padding: '5px'}} className="mini">
    <h3 style={{ backgroundColor: '#f8f9fa', padding: '0.5rem 1rem', borderTop: '1px solid #dee2e6', borderTop: '1px solid black', fontSize: '1.7rem' ,fontWeight: 'bold'}}>Location Of Asset</h3>
      <ListGroup style={{ marginBottom: '1rem' }}>
        <ListGroupItem className='py-5' style={{padding: '5px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid black'}}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
            {asset && <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}>{asset.location}</h3> }
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>
    
    
    <div style={{ width: '100%', borderLeft: "2px solid black", padding: '5px'}} className="mini">
    <h3 style={{ backgroundColor: '#f8f9fa', padding: '0.5rem 1rem', borderTop: '1px solid black', borderBottom: '1px solid #dee2e6', fontSize: '1.7rem' ,fontWeight: 'bold'}}>Item(s)</h3>
      <ListGroup style={{ marginBottom: '1rem' }}>
        <ListGroupItem className='py-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid black' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
           
            <div>
            {asset &&  <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}>{asset.item}</h3> }
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>
    
    
    
    <div style={{ width: '100%', borderLeft: "2px solid black", padding: '5px'}} className="mini">
    <h3 style={{ backgroundColor: '#f8f9fa', padding: '0.5rem 1rem', borderTop: '1px solid black', borderBottom: '1px solid #dee2e6', fontSize: '1.7rem' ,fontWeight: 'bold'}}>Package Value</h3>
      <ListGroup style={{ marginBottom: '1rem' }}>
        <ListGroupItem className='py-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid black' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
            {asset &&  <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}>{asset.currency}{" "} {formattedCurrency}</h3> }
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>
    
    
    
    <div style={{ width: '100%', borderLeft: "2px solid black", padding: '5px'}}  className="mini">
    <h3 style={{ backgroundColor: '#f8f9fa', padding: '0.5rem 1rem', borderTop: '1px solid black', borderBottom: '1px solid #dee2e6', fontSize: '1.7rem' ,fontWeight: 'bold'}}> Shipment Status</h3>
      <ListGroup style={{ marginBottom: '1rem' }}>
        <ListGroupItem className='py-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid black' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
            {asset &&  <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}>{asset.category}</h3> }
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>
    
    
    
    <div style={{ width: '100%', borderLeft: "2px solid black", padding: '5px'}} className="mini">
    <h3 style={{ backgroundColor: '#f8f9fa', padding: '0.5rem 1rem', borderTop: '1px solid black', borderBottom: '1px solid #dee2e6', fontSize: '1.7rem' ,fontWeight: 'bold'}}> Date Of Deposit</h3>
      <ListGroup style={{ marginBottom: '1rem' }}>
        <ListGroupItem className='py-5' style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid black' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
            {asset && <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}> {asset.depositDate}</h3> }
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>
    
    
    
    <div style={{ width: '100%', borderLeft: "2px solid black", padding: '5px'}} className="mini">
    <h3 style={{ backgroundColor: '#f8f9fa', padding: '0.5rem 1rem', borderTop: '1px solid black', borderBottom: '1px solid #dee2e6', fontSize: '1.7rem' ,fontWeight: 'bold'}}> Demurrage Status</h3>
      <ListGroup style={{ marginBottom: '1rem' }}>
        <ListGroupItem className='py-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid black' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
            {asset && <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}> {asset.demurrageStatus}</h3> }
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>
    
    
    
    <div style={{ width: '100%', borderLeft: "2px solid black", padding: '5px'}} className="mini">
    <h3 style={{ backgroundColor: '#f8f9fa', padding: '0.5rem 1rem', borderTop: '1px solid black', borderBottom: '1px solid #dee2e6', fontSize: '1.7rem' ,fontWeight: 'bold'}}> Comments</h3>
      <ListGroup style={{ marginBottom: '1rem' }}>
        <ListGroupItem className='py-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid black' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
            {asset && <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}> {asset.comments}</h3> }
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>
    <br/><br/>

    <Footer/>
    </>
  );
}

export default AssetDetails;

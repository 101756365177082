import React, {useState} from 'react';
import { NavLink } from 'react-router-dom';
import Typed from 'react-typed';


const Hero = () => {
  const [isErasing, setIsErasing] = useState(false);

  const handleBeginTyping = () => {
    setIsErasing(false);
  };

  const handleCompleteTyping = () => {
    setIsErasing(true);
  };
 
  return (
    
<section class="bg-center bg-no-repeat bg-[url('https://flowbite.s3.amazonaws.com/docs/jumbotron/conference.jpg')] bg-gray-700 bg-blend-multiply">
    <div class="px-4 mx-auto max-w-screen-xl text-center py-24 lg:py-56">
      <>
    <Typed
          className='mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl'
            strings={['Eugogate', 'Logistics', 'Eugogate Logistics']}
            typeSpeed={120}
            backSpeed={140}
            loop
            onComplete={handleCompleteTyping}
            onBegin={handleBeginTyping}
          />
          {isErasing ? (
        <span role="img" aria-label="Eraser" className="mb-2 text-white text-6xl">
          ✍️
        </span>
      ) : (
        <span role="img" aria-label="Pen" className="mb-2 text-white text-6xl">
          ✏️
        </span>
      )}
          </>

        <h1 className="mb-8 text-5xl font-normal text-gray-300 lg:text-4xl sm:px-16 lg:px-48"><span className='bg-gradient-to-r text-transparent from-[#6a00ff] to-[#00d8ff] bg-clip-text  px-2'>Efficient Logistics and Seamless Shipping:</span> Streamlining Operations for Global Supply Chain Excellence.</h1>
        <h3 class="mb-8 text-2xl font-normal text-gray-300  sm:px-16 lg:px-48"><span className='bg-gradient-to-r text-transparent from-[#6a00ff] to-[#00d8ff] bg-clip-text  px-2'>Efficient Logistics and Seamless Shipping:</span> Streamlining Operations for Global Supply Chain Excellence.</h3>
        <div class="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
        <button className='m-2 bg-gradient-to-r from-[#6a00ff] to-[#00d8ff] py-3 px-7 rounded-3xl text-white'>
        <NavLink to='/parcel'> <span className='text-white font-normal'>  Track Consignment </span> </NavLink>
        </button>
        </div>
    </div>
</section>

  );
};

export default Hero;
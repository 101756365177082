import React from 'react';
import './Logo.css';
import UspLogo from "./eugogate.png"

function Logo() {
  return (
    <div className="logo">
      <img src={UspLogo} alt="Eugogate" />
      <h1></h1>
    </div>
  );
}

export default Logo;

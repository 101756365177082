import React, {useState} from 'react'
import Typed from 'react-typed';
import Footer from '../components/AssetValuePortal/components/Footer'


const bgImageStyle = {
  backgroundImage: 'url(https://files.secure.website/wscfus/10717741/30650113/mt-slider-trucking02-w1800-o.jpg)',
};

const About = () => {
  const [isErasing, setIsErasing] = useState(false);

  const handleBeginTyping = () => {
    setIsErasing(false);
  };

  const handleCompleteTyping = () => {
    setIsErasing(true);
  };

  return (
    <div>
      <div className='text-white bg-black' style={bgImageStyle}>
      <div className='max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
        <h2 className='text-[#00df9a] md:text-3xl text-xl block font-bold p-2 mt-5'>
        Secure. Efficient. Trusted. Logistics.
        </h2>
        <h1 className='md:text-7xl sm:text-3xl text-4xl block font-bold md:py-6'>
          About Us
        </h1>
        <div className='flex justify-center items-center'>
          <p className='md:text-5xl sm:text-4xl text-xl font-bold py-4'>
            
          </p>
          <>
          <Typed
          className='md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2'
            strings={['Eugogate', 'Logistics', 'Eugogate Logistics', 'Eugogate', 'Asset Avault', 'Eugogate Asset Vault']}
            typeSpeed={120}
            backSpeed={140}
            loop
            onComplete={handleCompleteTyping}
            onBegin={handleBeginTyping}
          />
          {isErasing ? (
        <span role="img" aria-label="Eraser" className="mb-2 text-white text-6xl">
          ✍️
        </span>
      ) : (
        <span role="img" aria-label="Pen" className="mb-2 text-white text-6xl">
          ✏️
        </span>
      )}
          </>
        </div>
        <h2 className='md:text-2xl  text-xl font-bold text-gray-500'>Building Trust through Secure Logistics: Safeguarding and Delivering Your Valued Assets..</h2>
        
      </div>
    </div>
      


    <section className="bg-gray-100 py-16">
      <div className="container mx-auto">
        <div className="max-w-200 mx-auto">
          <h2 className="text-4xl font-bold mb-4">About Us</h2>
          <h2 className="text-3xl text-gray-700 leading-relaxed justify mb-2">
            At Eugogate Logistics, we are dedicated to providing exceptional logistics and shipping solutions
            to our clients worldwide. With years of experience in the industry, we have built a reputation
            for reliability, efficiency, and customer satisfaction. Our team of experts works tirelessly to
            ensure seamless transportation, effective asset tracking, and optimized supply chain management.
          </h2>
          <h2 className="text-3xl text-gray-700 leading-relaxed justify mb-2">
          Our mission is simple: to empower your shipping journey. We understand the critical role that logistics plays in the success 
          of your business, and we are dedicated to delivering comprehensive solutions that streamline your operations and drive growth.
          </h2>
          <h2 className="text-3xl text-gray-700 leading-relaxed justify mb-2">
          As a customer-centric company, we prioritize building strong relationships with our clients.
           We take the time to understand your specific requirements, challenges, and goals,
           allowing us to develop customized strategies that optimize your supply chain and enhance efficiency.
          </h2>
          <h2 className="text-3xl text-gray-700 leading-relaxed justify mb-2">
          Our team of logistics experts is at the heart of our success. With their in-depth industry 
          knowledge and unwavering commitment to excellence, they ensure that your shipments are handled with the utmost 
          care and professionalism. From initial planning to final delivery,
           we leverage our expertise to overcome any logistical obstacles, 
          keeping your goods safe and secure every step of the way.
          </h2>
          <h2 className="text-3xl text-gray-700 leading-relaxed justify mb-2">
          Innovation and technology are integral to our operations. We invest in cutting-edge tracking systems
           and advanced logistics software to provide real-time visibility into your shipments. With our
            state-of-the-art infrastructure and digital solutions, you can monitor the progress of 
            your cargo, access valuable data and analytics,
           and make informed decisions to optimize your supply chain.
          </h2>
          <h2 className="text-3xl text-gray-700 leading-relaxed justify mb-2">
          Sustainability is a core value at Eugogate Logistics. We recognize our responsibility to minimize our environmental 
          impact and contribute to a greener future. Through eco-friendly practices, energy-efficient operations,
           and a commitment to reduce carbon emissions, we strive to ensure that our services align with your sustainability goals.
          </h2>
          <h2 className="text-3xl text-gray-700 leading-relaxed justify mb-2">
          Located at the heart of the bustling logistics hub, our headquarters provide easy access to major transportation routes, 
          enabling us to efficiently connect your goods to global markets. Whether it's air freight,
           ocean freight, or land transportation, we have the expertise 
          and resources to deliver reliable and cost-effective solutions that meet your deadlines and exceed your expectations.
          </h2>
          <h2 className="text-3xl text-gray-700 leading-relaxed justify mb-2">
          We take pride in our reputation for delivering exceptional service and achieving customer satisfaction. 
          Our dedication to quality assurance is evident in our rigorous quality control processes, 
          adherence to industry regulations, and continuous improvement initiatives
          . Your trust is our most valuable asset, and we strive to earn it through consistent performance and exceeding your expectations.
          </h2>
          <h2 className="text-3xl text-gray-700 leading-relaxed justify mb-2">
          Thank you for considering Eugogate Logistics as your logistics partner. We invite you to explore 
          our website and discover how our comprehensive services and customer-centric approach can add value to your business.
           Contact us today to discuss your unique shipping requirements, and let us empower your shipping journey!
          </h2>
          </div>
          </div>
          </section>

      <Footer/>
    </div>
  )
}

export default About
import React, {useState} from 'react'
import Typed from 'react-typed';
import Footer from '../components/AssetValuePortal/components/Footer'


const bgImageStyle = {
  backgroundImage: 'url(https://ziplinelogistics.com/wp-content/uploads/2019/03/Bundling-Logistics-Services-Turnkey-3PL-e1617151758346.jpg)',
};

const Contact = () => {
  const [isErasing, setIsErasing] = useState(false);

  const handleBeginTyping = () => {
    setIsErasing(false);
  };

  const handleCompleteTyping = () => {
    setIsErasing(true);
  };


  return (
    <div>
      <div className='text-white bg-black' style={bgImageStyle}>
      <div className='max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
        <h2 className='text-[#00df9a] md:text-3xl text-xl block font-bold p-2 mt-5'>
        Secure. Efficient. Trusted. Logistics.
        </h2>
        <h1 className='md:text-7xl sm:text-3xl text-4xl block font-bold md:py-6'>
          Our Contact
        </h1>
        <div className='flex justify-center items-center'>
          <p className='md:text-5xl sm:text-4xl text-xl font-bold py-4'>
            
          </p>
          <>
    <Typed
          className='mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl'
            strings={['Eugogate', 'Logistics', 'Eugogate Logistics']}
            typeSpeed={120}
            backSpeed={140}
            loop
            onComplete={handleCompleteTyping}
            onBegin={handleBeginTyping}
          />
          {isErasing ? (
        <span role="img" aria-label="Eraser" className="mb-2 text-white text-6xl">
          ✍️
        </span>
      ) : (
        <span role="img" aria-label="Pen" className="mb-2 text-white text-6xl">
          ✏️
        </span>
      )}
          </>
        </div>
        <h2 className='md:text-2xl  text-xl font-bold text-gray-500'>Building Trust through Secure Logistics: Safeguarding and Delivering Your Valued Assets..</h2>
        
      </div>
    </div>



    <section className="bg-gray-100 py-16">
      <div className="container mx-auto">
        <div className="max-w-200 mx-auto">
          <h2 className="text-4xl font-bold mb-4">Contact Us</h2>
          <h2 className="text-3xl text-gray-700 leading-relaxed justify mb-2">
          We value your feedback, inquiries, and the opportunity to assist you. Our dedicated customer support team is here to answer any questions you may have and provide personalized assistance.
          </h2>
          <h2 className="text-3xl text-gray-700 leading-relaxed justify mb-2">
          For general inquiries or more information about our services, please reach out to us via email. Our team will respond promptly, providing you with the information you need to make informed decisions about your logistics requirements.
          </h2>
          <h2 className="text-3xl text-gray-700 leading-relaxed justify mb-2">
          If you prefer to speak with us directly, our phone lines are open during business hours. Our friendly and knowledgeable staff will be happy to address your queries, discuss specific shipping needs, or provide guidance on the most suitable solutions for your business.
          </h2>
          <h2 className="text-3xl text-gray-700 leading-relaxed justify mb-2">
          At Eugogate Logistics, we understand the importance of effective communication, and we are committed to ensuring a seamless and convenient experience for our clients. Whether you have questions about tracking your shipment, require assistance with customs documentation, or need support with any aspect of our services, our team is here to assist you.
          </h2>
          <h2 className="text-3xl text-gray-700 leading-relaxed justify mb-2">
          We value your time and strive to provide timely and accurate responses to all inquiries. Your satisfaction is our priority, and we will go above and beyond to exceed your expectations. Trust Eugogate Logistics for reliable, efficient, and personalized shipping solutions.
          </h2>
          <h2 className="text-3xl text-gray-700 leading-relaxed justify mb-2">
          Contact us today to discuss your logistics needs and experience the exceptional service that sets Eugogate Logistics apart.
          </h2>
          
          
          <h2 className="text-3xl text-gray-600 leading-relaxed mt-6">
            Located at:
            <br />
            111 Buckingham Palace Rd,
            <br />
            Westminster, London, SW1W 0SR
          </h2>
          <p className="text-3xl text-gray-600 leading-relaxed mt-6">
            Contact us:
            <br />
            Phone: +447466089626
            <br />
            Email: contact@eugogatelogistics.com
          </p>
        </div>
      </div>
    </section>


      <Footer/>
    </div>
  )
}

export default Contact
import React from 'react';
import useAuth from '../custom-hooks/useAuth';
import { Navigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

const AdminRoute = () => {

  const { currentUser } = useAuth();

  if (!currentUser) {
    return <Navigate to='/login-admin' state={{ error: 'You must be logged in to access this page' }} />;
  }

  if (currentUser.uid !== process.env.REACT_APP_USER_UID) {
    return <Navigate to='/dashboard' state={{ error: 'You are not authorized to access this page' }} />;
  }

  return <Outlet />;
};

export default AdminRoute;

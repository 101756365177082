import React, { useState, useEffect } from 'react';
import { Badge, Row, Col, Card, CardBody, CardFooter, Button,  ListGroup, ListGroupItem  } from 'reactstrap';
import './styles.css';
import { doc, getDoc, collection, query, where, orderBy, onSnapshot  } from 'firebase/firestore';
import { db } from '../../../firebase.config';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Typed from 'react-typed';
import Footer from '../../AssetValuePortal/components/Footer';

function ParcelDetails() {
  const location = useLocation();
  const navigate = useNavigate();

  const [product, setProduct] = useState(null);
  const [orderHistory, setOrderHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  const [isErasing, setIsErasing] = useState(false);

  const handleBeginTyping = () => {
    setIsErasing(false);
  };

  const handleCompleteTyping = () => {
    setIsErasing(true);
  };

  useEffect(() => {
    if (!location.state || !location.state.trackingPin) {
      handleAccessDenied();
      return;
    }

    const fetchProductData = async () => {
      try {
        const productDoc = doc(db, 'products', location.state.trackingPin);
        const productSnapshot = await getDoc(productDoc);
        if (productSnapshot.exists()) {
          setProduct(productSnapshot.data());
          toast.success('product Found Successfully !');
        }
      } catch (error) {
        toast.error('Error fetching product data:', error);
        console.error('Error fetching product data:', error);
      }
    };
    fetchProductData();
  }, [location.state, navigate]);





  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(db, 'orderHistory'), location.state.trackingPin),
      (snapshot) => {
        const data = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        setOrderHistory(data);
        setLoading(false);
      }
    );
    return () => unsubscribe();
  }, [location.state, navigate]);

  const renderOrderHistoryRow = (order) => (
    <table
      key={order.id}
      style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px', overflowX: 'auto' }}
      className='items-center justify-center'
    >
      <thead>
        <tr>
          <th style={{ textAlign: 'left', padding: '10px', background: '#90EE90' }} className='whitespace-nowrap border border-b-white'>
            Date
          </th>
          <td className='px-2 py-4 border-b border-l border-r border-green-300'>{order.product.deliveryDate}</td>
          </tr>
          <tr>
          <th style={{ textAlign: 'left', padding: '10px', background: '#90EE90' }} className='whitespace-nowrap border border-b-white'>
            Location
          </th>
          <td className='px-2 py-4 border-b border-l border-r border-green-300'>{order.product.currentLoc}</td>
          </tr>
          <tr>
          <th style={{ textAlign: 'left', padding: '10px', background: '#90EE90' }} className='whitespace-nowrap border border-b-white'>
            Status
          </th>
          <td className='px-2 py-4 border-b border-l border-r border-green-300'>{order.product.category}</td>
          </tr>
          <tr>
          <th style={{ textAlign: 'left', padding: '10px', background: '#90EE90' }} className='whitespace-nowrap border border-b-white'>
            Updated By
          </th>
          <td className='px-2 py-4 border-b border-l border-r border-green-300'>{order.product.carrier}</td>
          </tr>
          <tr>
          <th style={{ textAlign: 'left', padding: '10px', background: '#90EE90' }} className='whitespace-nowrap border border-b-white'>
            Remarks
          </th>
          <td className='px-2 py-4 border-b border-l border-r border-green-300'>{order.product.comments}</td>
        </tr>
      </thead>
      <tbody>
        <tr>
        </tr>
      </tbody>
    </table>
  );

  const renderOrderHistoryPcRow = (order) => (
    <tr key={order.id}>
      <td className='px-2 py-4 border-b border-l border-green-300'>{order.product.deliveryDate}</td>
      <td className='px-2 py-4 border-b border-l border-green-300'>{order.product.currentLoc}</td>
      <td className='px-2 py-4 border-b border-l border-green-300'>{order.product.category}</td>
      <td className='px-2 py-4 border-b border-l border-green-300'>{order.product.carrier}</td>
      <td className='px-2 py-4 border-b border-l border-r border-green-300'>{order.product.comments}</td>
    </tr>
  );

  // Filter order histories based on the trackingPin
  const filteredOrderHistory = orderHistory.filter((order) => order.product.id === location.state.trackingPin);



  const handleAccessDenied = () => {
    toast.error('Access Denied. You are not authorized to access this page.');
    navigate('/parcel');
  };

  if (!product) {
    return <div>Loading product data...</div>;
  }
  

  return (
  <>
  <div>
  <div style={{
  backgroundImage: "url('https://evolvous.com/wp-content/uploads/2022/01/Digital-transformation-in-the-construction-industry.jpg')",
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  height: '400px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  textShadow: '2px 2px #000000',
}}>
   <>
    <Typed
          className='mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl'
            strings={['Eugogate', 'Logistics', 'Eugogate Logistics']}
            typeSpeed={120}
            backSpeed={140}
            loop
            onComplete={handleCompleteTyping}
            onBegin={handleBeginTyping}
          />
          {isErasing ? (
        <span role="img" aria-label="Eraser" className="mb-2 text-white text-6xl">
          ✍️
        </span>
      ) : (
        <span role="img" aria-label="Pen" className="mb-2 text-white text-6xl">
          ✏️
        </span>
      )}
          </>
          {/*
          <Typed
          className='mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl'
            strings={['Unlocking the Power of Secure, Efficient, and Trusted Logistics Solutions.', 'Streamlined Logistics Solutions: Securing and Delivering Your Assets with Confidence.', 'Elevating Logistics: Safeguarding Your Assets with Seamless Efficiency and Trust.']}
            typeSpeed={120}
            backSpeed={140}
            loop
          />
          
  <h1 style={{
    fontSize: '3rem',
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase',
    letterSpacing: '2px',
    padding: '1rem',
    backgroundColor: 'rgba(0,0,0,0.5)',
    borderRadius: '10px',
  }} class="header">
    View your Order's performance
  </h1>

  <h3 style={{
    fontSize: '1rem',
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase',
    letterSpacing: '2px',
    padding: '1rem',
    backgroundColor: 'rgba(0,0,0,0.5)',
    borderRadius: '10px',
  }} >
    View Your Consignment
  </h3>
  */}

</div>
</div>
  
  <h1 style={{
  textAlign: 'center',
  fontFamily: 'Arial',
  fontWeight: 'bold',
  fontSize: '2rem',
  color: 'black',
  borderRadius: '10px',
  padding: '1rem',
}}>Your Consignment Details</h1>

  <h3 style={{
  textAlign: 'center',
  fontFamily: 'Arial',
  fontWeight: 'bold',
  fontSize: '3rem',
  color: 'black',
  borderRadius: '10px',
  padding: '1rem',
}}>Consignment Details</h3>

  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' , width: '100%', }} className='detail'>
    
    <Row style={{
              backgroundColor: '#f8f9fa',
              border: '0',
              padding: '0.5rem',
              display: 'flex',
              justifyContent: 'space-around',
              width: '90%'
            }}>
      <Col xl={6} className='mb-4' style={{ padding: '1rem', backgroundColor: '#f8f9fa', }}>
        <Card style={{ padding: '1rem', backgroundColor: 'white', borderRadius: '1rem', width: '650px', }}>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
               
                <div style={{ width: '550px', marginLeft: '10px' }}>
                  <p style={{ fontWeight: 'bold', marginBottom: '5px', color: 'black' }}>Shipper Information</p><hr/>
                 {product && <p style={{ color: 'gray', marginBottom: '0' }}>{product.shipperName}</p> }
                 {product && <p style={{ color: 'gray', marginBottom: '0' }}>{product.shipperAddress}</p> }
                 {product && <p style={{ color: 'gray', marginBottom: '0' }}>{product.shipperNumber}</p> } 
                 {product && <p style={{ color: 'gray', marginBottom: '0' }}>{product.shipperEmail}</p> }
                </div>
              </div>
              <Badge pill color='success' style={{
  backgroundColor: 'lightgreen',
  borderRadius: '5px',
  padding: '0.5rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
}}>
  Verified
</Badge>

            </div>
          </CardBody>
          
        </Card>
      </Col>
      
      <Col xl={6} className='mb-4' style={{ padding: '1rem', backgroundColor: '#f8f9fa' }}>
        <Card style={{ padding: '1rem', backgroundColor: 'white', borderRadius: '1rem', width: '650px', }}>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                
                <div style={{ width: '550px', marginLeft: '10px' }}>
                  <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '5px' }}>Receiver Information</p><hr/>
                  {product && <p style={{ color: 'gray', marginBottom: '0' }}>{product.recipient}</p> }
                  {product && <p style={{ color: 'gray', marginBottom: '0' }}>{product.recipientAddress}</p> }
                  {product && <p style={{ color: 'gray', marginBottom: '0' }}>{product.recipientNum}</p> } 
                  {product && <p style={{ color: 'gray', marginBottom: '0' }}>{product.recipientEmail}</p> }
                </div>
              </div>
              <Badge pill color='success' style={{
  backgroundColor: 'lightgreen',
  borderRadius: '5px',
  padding: '0.5rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
}}>
  Active
</Badge>
            </div>
          </CardBody>
          
        </Card>
      </Col>
    </Row>
    </div>


   
    <p style={{
  textAlign: 'center',
  fontFamily: 'Arial',
  fontSize: '2rem',
  color: 'white',
  padding: '1rem',
  }} className='bg-green-400'>Shipment Status: {product.category}</p>

  



    
    <h2 style={{
  textAlign: 'center',
  fontFamily: 'Arial',
  fontWeight: 'bold',
  fontSize: '2rem',
  color: 'black',
  borderRadius: '10px',
  padding: '1rem',
  }} className='hidden md:block lg:block'>Shipment Information</h2>

 
    
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%',  }} className='detail'>
    <Row style={{
              backgroundColor: '#f8f9fa',
              border: '0',
              padding: '0.5rem',
              display: 'flex',
              justifyContent: 'space-around',
              width: '65%', 
            }} >
      <Col xl={6} className='mb-4' style={{ padding: '1rem', backgroundColor: '#f8f9fa', }}>
        <Card style={{ padding: '1rem', backgroundColor: 'white', borderRadius: '1rem', width: '450px', }}>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                
                <div style={{ width: '340px', marginLeft: '10px' }}>
                  <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '5px' }}>Origin</p><hr/>
                  {product && <p style={{ color: 'gray', marginBottom: '0' }}>{product.origin}</p> }
                </div>
              </div>
              <Badge pill color='success' style={{
  backgroundColor: 'lightgreen',
  borderRadius: '5px',
  padding: '0.5rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
}}>
  Verified
</Badge>

            </div>
          </CardBody>
        </Card>
      </Col>
      
      <Col xl={6} className='mb-4' style={{ padding: '1rem', backgroundColor: '#f8f9fa' }}>
        <Card style={{ padding: '1rem', backgroundColor: 'white', borderRadius: '1rem', width: '450px',  }}>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                
                <div style={{ width: '340px', marginLeft: '10px' }}>
                  <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '5px' }}>Destination</p><hr/>
                  {product && <p style={{ color: 'gray', marginBottom: '0' }}>{product.destination}</p> }
                </div>
              </div>
              <Badge pill color='success' style={{
  backgroundColor: 'lightgreen',
  borderRadius: '5px',
  padding: '0.5rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
}}>
  Active
</Badge>
            </div>
          </CardBody>
          
        </Card>
      </Col>
    </Row>
    </div>

    <p style={{
  textAlign: 'center',
  fontFamily: 'Arial',
  fontSize: '2rem',
  color: 'white',
  padding: '1rem',
  background: 'gray',
  }}>Current Location: {product.currentLoc}</p>
    
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', }} className='detail'>
    <Row style={{
              backgroundColor: '#f8f9fa',
              border: '0',
              padding: '0.5rem',
              display: 'flex',
              justifyContent: 'space-around',
              width: '65%',
            }} >
      <Col xl={6} className='mb-4' style={{ padding: '1rem', backgroundColor: '#f8f9fa', }}>
        <Card style={{ padding: '1rem', backgroundColor: 'white', borderRadius: '1rem',width: '450px', }}>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                
                <div style={{ width: '340px', marginLeft: '10px' }}>
                  <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '5px' }}>Weight</p><hr/>
                  {product && <p style={{ color: 'gray', marginBottom: '0' }}>{product.weight}</p> }
                </div>
              </div>
              <Badge pill color='success' style={{
  backgroundColor: 'lightgreen',
  borderRadius: '5px',
  padding: '0.5rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
}}>
  Verified
</Badge>

            </div>
          </CardBody>
        </Card>
      </Col>
      
      <Col xl={6} className='mb-4' style={{ padding: '1rem', backgroundColor: '#f8f9fa' }}>
        <Card style={{ padding: '1rem', backgroundColor: 'white', borderRadius: '1rem', width: '450px',}}>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                
                <div style={{ width: '340px', marginLeft: '10px' }}>
                  <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '5px' }}>Product</p><hr/>
                  {product && <p style={{ color: 'gray', marginBottom: '0' }}>{product.product}</p> }
                </div>
              </div>
              <Badge pill color='success' style={{
  backgroundColor: 'lightgreen',
  borderRadius: '5px',
  padding: '0.5rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
}}>
  Active
</Badge>
            </div>
          </CardBody>
          
        </Card>
      </Col>
    </Row>
    </div>
    
    
    
    
    
    
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', }} className='detail'>
    <Row style={{
              backgroundColor: '#f8f9fa',
              border: '0',
              padding: '0.5rem',
              display: 'flex',
              justifyContent: 'space-around',
              width: '65%',
            }} >
      <Col xl={6} className='mb-4' style={{ padding: '1rem', backgroundColor: '#f8f9fa', }}>
        <Card style={{ padding: '1rem', backgroundColor: 'white', borderRadius: '1rem', width: '450px', }}>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
               
                <div style={{ width: '340px', marginLeft: '10px' }}>
                  <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '5px' }}>Total Freight</p><hr/>
                  {product && <p style={{ color: 'gray', marginBottom: '0' }}>{product.freight}</p> }
                </div>
              </div>
              <Badge pill color='success' style={{
  backgroundColor: 'lightgreen',
  borderRadius: '5px',
  padding: '0.5rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
}}>
  Verified
</Badge>

            </div>
          </CardBody>
        </Card>
      </Col>
      
      <Col xl={6} className='mb-4' style={{ padding: '1rem', backgroundColor: '#f8f9fa' }}>
        <Card style={{ padding: '1rem', backgroundColor: 'white', borderRadius: '1rem', width: '450px', }}>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                
                <div style={{ width: '340px', marginLeft: '10px' }}>
                  <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '5px' }}>Comments</p><hr/>
                  {product && <p style={{ color: 'gray', marginBottom: '0' }}>{product.comments}</p> }
                </div>
              </div>
              <Badge pill color='success' style={{
  backgroundColor: 'lightgreen',
  borderRadius: '5px',
  padding: '0.5rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
}}>
  Active
</Badge>
            </div>
          </CardBody>
          
        </Card>
      </Col>
    </Row>
    </div>
    
    
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', }} className='detail'>
    <Row style={{
              backgroundColor: '#f8f9fa',
              border: '0',
              padding: '0.5rem',
              display: 'flex',
              justifyContent: 'space-around',
              width: '65%',
            }} >
      <Col xl={6} className='mb-4' style={{ padding: '1rem', backgroundColor: '#f8f9fa', }}>
        <Card style={{ padding: '1rem', backgroundColor: 'white', borderRadius: '1rem', width: '450px', }}>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                
                <div style={{ width: '340px', marginLeft: '10px' }}>
                  <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '5px' }}>Pick-up Date</p><hr/>
                  {product && <p style={{ color: 'gray', marginBottom: '0' }}>{product.pickupDate}</p> }
                </div>
              </div>
              <Badge pill color='success' style={{
  backgroundColor: 'lightgreen',
  borderRadius: '5px',
  padding: '0.5rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
}}>
  Verified
</Badge>

            </div>
          </CardBody>
        </Card>
      </Col>
      
      <Col xl={6} className='mb-4' style={{ padding: '1rem', backgroundColor: '#f8f9fa', }}>
        <Card style={{ padding: '1rem', backgroundColor: 'white', borderRadius: '1rem', width: '450px', }}>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                
                <div style={{ width: '340px', marginLeft: '10px' }}>
                  <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '5px' }}>Expected Delivery Date</p><hr/>
                  {product && <p style={{ color: 'gray', marginBottom: '0' }}>{product.deliveryDate}</p> }
                </div>
              </div>
              <Badge pill color='success' style={{
  backgroundColor: 'lightgreen',
  borderRadius: '5px',
  padding: '0.5rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
}}>
  Verified
</Badge>

            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
    </div>


    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', }} className='detail'>
    <Row style={{
              backgroundColor: '#f8f9fa',
              border: '0',
              padding: '0.5rem',
              display: 'flex',
              justifyContent: 'space-around',
              width: '65%',
            }} >
      <Col xl={6} className='mb-4' style={{ padding: '1rem', backgroundColor: '#f8f9fa', }}>
        <Card style={{ padding: '1rem', backgroundColor: 'white', borderRadius: '1rem', width: '450px', }}>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                
                <div style={{ width: '340px', marginLeft: '10px' }}>
                  <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '5px' }}>Package</p><hr/>
                  {product && <p style={{ color: 'gray', marginBottom: '0' }}>{product.package}</p> }
                </div>
              </div>
              <Badge pill color='success' style={{
  backgroundColor: 'lightgreen',
  borderRadius: '5px',
  padding: '0.5rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
}}>
  Verified
</Badge>

            </div>
          </CardBody>
        </Card>
      </Col>
      
      <Col xl={6} className='mb-4' style={{ padding: '1rem', backgroundColor: '#f8f9fa' }}>
        <Card style={{ padding: '1rem', backgroundColor: 'white', borderRadius: '1rem', width: '450px', }}>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                
                <div style={{ width: '340px', marginLeft: '10px' }}>
                  <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '5px' }}>Quantity</p><hr/>
                  {product && <p style={{ color: 'gray', marginBottom: '0' }}>{product.quantity}</p> }
                </div>
              </div>
              <Badge pill color='success' style={{
  backgroundColor: 'lightgreen',
  borderRadius: '5px',
  padding: '0.5rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
}}>
  Active
</Badge>
            </div>
          </CardBody>
          
        </Card>
      </Col>
    </Row>
    </div>

    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', }} className='detail'>
    <Row style={{
              backgroundColor: '#f8f9fa',
              border: '0',
              padding: '0.5rem',
              display: 'flex',
              justifyContent: 'space-around',
              width: '65%',
            }} >
      <Col xl={6} className='mb-4' style={{ padding: '1rem', backgroundColor: '#f8f9fa', }}>
        <Card style={{ padding: '1rem', backgroundColor: 'white', borderRadius: '1rem', width: '450px', }}>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
               
                <div style={{ width: '340px', marginLeft: '10px' }}>
                  <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '5px' }}>Shipment Mode</p><hr/>
                  {product && <p style={{ color: 'gray', marginBottom: '0' }}>{product.shipmentMode}</p> }
                </div>
              </div>
              <Badge pill color='success' style={{
  backgroundColor: 'lightgreen',
  borderRadius: '5px',
  padding: '0.5rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
}}>
  Verified
</Badge>

            </div>
          </CardBody>
        </Card>
      </Col>
      
      <Col xl={6} className='mb-4' style={{ padding: '1rem', backgroundColor: '#f8f9fa' }}>
        <Card style={{ padding: '1rem', backgroundColor: 'white', borderRadius: '1rem', width: '450px', }}>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                
                <div style={{ width: '340px', marginLeft: '10px' }}>
                  <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '5px' }}>Type of Shipment</p><hr/>
                  {product && <p style={{ color: 'gray', marginBottom: '0' }}>{product.shipmentType}</p> }
                </div>
              </div>
              <Badge pill color='success' style={{
  backgroundColor: 'lightgreen',
  borderRadius: '5px',
  padding: '0.5rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
}}>
  Active
</Badge>
            </div>
          </CardBody>
          
        </Card>
      </Col>
    </Row>
    </div>

    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', }} className='detail'>
    <Row style={{
              backgroundColor: '#f8f9fa',
              border: '0',
              padding: '0.5rem',
              display: 'flex',
              justifyContent: 'space-around',
              width: '65%',
            }} >
      <Col xl={6} className='mb-4' style={{ padding: '1rem', backgroundColor: '#f8f9fa', }}>
        <Card style={{ padding: '1rem', backgroundColor: 'white', borderRadius: '1rem', width: '450px', }}>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                
                <div style={{ width: '340px', marginLeft: '10px' }}>
                  <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '5px' }}>Carrier</p><hr/>
                  {product && <p style={{ color: 'gray', marginBottom: '0' }}>{product.carrier}</p> }
                </div>
              </div>
              <Badge pill color='success' style={{
  backgroundColor: 'lightgreen',
  borderRadius: '5px',
  padding: '0.5rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
}}>
  Verified
</Badge>

            </div>
          </CardBody>
        </Card>
      </Col>
      
      <Col xl={6} className='mb-4' style={{ padding: '1rem', backgroundColor: '#f8f9fa' }}>
        <Card style={{ padding: '1rem', backgroundColor: 'white', borderRadius: '1rem', width: '450px', }}>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                
                <div style={{ width: '340px', marginLeft: '10px' }}>
                  <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '5px' }}>Carrier Reference No.</p><hr/>
                  {product && <p style={{ color: 'gray', marginBottom: '0' }}>{product.referenceNumber}</p> }
                </div>
              </div>
              <Badge pill color='success' style={{
  backgroundColor: 'lightgreen',
  borderRadius: '5px',
  padding: '0.5rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
}}>
  Active
</Badge>
            </div>
          </CardBody>
          
        </Card>
      </Col>
    </Row>
    </div>

    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', }} className='detail'>
    <Row style={{
              backgroundColor: '#f8f9fa',
              border: '0',
              padding: '0.5rem',
              display: 'flex',
              justifyContent: 'space-around',
              width: '65%',
            }} >
      <Col xl={6} className='mb-4' style={{ padding: '1rem', backgroundColor: '#f8f9fa', }}>
        <Card style={{ padding: '1rem', backgroundColor: 'white', borderRadius: '1rem', width: '450px', }}>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                
                <div style={{ width: '340px', marginLeft: '10px' }}>
                  <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '5px' }}>Payment Mode</p><hr/>
                  {product && <p style={{ color: 'gray', marginBottom: '0' }}>{product.paymentMode}</p> }
                </div>
              </div>
              <Badge pill color='success' style={{
  backgroundColor: 'lightgreen',
  borderRadius: '5px',
  padding: '0.5rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
}}>
  Verified
</Badge>

            </div>
          </CardBody>
        </Card>
      </Col>
      
      <Col xl={6} className='mb-4' style={{ padding: '1rem', backgroundColor: '#f8f9fa' }}>
        <Card style={{ padding: '1rem', backgroundColor: 'white', borderRadius: '1rem', width: '450px', }}>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                
                <div style={{ width: '340px', marginLeft: '10px' }}>
                  <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '5px' }}>Status</p><hr/>
                  {product && <p style={{ color: 'gray', marginBottom: '0' }}>{product.category}</p> }
                </div>
              </div>
              <Badge pill color='success' style={{
  backgroundColor: 'lightgreen',
  borderRadius: '5px',
  padding: '0.5rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
}}>
  Active
</Badge>
            </div>
          </CardBody>
          
        </Card>
      </Col>
    </Row>
    </div>


   
<figure className='hidden md:block'>
<figcaption class="mt-2 mb-3 font-bold text-3xl text-center text-gray-500 dark:text-black">Package Image</figcaption>
  <img class="h-md mb-3 max-w-2xl mx-auto rounded-xl  transition-all duration-300 rounded-lg blur-sm hover:blur-none" src={product.imgUrl} alt="Product Image"/>
</figure>
  


    <h2 style={{
  textAlign: 'center',
  fontFamily: 'Arial',
  fontWeight: 'bold',
  fontSize: '2rem',
  color: 'black',
  borderRadius: '10px',
  padding: '1rem',
  }} className='hidden md:block'>Shipment History</h2>

  

<div style={{justifyContent: 'center', alignItems: 'center', width: '100%', }} className='detail overflow-x-auto '>
    
    <Row style={{
              backgroundColor: '#f8f9fa',
              border: '0',
              justifyContent: 'center',
              padding: '0.5rem',
              width: '100%'
            }}>
  <Col lg="12"  xl={6} className='mb-4' style={{ padding: '1rem'}}>
    <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "20px", overflowX: "auto" }} className='overflow-x-auto items-center justify-center'>
      <thead>
        <tr>
          <th style={{ textAlign: "left", padding: "30px", width: '15%', background: '#90EE90'}}>Date</th>
          <th style={{ textAlign: "left", padding: "30px", width: '20%', borderLeft: '2px solid white', background: '#90EE90' }}>Location</th>
          <th style={{ textAlign: "left", padding: "30px", width: '25%', borderLeft: '2px solid white', background: '#90EE90'  }}>Status</th>
          <th style={{ textAlign: "left", padding: "30px", width: '15%', borderLeft: '2px solid white', background: '#90EE90'  }}>Updated By</th>
          <th style={{ textAlign: "left", padding: "30px", width: '25%', borderLeft: '2px solid white', background: '#90EE90'  }}>Remarks</th>
        </tr>
      </thead>
      <tbody>
      {loading ? (
            <h4 className="py-5 text-center fw-bold">Loading...</h4>
          ) : (
            <>
              {filteredOrderHistory.sort((a, b) => b.timestamp - a.timestamp).map((order) => renderOrderHistoryPcRow(order))}
            </>
          )}
          </tbody>
    </table>
  </Col>
</Row>
</div>


                 
    
    <div style={{ width: '100%', borderLeft: "2px solid black",}} className="mini">
      <h3 style={{ backgroundColor: '#f8f9fa', padding: '0.5rem 1rem', borderTop: '1px solid #dee2e6', fontSize: '2rem', borderBottom: '1px solid #dee2e6', fontWeight: 'bold' }}>Shipper Details</h3>
      <ListGroup style={{ marginBottom: '1rem' }}>
        <ListGroupItem className='py-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #dee2e6' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
              <h3 style={{ fontSize: '1.7rem', marginLeft: '1rem', marginBottom: '1px' }}>Shipper Name</h3>
              {product &&  <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}>{product.shipperName}</h3> }
            </div>
          </div>
        </ListGroupItem>

        <ListGroupItem className='py-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #dee2e6' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
              <h3 style={{ fontSize: '1.7rem', marginLeft: '1rem', marginBottom: '1px' }}>Shipper Address</h3>
              {product &&  <h3 style={{ fontSize: '1.2rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}>{product.shipperAddress}</h3> }
            </div>
          </div>
        </ListGroupItem>

        <ListGroupItem className='py-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #dee2e6' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
              <h3 style={{ fontSize: '1.7rem', marginLeft: '1rem', marginBottom: '1px' }}>Shipper Phone Number</h3>
              {product &&  <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}>{product.shipperNumber}</h3> }
            </div>
          </div>
        </ListGroupItem>

        <ListGroupItem style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid black', }} className='py-5'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
              <h3 style={{ fontSize: '1.7rem', marginLeft: '1rem', marginBottom: '1px' }}>Shipper Email Address</h3>
              {product &&  <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}>{product.shipperEmail}</h3> }
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
      <br/>
    </div>
    
    
    <div style={{ width: '100%', borderLeft: "2px solid black",}} className="mini">
      <h3 style={{ backgroundColor: '#f8f9fa', padding: '0.5rem 1rem', borderTop: '1px solid #dee2e6', fontSize: '2rem', borderBottom: '1px solid #dee2e6', fontWeight: 'bold' }}>Receiver Details</h3>
      <ListGroup style={{ marginBottom: '1rem' }}>
        <ListGroupItem className='py-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #dee2e6' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
              <h3 style={{ fontSize: '1.7rem', marginLeft: '1rem', marginBottom: '1px' }}>Receiver Name</h3>
              {product &&  <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}>{product.recipient}</h3> }
            </div>
          </div>
        </ListGroupItem>

        <ListGroupItem className='py-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #dee2e6' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
              <h3 style={{ fontSize: '1.7rem', marginLeft: '1rem', marginBottom: '1px' }}>Receiver Address</h3>
              {product &&  <h3 style={{ fontSize: '1.2rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}>{product.recipientAddress}</h3> }
            </div>
          </div>
        </ListGroupItem>

        <ListGroupItem className='py-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #dee2e6' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
              <h3 style={{ fontSize: '1.7rem', marginLeft: '1rem', marginBottom: '1px' }}>Receiver Phone Number</h3>
              {product &&  <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}>{product.recipientNum}</h3> }
            </div>
          </div>
        </ListGroupItem>

        <ListGroupItem className='py-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid black' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
              <h3 style={{ fontSize: '1.7rem', marginLeft: '1rem', marginBottom: '1px' }}>Receiver Email Address</h3>
              {product &&  <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}>{product.recipientEmail}</h3> }
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
      <br/>
    </div>
    
    
    <h5 style={{
  textAlign: 'center',
  fontFamily: 'Arial',
  fontWeight: 'bold',
  fontSize: '1rem',
  color: 'white',
  padding: '1rem',
  background: 'gray'
}}>Shipment Status: {product.category}</h5>

<h2 style={{
  textAlign: 'center',
  fontFamily: 'Arial',
  fontWeight: 'bold',
  fontSize: '2rem',
  color: 'black',
  borderRadius: '10px',
  padding: '1rem',
}} className='block md:hidden lg:hidden'>Shipment Information</h2>

<div style={{ width: '100%', borderLeft: "2px solid black",}} className="mini">
<h3 style={{ backgroundColor: '#f8f9fa', padding: '0.5rem 1rem', borderTop: '1px solid #dee2e6', fontSize: '2rem', borderBottom: '1px solid #dee2e6', fontWeight: 'bold' }}>Origin</h3>
      <ListGroup style={{ marginBottom: '1rem' }}>
      <ListGroupItem className='py-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid black' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
            {product && <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}> {product.origin}</h3> }
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>

    <div style={{ width: '100%', borderLeft: "2px solid black",}} className="mini">
<h3 style={{ backgroundColor: '#f8f9fa', padding: '0.5rem 1rem', borderTop: '1px solid #dee2e6', fontSize: '2rem', borderBottom: '1px solid #dee2e6', fontWeight: 'bold' }}>Destination</h3>
      <ListGroup style={{ marginBottom: '1rem' }}>
      <ListGroupItem className='py-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid black' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
            {product && <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}> {product.destination}</h3> }
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>

    <div style={{ width: '100%', borderLeft: "2px solid black",}} className="mini">
<h3 style={{ backgroundColor: '#f8f9fa', padding: '0.5rem 1rem', borderTop: '1px solid #dee2e6', fontSize: '2rem', borderBottom: '1px solid #dee2e6', fontWeight: 'bold' }}>Current Location</h3>
      <ListGroup style={{ marginBottom: '1rem' }}>
      <ListGroupItem className='py-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid black' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
            {product && <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}> {product.currentLoc}</h3> }
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>

    <div style={{ width: '100%', borderLeft: "2px solid black",}} className="mini">
<h3 style={{ backgroundColor: '#f8f9fa', padding: '0.5rem 1rem', borderTop: '1px solid #dee2e6', fontSize: '2rem', borderBottom: '1px solid #dee2e6', fontWeight: 'bold' }}>Weight</h3>
      <ListGroup style={{ marginBottom: '1rem' }}>
      <ListGroupItem className='py-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid black' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
            {product && <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}> {product.weight}</h3> }
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>

    <div style={{ width: '100%', borderLeft: "2px solid black",}} className="mini">
<h3 style={{ backgroundColor: '#f8f9fa', padding: '0.5rem 1rem', borderTop: '1px solid #dee2e6', fontSize: '2rem', borderBottom: '1px solid #dee2e6', fontWeight: 'bold' }}>Product Type</h3>
      <ListGroup style={{ marginBottom: '1rem' }}>
      <ListGroupItem className='py-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid black' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
            {product && <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}> {product.product}</h3> }
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>

    <div style={{ width: '100%', borderLeft: "2px solid black",}} className="mini">
<h3 style={{ backgroundColor: '#f8f9fa', padding: '0.5rem 1rem', borderTop: '1px solid #dee2e6', fontSize: '2rem', borderBottom: '1px solid #dee2e6', fontWeight: 'bold' }}>Total Freight</h3>
      <ListGroup style={{ marginBottom: '1rem' }}>
      <ListGroupItem className='py-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid black' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
            {product && <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}> {product.freight}</h3> }
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>

    <div style={{ width: '100%', borderLeft: "2px solid black",}} className="mini">
<h3 style={{ backgroundColor: '#f8f9fa', padding: '0.5rem 1rem', borderTop: '1px solid #dee2e6', fontSize: '2rem', borderBottom: '1px solid #dee2e6', fontWeight: 'bold' }}>Comments</h3>
      <ListGroup style={{ marginBottom: '1rem' }}>
      <ListGroupItem className='py-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid black' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
            {product && <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}> {product.comments}</h3> }
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>

    <div style={{ width: '100%', borderLeft: "2px solid black",}} className="mini">
<h3 style={{ backgroundColor: '#f8f9fa', padding: '0.5rem 1rem', borderTop: '1px solid #dee2e6', fontSize: '2rem', borderBottom: '1px solid #dee2e6', fontWeight: 'bold' }}>Pick Up Date</h3>
      <ListGroup style={{ marginBottom: '1rem' }}>
      <ListGroupItem className='py-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid black' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
            {product && <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}> {product.pickupDate}</h3> }
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>

    <div style={{ width: '100%', borderLeft: "2px solid black",}} className="mini">
<h3 style={{ backgroundColor: '#f8f9fa', padding: '0.5rem 1rem', borderTop: '1px solid #dee2e6', fontSize: '2rem', borderBottom: '1px solid #dee2e6', fontWeight: 'bold' }}>Expected Delivery Date</h3>
      <ListGroup style={{ marginBottom: '1rem' }}>
      <ListGroupItem className='py-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid black' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
            {product && <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}> {product.deliveryDate}</h3> }
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>

    <div style={{ width: '100%', borderLeft: "2px solid black",}} className="mini">
<h3 style={{ backgroundColor: '#f8f9fa', padding: '0.5rem 1rem', borderTop: '1px solid #dee2e6', fontSize: '2rem', borderBottom: '1px solid #dee2e6', fontWeight: 'bold' }}>Package</h3>
      <ListGroup style={{ marginBottom: '1rem' }}>
      <ListGroupItem className='py-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid black' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
            {product && <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}> {product.package}</h3> }
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>

    <div style={{ width: '100%', borderLeft: "2px solid black",}} className="mini">
<h3 style={{ backgroundColor: '#f8f9fa', padding: '0.5rem 1rem', borderTop: '1px solid #dee2e6', fontSize: '2rem', borderBottom: '1px solid #dee2e6', fontWeight: 'bold' }}>Quantity</h3>
      <ListGroup style={{ marginBottom: '1rem' }}>
      <ListGroupItem className='py-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid black' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
            {product && <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}> {product.quantity}</h3> }
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>

    <div style={{ width: '100%', borderLeft: "2px solid black",}} className="mini">
<h3 style={{ backgroundColor: '#f8f9fa', padding: '0.5rem 1rem', borderTop: '1px solid #dee2e6', fontSize: '2rem', borderBottom: '1px solid #dee2e6', fontWeight: 'bold' }}>Shipment Mode</h3>
      <ListGroup style={{ marginBottom: '1rem' }}>
      <ListGroupItem className='py-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid black' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
            {product && <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}> {product.shipmentMode}</h3> }
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>

    <div style={{ width: '100%', borderLeft: "2px solid black",}} className="mini">
<h3 style={{ backgroundColor: '#f8f9fa', padding: '0.5rem 1rem', borderTop: '1px solid #dee2e6', fontSize: '2rem', borderBottom: '1px solid #dee2e6', fontWeight: 'bold' }}>Shipment Type</h3>
      <ListGroup style={{ marginBottom: '1rem' }}>
      <ListGroupItem className='py-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid black' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
            {product && <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}> {product.shipmentType}</h3> }
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>

    <div style={{ width: '100%', borderLeft: "2px solid black",}} className="mini">
<h3 style={{ backgroundColor: '#f8f9fa', padding: '0.5rem 1rem', borderTop: '1px solid #dee2e6', fontSize: '2rem', borderBottom: '1px solid #dee2e6', fontWeight: 'bold' }}>Carrier</h3>
      <ListGroup style={{ marginBottom: '1rem' }}>
      <ListGroupItem className='py-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid black' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
            {product && <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}> {product.carrier}</h3> }
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>

    <div style={{ width: '100%', borderLeft: "2px solid black",}} className="mini">
<h3 style={{ backgroundColor: '#f8f9fa', padding: '0.5rem 1rem', borderTop: '1px solid #dee2e6', fontSize: '2rem', borderBottom: '1px solid #dee2e6', fontWeight: 'bold' }}>Carrier Reference No.</h3>
      <ListGroup style={{ marginBottom: '1rem' }}>
      <ListGroupItem className='py-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid black' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
            {product && <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}> {product.referenceNumber}</h3> }
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>

    <div style={{ width: '100%', borderLeft: "2px solid black",}} className="mini">
<h3 style={{ backgroundColor: '#f8f9fa', padding: '0.5rem 1rem', borderTop: '1px solid #dee2e6', fontSize: '2rem', borderBottom: '1px solid #dee2e6', fontWeight: 'bold' }}>Payment Mode</h3>
      <ListGroup style={{ marginBottom: '1rem' }}>
      <ListGroupItem className='py-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid black' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
            {product && <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}> {product.paymentMode}</h3> }
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>

    <div style={{ width: '100%', borderLeft: "2px solid black",}} className="mini">
<h3 style={{ backgroundColor: '#f8f9fa', padding: '0.5rem 1rem', borderTop: '1px solid #dee2e6', fontSize: '2rem', borderBottom: '1px solid #dee2e6', fontWeight: 'bold' }}>Shipment Status</h3>
      <ListGroup style={{ marginBottom: '1rem' }}>
      <ListGroupItem className='py-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid black' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            <div>
            {product && <h3 style={{ fontSize: '1.4rem', marginLeft: '1rem', fontWeight: 'bold', marginBottom: '0.02rem', color: 'blue', }}> {product.category}</h3> }
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
      <br/>
    </div>


    <figure className='block md:hidden lg:hidden px-5'>
<figcaption class="mt-2 mb-3 font-bold text-3xl text-center text-gray-500 dark:text-black">Package Image</figcaption>
  <img class="h-90 mb-3 max-w-full mx-auto rounded-xl" src={product.imgUrl} alt="Product Image"/>
</figure>


    <h3 style={{
  textAlign: 'center',
  fontFamily: 'Arial',
  fontWeight: 'bold',
  fontSize: '2rem',
  color: 'black',
  borderRadius: '10px',
  padding: '1rem',
}} className=''>Shipment History</h3>

<div style={{justifyContent: 'center', alignItems: 'center', width: '100%', }} className='mini overflow-x-auto'>
    
    <Row style={{
              backgroundColor: '#f8f9fa',
              border: '0',
              justifyContent: 'center',
              padding: '0.5rem',
              width: '100%'
            }}>
  <Col lg="12"  xl={6} className='mb-4' style={{ padding: '1rem'}}>
   
      {loading ? (
            <h4 className="py-5 text-center fw-bold">Loading...</h4>
          ) : (
            <>
              {filteredOrderHistory.sort((a, b) => b.timestamp - a.timestamp).map((order) => renderOrderHistoryRow(order))}
            </>
          )}
  </Col>
</Row>
</div>

    <br/><br/>

    <Footer/>
    </>
  );
}

export default ParcelDetails;

import React, {useState} from 'react'
import {AiOutlineMenu, AiOutlineClose} from 'react-icons/ai'
import { NavLink } from "react-router-dom";
import Logo from './eugogate.png'


const CheckOrderNavbar = () => {
    const [nav, setNav] = useState(false)
    const handleNav = () => {
        setNav(!nav)
    }
  return (
    <div className='w-full h-[90px] bg-gray-100 shadow-xl'>
        <div className=' max-w-[1240px] mx-auto px-4 flex justify-between items-center h-full'>
        <div>
        <NavLink to='home' className="capitalize">
            <img src={Logo} alt="hoobank" className="w-[124px] md:w-[150px] h-[42px]" />
        </NavLink>
        </div>
        <div className='hidden md:flex'>
            <ul className='flex text-black items-center'>
                <li className='p-4'>
                    <NavLink to='services' className='text-black '><h2 className='text-black font-normal'>Services</h2></NavLink>
                </li>
                <li className='p-4'>
                    <NavLink to='contact-us'><h2 className='text-black font-normal'> Contact Us</h2></NavLink>
                </li>
                <li className='p-4'>
                    <NavLink to='about'><h2 className='text-black font-normal'> About Us </h2></NavLink>
                </li>
               
            </ul>
        </div>

        {/* Hamburger menu */}

        <div onClick={handleNav} className='block md:hidden'>
            {nav ? <AiOutlineClose size={30} className='text-gray-500' /> : <AiOutlineMenu size={30} className='text-gray-500' />}
        


        </div>
       
        {/* Mobile menu */}
        <div className= {nav ? ' w-full bg-black text-white absolute top-[90px] left-0 flex justify-center text-center' : 'absolute left-[-100%]'}>
        

        
        <ul>
                <li><NavLink to='services' className='text-white '><h2 className='text-white font-normal'>Services</h2></NavLink></li>
                <li><NavLink to='contact-us'><h2 className='text-white font-normal'> Contact Us</h2></NavLink></li>
                <li><NavLink to='about'><h2 className='text-white font-normal'> About Us </h2></NavLink></li>
            </ul>




            </div>
        </div>
    </div>

  )
}

export default CheckOrderNavbar;
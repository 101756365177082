import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Home from '../pages/Home';
import About from '../pages/About';
import NewsletterForm from '../NewsletterForm';
import Contact from '../pages/Contact';
import Services from '../pages/Services';
import AdminSignup from '../pages/AdminSignup';
import AdminLogin from '../pages/AdminLogin';
import ProtectedRoute from './ProtectedRoute';
import AdminRoute from './AdminRoute';

import Dashboard from '../admin/Dashboard';
import Users from '../admin/Users';
import AdminAddUser from '../admin/AdminSignup';
import Appearance from '../admin/Appearance';
import AllAppearance from '../admin/AllAppearance';
import AddOrder from '../admin/AddOrder';
import AllOrders from '../admin/AllOrders';
import MiniAddOrder from '../miniAdmin/MiniAddOrder';
import MiniAllOrders from '../miniAdmin/MiniAllOrders';
import MiniDashboard from '../miniAdmin/MiniDashboard';
import AddAsset from '../admin/AddAsset';
import AllAssets from '../admin/AllAssets';

import OrderPortal from '../components/TrackOrderPortal/OrderPortal';
import Parcel from '../components/TrackOrderPortal/Parcel/Parcel';
import ParcelDetails from '../components/TrackOrderPortal/Parcel/ParcelDetails';

import AssetValuePortal from '../components/AssetValuePortal/AssetValuePortal';
import CheckAsset from '../components/AssetValuePortal/CheckAsset/CheckAsset';
import AssetDetails from '../components/AssetValuePortal/CheckAsset/AssetDetails';
import MiniAddAsset from '../miniAdmin/MiniAddAsset';
import MiniAllAssets from '../miniAdmin/MiniAllAssets';


const Routers = () => {
  return (
    <Routes>
        <Route path='/' element={<Navigate to='home' />} />
        <Route path='home' element={<Home/>} />
        <Route path='about' element={<About/>} />
        <Route path='contact-us' element={<Contact/>} />
        <Route path='newsletter' element={<NewsletterForm/>} />

        <Route path='order-portal' element={<OrderPortal/>} />
        <Route path='parcel' element={<Parcel/>} />
        <Route path='parcel/parcel-details' element={<ParcelDetails/>} />
        <Route path='asset-value-portal' element={<AssetValuePortal/>} />
        <Route path='check-asset' element={<CheckAsset/>} />
        <Route path='check-asset/asset-details' element={<AssetDetails/>} />

        

        <Route path="/*" element={<ProtectedRoute/>}>
        <Route path="mini/dashboard" element={<MiniDashboard/>} />
        <Route path="mini/dashboard/add-order" element={<MiniAddOrder/>} />
        <Route path="mini/dashboard/all-orders" element={<MiniAllOrders/>} />
        <Route path="mini/dashboard/add-asset" element={<MiniAddAsset/>} />
        <Route path="mini/dashboard/all-assets" element={<MiniAllAssets/>} />
        </Route>
        <Route path="/*" element={<AdminRoute/>}>
        <Route path="dashboard" element={<Dashboard />}/>
        <Route path="dashboard/all-orders" element={<AllOrders/>}/>
        <Route path="dashboard/add-order" element={<AddOrder/>}/>
        <Route path="dashboard/site-appearance" element={<Appearance/>} />
        <Route path="dashboard/all-appearance" element={<AllAppearance/>} />
        <Route path="dashboard/users" element={<Users/>}/>
        <Route path="dashboard/add-admin" element={<AdminAddUser/>}/>
        <Route path="dashboard/add-asset" element={<AddAsset/>}/>
        <Route path="dashboard/all-assets" element={<AllAssets/>}/>
        </Route>
      
      

        <Route path='admin/signup-admin' element={<AdminSignup/>} />
        <Route path='login-admin' element={<AdminLogin/>} />
        <Route path='services' element={<Services/>} />
    </Routes>
  )
}

export default Routers
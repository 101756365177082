import React from "react";
import Services from './Services'
import "./Asset.css";
import Companies from './components/Companies'
import Courses from './components/Courses'
import Footer from './components/Footer'
import CTA from './components/CTA'
import Feedback from './components/Feedback'
import RequestQuote from "../TrackOrderPortal/RequestQuote";
import Request from "../../Compo/RequestQuote";
import Home from "./components/home/Home";

const AssetValuePortal = () => {
  return (
    <div>
      <Home/>
      <RequestQuote/>
      <Request/>
      <Services/>
      <Companies/>
      <Courses/>
      <CTA/>
      <Feedback/>
      <Footer/>
    </div>
  );
};

export default AssetValuePortal;

import React, {useState} from 'react';
import heroVideo from '../../assets/world.mp4';
import "./hero.css";
import Typed from 'react-typed';
import { NavLink } from "react-router-dom";

const Hero = () => {
  const [isErasing, setIsErasing] = useState(false);

  const handleBeginTyping = () => {
    setIsErasing(false);
  };

  const handleCompleteTyping = () => {
    setIsErasing(true);
  };

  return (
    <div>
    <div className='w-screen h-[120vh] top-[90px] m-0 hidden md:block lg:block'>
      <video className='w-full absolute -z-10'
      src={heroVideo}
      autoPlay 
      loop 
      muted
      />
      <div className='w-full h-[90%] flex flex-col justify-center items-center text-white  text-center'>
        <h1 className='text-5xl font-bold'>
        <>
      
        <Typed
          className='md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2'
            strings={['Eugogate', 'Logistics', 'Eugogate Logistics', 'Eugogate', 'Asset Avault', 'Eugogate Asset Vault']}
            typeSpeed={120}
            backSpeed={140}
            loop
            onComplete={handleCompleteTyping}
            onBegin={handleBeginTyping}
          />
          {isErasing ? (
        <span role="img" aria-label="Eraser" className="mb-2 text-white text-6xl">
          ✍️
        </span>
      ) : (
        <span role="img" aria-label="Pen" className="mb-2 text-white text-6xl">
          ✏️
        </span>
      )}
          </>
        </h1>
        <h1 className='text-5xl py-4'>
          <span className='bg-gradient-to-r text-transparent from-[#6a00ff] to-[#00d8ff] bg-clip-text  px-2'>Streamlined Logistics Solutions: Securing and </span>
          Delivering Your Assets with Confidence.
        </h1>
        <p className='text-4xl py-4'>
        Efficient Logistics and Seamless Shipping: <span className='bg-gradient-to-r text-transparent from-[#6a00ff] to-[#00d8ff] bg-clip-text  px-2'>Streamlining Operations for Global Supply Chain Excellence. </span>
        </p>
        <button className='m-2 bg-gradient-to-r from-[#6a00ff] to-[#00d8ff] py-3 px-7 rounded-3xl text-white'>
        <NavLink to='/order-portal'><span className='text-white font-normal'>  Track Consignment </span></NavLink>
        </button>
        <button className='m-2 bg-gradient-to-r from-[#6a00ff] to-[#00d8ff] py-3 px-7 rounded-3xl text-white'>
        <NavLink to='/asset-value-portal'><span className='text-white font-normal'>  Asset Vault </span></NavLink>
        </button>
      </div>

    </div>


    <section class="bg-center bg-no-repeat bg-[url('https://e1.pxfuel.com/desktop-wallpaper/757/926/desktop-wallpaper-truck-and-backgrounds-black-volvo-truck.jpg')] bg-gray-700 bg-blend-multiply block md:hidden lg:hidden">
    <div class="px-4 mx-auto max-w-screen-xl text-center py-24 lg:py-56">
      <>
    <Typed
          className='mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl'
            strings={['Eugogate', 'Logistics', 'Eugogate Logistics', 'Eugogate', 'Asset Avault', 'Eugogate Asset Vault']}
            typeSpeed={120}
            backSpeed={140}
            loop
            onComplete={handleCompleteTyping}
            onBegin={handleBeginTyping}
          />
          {isErasing ? (
        <span role="img" aria-label="Eraser" className="mb-2 text-white text-6xl">
          ✍️
        </span>
      ) : (
        <span role="img" aria-label="Pen" className="mb-2 text-white text-6xl">
          ✏️
        </span>
      )}
          </>

        <p class="mb-8 text-lg font-normal text-gray-300 lg:text-xl sm:px-16 lg:px-48">Efficient Logistics and Seamless Shipping: Streamlining Operations for Global Supply Chain Excellence.</p>
        <h3 class="mb-8 text-2xl font-normal text-gray-300  sm:px-16 lg:px-48"><span className='bg-gradient-to-r text-transparent from-[#6a00ff] to-[#00d8ff] bg-clip-text  px-2'>Efficient Logistics and Seamless Shipping:</span> Streamlining Operations for Global Supply Chain Excellence.</h3>
        <div class="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
          
            <button className='m-2 bg-gradient-to-r from-[#6a00ff] to-[#00d8ff] py-3 px-7 rounded-3xl text-white'>
        <NavLink to='/order-portal'> <span className='text-white font-normal'>  Track Consignment </span> </NavLink>
        </button>
            <button class="m-2 bg-gradient-to-r from-[#6a00ff] to-[#00d8ff] py-3 px-7 rounded-3xl text-white">
            <NavLink to='/asset-value-portal'><span className='text-white font-normal'> Asset Vault </span> </NavLink>
            </button>  
           
        </div>
    </div>
</section>

    </div>
  )
}

export default Hero
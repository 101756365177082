import { aflacLogo, android, angular, cognizantLogo,
     facebook, firebase, instagram, ionic, linkdin, mysql,
      nodejs,
      react, typescript, wellsLogo, wiuLogo, youtube } from "../assetss"

export const AppText={
    hello:'Hello,',
    Iam:'I am ',
    RahulSanap:'Rahul Sanap',
    aboutMeDescripion:'Experience the efficiency of our integrated logistics services, including asset tracking, for seamless shipping and reliable delivery. Choose Eugogate Logistics for your business needs.',
    Skills:'We Make World ',
    Experties:'Wide Delivery',
    UIUXDesigner:'UI/UX Designer',
    BackendDeveloper:'Backend Dev',
    FrontedDeveloper:'Frontend Dev',
    Portfolio:'Shipping Solutions',
    Creative:'Smooth Global ',
    CompaniesI:'Companies I ',
    WorkedFor:'Worked For',
    Contact:'Contact ',
    Us:'Us',
    copywriteText:'Copyright by Rahul Sanap @2023'

}

export const aboutSection=[
    {
        id:1,
        image:'https://expak.com/wp-content/uploads/2022/03/V2-iStock-1250106326_expak_air_freight-copy.jpg',
        title:'Air Freight',
        desc:'Fast, efficient air freight services worldwide.'
    },
    {
        id:2,
        image:'https://www.businesstelegraph.co.uk/wp-content/uploads/2020/07/Ocean-Freight-Shipping.jpg',
        title:'Ocean Freight',
        desc:'Reliable ocean freight solutions for global shipping.'
    },
    {
        id:3,
        image:'https://www.haultrac.com/img/truck-freight.jpg',
        title:'Land Freight',
        desc:'Reliable land freight for seamless transportation.'
    }
]

export const navLinks = [
    {
      id: "home",
      title: "Home",
    },
    {
      id: "aboutus",
      title: "About Us",
      path: "/about-us"
    },
    {
      id: "product",
      title: "Product",
    },
    {
      id: "clients",
      title: "Clients",
    },
    {
      id: "signup",
      title: "Sign Up",
      path: '/signup',
    },
    {
      id: "login",
      title: "Login",
      path: '/login',
    }
  ];

export const skillsList=[
    {
        id:1,
        icon:angular
    },
    {
        id:1,
        icon:react
    },
    {
        id:1,
        icon:typescript
    },
    {
        id:1,
        icon:firebase
    },
    {
        id:1,
        icon:android
    },
    {
        id:1,
        icon:ionic
    },
    {
        id:1,
        icon:mysql
    },
    {
        id:1,
        icon:react
    },
    {
        id:1,
        icon:typescript
    },
    {
        id:1,
        icon:firebase
    },
    {
        id:1,
        icon:android
    },
    {
        id:1,
        icon:nodejs
    },

]

export const workDetail=[
    { 
        id:1,
        year:'USA',
        companyName:'All Freight',
        position:'Available'
    },
    { 
        id:1,
        year:'Germany',
        companyName:'All Freight',
        position:'Available'
    },
    { 
        id:1,
        year:'Canada',
        companyName:'All Freight',
        position:'Available'
    },
    { 
        id:1,
        year:'Italy',
        companyName:'All Freight',
        position:'Available'
    },
]

export const portfolio=[
    {
        id:1,
        title:'Asset Management Specialist',
        type:'ui/ux',
        desc:"Diligently oversees and optimizes the lifecycle management of company assets.",
        imageUrl:"https://www.toppr.com/bytes/wp-content/uploads/2019/06/Staff-meeting-1024x682.jpeg"
    },
    {
        id:4,
        title:'Air Freight',
        type:'website',
        desc:"Fast and efficient transportation of goods through air cargo services.",
        imageUrl:"https://globitexworld.com/wp-content/uploads/2020/10/air-cargo.png"
    },
    {
        id:2,
        title:'Ocean Freight',
        type:'mobile',
        desc:"Reliable and cost-effective shipping of goods via sea transportation.",
        imageUrl:"https://www.globalialogisticsnetwork.com/blog/wp-content/uploads/2022/06/container-shipping-industry.jpg"
    },
    {
        id:3,
        title:'Logistics Coordinators',
        type:'ui/ux',
        desc:"Coordinates transportation, warehousing, and distribution for smooth operations.",
        imageUrl:"https://motortransport.co.uk/wp-content/uploads/2023/06/shutterstock_2223960087-2.jpg"
    },

    
]

export const CompanyImage=[
    {
        id:1,
        imageUrl:cognizantLogo
    },
    {
        id:1,
        imageUrl:wellsLogo
    },
    {
        id:1,
        imageUrl:aflacLogo
    },
    {
        id:1,
        imageUrl:wiuLogo
    }
]

export const socialNetwork=[
    {
        id:1,
        logo:instagram,
        url:'https://www.instagram.com/tubeguruji/'
    },
    {
        id:1,
        logo:youtube,
        url:'https://www.youtube.com/channel/UC33Tc4FCgd_to314_b2hNTg',
    },
    {
        id:1,
        logo:linkdin,
        url:'https://www.linkedin.com/in/rahul-sanap-652920108/'
    },
    {
        id:1,
        logo:facebook,
        url:'https://www.facebook.com/rahul.sanap1'
    },
]
import React from 'react';
import Man from '../../assets/assets/man.png';
import Man2 from '../../assets/assets/const1.png';
import Man3 from '../../assets/assets/const2.png';

const Testimonials = () => {
  return (
    <div className='hidden md:block px-5'>

<div className="flex flex-col items-center bg-white shadow md:flex-row md:max-w-full h-[550px] hover:bg-white dark:bg-white dark:hover:bg-white">
    <img className="object-cover w-full rounded-t-lg h-96 md:h-[550px] md:w-[350px] md:rounded-none md:rounded-l-lg md:mx-[60px] " src={Man2} alt="" />
    
    <div class="p-4 bg-white rounded-lg md:p-8 dark:bg-white" id="about" role="tabpanel" aria-labelledby="about-tab">
            <h2 class="mb-3 md:text-4xl text-2xl font-extrabold tracking-tight text-gray-900 dark:text-black">Efficiency and Trust in Logistics Solutions.</h2>
            <p class="mb-3 text-gray-500 dark:text-gray-400">Discover a World of Logistics Excellence and Asset Protection Under One Roof. Our comprehensive solutions blend cutting-edge technology, strategic expertise, and unwavering commitment to secure and streamline your logistics operations. With a focus on trust, efficiency, and reliability, we provide end-to-end asset management, vault services, and logistics solutions tailored to your unique needs. Experience the difference of seamless integration and peace of mind as we safeguard your assets with unwavering dedication and precision.</p>
            
        </div>
        <img className="object-cover w-full rounded-t-lg h-96 md:h-[550px] md:w-[350px] md:rounded-none md:rounded-l-lg md:mx-[60px] " src={Man3} alt="" />
</div>


    
<div class="grid mb-8 mx-auto rounded-lg shadow-sm  md:mb-12 md:grid-cols-2 mt-6">
    <figure class="flex flex-col items-center justify-center p-8 text-center bg-white  rounded-lg md:rounded-lg  dark:bg-gray-200 ">
        <blockquote class="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-600">
            <h3 class="text-lg font-semibold text-gray-900 dark:text-gray-600">Very easy this was to integrate</h3>
            <p class="my-4">Eugogate Logistics has revolutionized our shipping process, ensuring timely deliveries and accurate tracking of assets. A game-changer in the logistics industry!</p>
        </blockquote>
        <figcaption class="flex items-center justify-center space-x-3">
            <img class="rounded-full w-9 h-9" src="https://www.vmcdn.ca/f/files/townandcountrytoday/images/wes-mark-tims.jpg;w=960" alt="profile picture" />
            <div class="space-y-0.5 font-medium dark:text-black text-left">
                <div>Mark Stevens</div>
                <div class="text-sm text-gray-500 dark:text-gray-400">Supply Chain Manager</div>
            </div>
        </figcaption>    
    </figure>
    <figure class="flex flex-col items-center justify-center p-8 text-center bg-white  rounded-lg dark:bg-gray-200">
        <blockquote class="max-w-2xl mb-4 text-gray-500 lg:mb-8 dark:text-gray-600">
            <h3 class="text-lg font-semibold text-gray-900 dark:text-gray-600">Solid foundation for any project</h3>
            <p class="my-4">With Eugogate Logistics, our asset management has become seamless, enabling efficient tracking and optimization of inventory. They excel in logistics solutions!</p>
        </blockquote>
        <figcaption class="flex items-center justify-center space-x-3">
            <img class="rounded-full w-9 h-9" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/roberta-casas.png" alt="profile picture" />
            <div class="space-y-0.5 font-medium dark:text-black text-left">
                <div>Sophia Carter</div>
                <div class="text-sm text-gray-500 dark:text-gray-400">Warehouse Coordinator</div>
            </div>
        </figcaption>    
    </figure>
    <figure class="flex flex-col items-center justify-center p-8 text-center bg-white  rounded-lg  dark:bg-gray-200 ">
        <blockquote class="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-600">
            <h3 class="text-lg font-semibold text-gray-900 dark:text-white">Mindblowing workflow</h3>
            <p class="my-4">Eugogate Logistics is our trusted partner for end-to-end logistics solutions. Their expertise in shipping and asset tracking has elevated our operations to new heights.</p>
        </blockquote>
        <figcaption class="flex items-center justify-center space-x-3">
            <img class="rounded-full w-9 h-9" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/jese-leos.png" alt="profile picture" />
            <div class="space-y-0.5 font-medium dark:text-black text-left">
                <div>Andrew Thompson</div>
                <div class="text-sm text-gray-500 dark:text-gray-400">Shipping Coordinator</div>
            </div>
        </figcaption>    
    </figure>
    <figure class="flex flex-col items-center justify-center p-8 text-center bg-white  rounded-lg md:rounded-lg dark:bg-gray-200 ">
        <blockquote class="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-600">
            <h3 class="text-lg font-semibold text-gray-900 dark:text-white">Efficient Collaborating</h3>
            <p class="my-4">Thanks to Eugogate Logistics, our supply chain is finely orchestrated, ensuring smooth movement of assets and precise delivery scheduling. A logistics powerhouse!</p>
        </blockquote>
        <figcaption class="flex items-center justify-center space-x-3">
            <img class="rounded-full w-9 h-9" src="https://media.licdn.com/dms/image/C4E03AQFsks9nyJleBg/profile-displayphoto-shrink_800_800/0/1623075193508?e=2147483647&v=beta&t=dO28uzeA6Oj-VjqXMzPkZiI8JPLBopjiEX_mUBO2zyw" alt="profile picture" />
            <div class="space-y-0.5 font-medium dark:text-black text-left">
                <div>Olivia Collins</div>
                <div class="text-sm text-gray-500 dark:text-gray-400">Logistics Planner</div>
            </div>
        </figcaption>    
    </figure>
</div>




<div className="flex flex-col items-center bg-white shadow md:flex-row md:max-w-full h-[550px] hover:bg-white dark:bg-white dark:hover:bg-white mx-40 my-10">
<div class="p-4 bg-white rounded-lg md:p-8 dark:bg-white" id="about" role="tabpanel" aria-labelledby="about-tab">
            <h2 class="mb-3 md:text-4xl text-2xl font-extrabold tracking-tight text-gray-900 dark:text-black">Eugogate Logistics: Empowering Your Shipping Journey.</h2>
            <p class="mb-3 text-gray-500 dark:text-gray-400">Eugogate Logistics is your trusted partner in navigating the complexities of shipping and logistics. With our comprehensive services, advanced tracking technology, and dedicated team, we empower your shipping journey with efficiency, reliability, and peace of mind. Experience seamless operations and unlock new possibilities with Eugogate Logistics by your side.</p>
            
        </div>


<div class="relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[600px] w-[300px] shadow-xl">
    <div class="w-[148px] h-[18px] bg-gray-800 top-0 rounded-b-[1rem] left-1/2 -translate-x-1/2 absolute"></div>
    <div class="h-[46px] w-[3px] bg-gray-800 absolute -left-[17px] top-[124px] rounded-l-lg"></div>
    <div class="h-[46px] w-[3px] bg-gray-800 absolute -left-[17px] top-[178px] rounded-l-lg"></div>
    <div class="h-[64px] w-[3px] bg-gray-800 absolute -right-[17px] top-[142px] rounded-r-lg"></div>
    <div class="rounded-[2rem] overflow-hidden w-[272px] h-[572px] bg-white dark:bg-gray-800">
        <img src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/hero/mockup-2-light.png" class="dark:hidden w-[272px] h-[572px]" alt=""/>
        <img src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/hero/mockup-2-dark.png" class="hidden dark:block w-[272px] h-[572px]" alt=""/>
    </div>
</div>

</div>




</div>
  )
}

export default Testimonials;
import React, {useState} from 'react'
import {AiOutlineMenu, AiOutlineClose} from 'react-icons/ai'
import { NavLink } from "react-router-dom";
import Logo from './logo.png'


const Navbar = () => {
    const [nav, setNav] = useState(false)
    const handleNav = () => {
        setNav(!nav)
    }
  return (
    <div className='w-full h-[90px] bg-black'>
        <div className=' max-w-[1240px] mx-auto px-4 flex justify-between items-center h-full'>
        <div>
            <NavLink to='home' className="capitalize">
            <img src={Logo} alt="hoobank" className="w-[124px] md:w-[150px] h-[42px]" />
            </NavLink>
        </div>
        <div className='hidden md:flex'>
            <ul className='flex text-white items-center'>
                <li className='p-4'>
                    <NavLink to='services' className='text-white '><h2 className='text-white font-normal'>Services</h2></NavLink>
                </li>
                <li className='p-4'>
                    <NavLink to='contact-us'><h2 className='text-white font-normal'> Contact Us</h2></NavLink>
                </li>
                <li className='p-4'>
                    <NavLink to='about'><h2 className='text-white font-normal'> About Us </h2></NavLink>
                </li>
                <button className='ml-4 mb-8 bg-gradient-to-r from-[#6a00ff] to-[#00d8ff] py-3 px-7 rounded-3xl text-white '><NavLink to='parcel'><h2 className='text-white text-2xl font-semibold'> Consignment </h2></NavLink></button>
                <button className='ml-4 mb-8 bg-gradient-to-r from-[#6a00ff] to-[#00d8ff] py-3 px-7 rounded-3xl text-white '><NavLink to='check-asset'><h2 className='text-white text-2xl font-semibold'>Asset Vault</h2></NavLink></button>
            </ul>
        </div>

        {/* Hamburger menu */}

        <div onClick={handleNav} className='block md:hidden'>
            {nav ? <AiOutlineClose size={30} className='text-white' /> : <AiOutlineMenu size={30} className='text-white' />}
        


        </div>
       
        {/* Mobile menu */}
        <div className= {nav ? ' w-full bg-black text-white absolute top-[90px] left-0 flex justify-center text-center' : 'absolute left-[-100%]'}>
        

        
        <ul>
                <li> <NavLink to='services' className='text-white '><h2 className='text-white font-normal'>Services</h2></NavLink></li>
                <li><NavLink to='contact-us'><h2 className='text-white font-normal'> Contact Us</h2></NavLink></li>
                <li><NavLink to='about'><h2 className='text-white font-normal'> About Us </h2></NavLink></li>
                <button className='ml-4 mb-8 bg-gradient-to-r from-[#6a00ff] to-[#00d8ff] py-3 px-7 rounded-3xl text-white '><NavLink to='parcel'><h2 className='text-white text-2xl font-semibold'> Consignment </h2></NavLink></button>
                <button className='ml-4 mb-8 bg-gradient-to-r from-[#6a00ff] to-[#00d8ff] py-3 px-7 rounded-3xl text-white '><NavLink to='check-asset'><h2 className='text-white text-2xl font-semibold'>Asset Vault</h2></NavLink></button>
            </ul>




            </div>
        </div>
    </div>

  )
}

export default Navbar
import React from 'react'
import logo  from '../eugogate.png'
import {FaFacebookF,FaDribbble,FaLinkedinIn,FaInstagram,FaBehance} from 'react-icons/fa'
import { Link } from 'react-router-dom'

const Footer = () => {
    const year = new Date().getFullYear();

  return (
    <div className='w-full bg-black py-24'>
        <div className='md:max-w-[1480px] m-auto grid md:grid-cols-5 max-[780px]:grid-cols-2  gap-8 max-w-[600px]  px-4 md:px-0'>
            
            <div className='col-span-2'>
                <img src={logo} className="h-[35px]" />
                <h2 className="text-3xl text-gray-600 leading-relaxed mt-6 mb-3">
            Located at:
            <br />
            111 Buckingham Palace Rd,
            <br />
            Westminster, London, SW1W 0SR
          </h2>

                <h2 className='py-2 text-[#6D737A]'>For more information and support <br></br> contact our customer care on</h2>
                <h2 className='py-2 text-[#363A3D]'>Email: contact@eugogatelogistics.com</h2>
                <h2 className='py-2 text-[#363A3D]'>Call : +447466089626</h2>
                <h2 className="text-xl text-gray-600 leading-relaxed mt-6 mb-3">
        Copyright Ⓒ {year} Eugogate. All Rights Reserved.
      </h2>
                <div className='flex gap-4 py-4'>
                        <div className='p-4 bg-[#E9F8F3] rounded-xl'><FaFacebookF size={25} style={{color:'#4DC39E'}} /></div>
                        <div className='p-4 bg-[#E9F8F3] rounded-xl'><FaDribbble size={25} style={{color:'#4DC39E'}} /></div>
                        <div className='p-4 bg-[#E9F8F3] rounded-xl'><FaLinkedinIn size={25} style={{color:'#4DC39E'}} /></div>
                        <div className='p-4 bg-[#E9F8F3] rounded-xl'><FaInstagram size={25} style={{color:'#4DC39E'}} /></div>
                        <div className='p-4 bg-[#E9F8F3] rounded-xl'><FaBehance size={25} style={{color:'#4DC39E'}} /></div>

                </div>

            </div>

            <div>
            
                <ul className='py-6 text-white'>
                    <li className='py-2'><Link to='/order-portal'><span className='text-white'>Order Portal</span></Link></li>
                    <li className='py-2'><Link to='/parcel'><span className='text-white'>Parcel</span></Link></li>
                    <li className='py-2'><Link to='/asset-value-portal'><span className='text-white'>Asset Portal</span></Link></li>
                    <li className='py-2'><Link to='/check-asset'><span className='text-white'>Check Asset</span></Link></li>
                </ul>
            </div>

            <div>
                
                <ul className='py-6 text-white'>
                    <li className='py-2'><Link to='/services'><span className='text-white'>Services</span></Link></li>
                    <li className='py-2'><Link to='/about'><span className='text-white'>About Us</span></Link></li>
                    <li className='py-2'><Link to='/contact-us'><span className='text-white'>Contact Us</span></Link></li>
                </ul>
            </div>
        </div>
    </div>
  )
}

export default Footer